import React, {useEffect, useState} from 'react';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import carImg from "../../../images/carimg.jpg";
import circleImg from "../../../images/circle.png";
import { ReactComponent as Location } from "../../../images/location.svg";
import { ReactComponent as RightArrow } from "../../../images/rightArrow.svg";
import section1 from "../../../images/section1.png";
import LoadingButton from '../../../components/elements/common/LoadingButton.js';
import useAppState from "../../../lib/hooks/useAppState";
import Dialog from '@material-ui/core/Dialog';
import DigitalAds from "./Modals/DigitalAds";

const useStyles = makeStyles(theme => ({
  zipSection: {
    backgroundColor: '#FFFBF5',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    position: 'relative',
  },
  contentContainer: {
    maxWidth: 1030,
    width: '100%',
    marginTop: 150,
    marginBottom: 100,

    [theme.breakpoints.down('xl')]: {
      width: '80%',
    },

    [theme.breakpoints.down('xs')]: {
      width: '95%',
      marginTop: 50,
    },
  },
  textsContainer: {
    maxWidth: 680,
  },
  title: {
    fontSize: 55,
    fontStyle: 'normal',
    letterSpacing: -0.5,
    lineHeight: '55px',
    color: theme.palette.secondary.main,

    [theme.breakpoints.down('xs')]: {
      fontSize: 34,
      letterSpacing: -0.45,
      lineHeight: '34px',
    },
  },
  title1: {
    color: theme.palette.secondary.main,
  },
  subTitle: {
    fontSize: 21,
    lineHeight: '25px',
    color: theme.palette.secondary.main,
    marginTop: 15,
  },
  text: {
    fontSize: 17,
    lineHeight: '24px',
    color: theme.palette.neutral[700],
    marginTop: 15
  },
  note: {
    fontSize: 21,
    lineHeight: '25px',
    color: theme.palette.secondary.main,
    marginTop: 15
  },
  imgContainer: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: 150,
    position: 'relative',

    [theme.breakpoints.down('md')]: {
      marginTop: 50,
    },
  },
  positionTitle: {
    fontSize: 34,
    letterSpacing: -0.45,
    marginTop: 50,
  },
  positionLabel: {
    fontSize: 21,
    lineHeight: '25px',
    color: theme.palette.neutral[600],
    marginTop: 25
  },
  zipInputContainer: {
    display: 'flex',
    marginTop: 20,

    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
      width: '100%'
    },
  },
  zipInput: {
    width: 300,
    backgroundColor: 'white',

    [theme.breakpoints.down('xs')]: {
      width: '100%'
    },
  },
  zipInputButton: {
    marginLeft: 20,
    '& button': {
      minWidth: 260,
      width: '100%',
    },

    [theme.breakpoints.down('xs')]: {
      marginLeft: 0,
      marginTop: 30
    },
  },
  sectionHeader: {
    fontSize: 34,
    letterSpacing: -0.45,
    color: theme.palette.secondary.main,
    borderBottom: '4px solid rgba(214,242,239, .3)',
    paddingBottom: 10,
    marginTop: 50,

    [theme.breakpoints.down('xs')]: {
      fontSize: 20,
      lineHeight: '25px',
    },
  },
  description: {
    color: theme.palette.common.offWhite,
    opacity: 0.5,
    marginTop: 10,
  },
  carImg: {
    zIndex: 2000,
    maxWith: 1130,
    maxHeight: 627,
    width: '100%',

    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },
  circleImg: {
    zIndex: 2000,
    position: 'absolute',
    width: 200,
    left: -100,
    bottom: 50,

    [theme.breakpoints.down('md')]: {
      width: 150,
      left: -75,
      bottom: 50,
    },

    [theme.breakpoints.down('xs')]: {
      width: 150,
      left: -75,
      bottom: -40,
    },
  },
  section1: {
    display: 'inline-block',
    position: 'absolute',
    right: 0,
    zIndex: 500,
    top: 200,

    [theme.breakpoints.down('lg')]: {
      right: 0,
    },

    [theme.breakpoints.down('md')]: {
      right: -250,
      height: 1387,
    },

    [theme.breakpoints.down(769)]: {
      display: 'none',
    },
  },
  section1Img: {
    height: 1587,

    [theme.breakpoints.down('md')]: {
      right: -250,
      height: 1300,
    },
  },
  dialog: {
    '& .MuiDialog-paper': {
      margin: 0,
      padding: 0,
      maxWidth: 1143,

      [theme.breakpoints.down('xs')]: {
        width: '95%',
      },

      [theme.breakpoints.down('sm')]: {
        width: '95%',
      },
    },
  },
  link: {
    textDecoration: 'underline',
    textUnderlineOffset: '3px',
    paddingBottom: 5,
  },
  errorMessage: {
    fontSize: 15,
    lineHeight: '20px',
    color: theme.palette.error.main,
    marginTop: 5
  },
  local: {
    background: 'rgba(149, 126, 254,0.3)',
    padding: '0 5px'
  }
}));

export default function ZipSection({ onGetStartedPress, loading, message, setMessage }) {

  const [zip, setZip] = useState('');
  const { state } = useAppState();
  const data = state.homeData;
  const classes = useStyles();
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    if (data.zipCode) {
      setZip(data.zipCode);
    }
  }, [data.zipCode])

  function handleChange(e) {
    setZip(e.target.value);
    if (message) {
      setMessage('')
    }
  }

  function handleClick() {
    onGetStartedPress(zip);
  }

  function onClose() {
    setIsOpen(false);
  }

  function handleLinkClick() {
    setIsOpen(true);
  }

  const isDisabled = !zip || message;

  return (
    <Box className={classes.zipSection} id="page1">
      <Box className={classes.contentContainer}>
        <div className={classes.section1}>
          <img src={section1} alt="section1" className={classes.section1Img}/>
        </div>
        <Box className={classes.textsContainer}>
          <div className={classes.title}>
            Reach <span className={classes.local}>local car shoppers</span>
          </div>
          <div className={classes.subTitle}>
            GroundTruth E2B helps you reach consumers who are in the <span className={classes.local}>market</span> for their next car.
          </div>
          <div className={classes.text}>
            With a Location-Based digital ad campaign, your message is delivered only
            to people who have actually shopped at a dealership in your area recently.
          </div>
          <div className={classes.text}>
            Within a few days of their visit, car shoppers will begin seeing <Button className={classes.link} onClick={handleLinkClick}>
            your digital ads</Button> on the websites and apps that they view on their phones, tablets or computers.
          </div>
          <div className={classes.note}>
            Here’s the best part - it’s really easy!
          </div>
          <Box className={classes.sectionHeader}>
            {data.location.zip ? (
              `First, it looks like you're in the ${data.location.city} area.`
            ) : (
              `First, let’s find the dealerships in your area`
            )}
          </Box>
          <div className={classes.positionLabel}>
            {data.location.zip ? (
              'No? Update your zip code here.'
            ) : (
              `What's your zip code?`
            )}
          </div>
          <div className={classes.zipInputContainer}>
            <TextField
              className={classes.zipInput}
              placeholder="zip code"
              value={zip}
              onChange={handleChange}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Location />
                  </InputAdornment>
                ),
              }}
            />
            <LoadingButton
              variant='outlined'
              className={classes.zipInputButton}
              disabled={isDisabled}
              onClick={handleClick}
              loading={loading}
            >
              Get Started&nbsp;&nbsp;
              {!isDisabled && <RightArrow />}
            </LoadingButton>
          </div>
          <div className={classes.errorMessage}>
            {message}
          </div>
        </Box>
        <Box className={classes.imgContainer}>
          <img src={carImg} alt="car" className={classes.carImg} />
          <img src={circleImg} alt='circle' className={classes.circleImg} />
        </Box>
      </Box>
      <Dialog
        className={classes.dialog}
        onClose={onClose}
        open={isOpen}
        disableEnforceFocus={true}
      >
        <DigitalAds onClose={onClose} />
      </Dialog>
    </Box>
  );
}
