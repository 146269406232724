import React, {useState} from 'react';
import {Box, Divider, makeStyles} from '@material-ui/core';
import Tabs from '../../common/Tabs';
import PieChart from './PieChart';
import BarChart from './BarChart';
import AreaChart from './AreaChart';
import InfoItem from './InfoItem';
import palette from '../../../../theme/palette';
import classNames from "classnames";
import NoCampaignBadge from "../../../elements/common/NoCampaignBadge";
import {format} from "date-fns";
import OrderFileSpecs from "../../Upload/OrderFileSpecs";
import useAppState from "../../../../lib/hooks/useAppState";
import DataController from "../../../../lib/controllers/DataController";
import {actions} from "../../../../store/store";
import {useParams} from "react-router-dom";

const colors = [
  palette.accent.subscriberSunshine[500],
  palette.accent.customerCoral[500],
  palette.primary[500],
  palette.background.start,
];

const useStyles = makeStyles(theme => ({
  endedCampaignWrapper: {
    display: 'flex',
    flexDirection: 'row',
    padding: '15px 0',
    margin: '40px 0 30px 0',
    background: 'rgba(220, 240, 255, 0.5)',
    border: `1px solid ${theme.palette.accent.marketMint[300]}`,
    boxSizing: 'border-box',
    borderRadius: 5,
    position: 'relative'
  },
  cellWrapper: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
  },
  cellTitle: {
    color: theme.palette.neutral[600],
    fontSize: 11,
    lineHeight: '18px',
  },
  cellValue: {
    fontSize: 34,
    lineHeight: '100%',
    letterSpacing: -0.45,
  },
  liveCampaignBadge: {
    position: 'absolute',
    top: -17,
    borderRadius: 14,
    padding: '4px 12px 6px',
    fontSize: 13,
    lineHeight: '18px',
    color: theme.palette.white
  },
  liveCampaignBadgeColor: {
    background: theme.palette.accent.subscriberSunshine[500],
  },
  endedCampaignBadgeColor: {
    background: theme.palette.accent.marketMint[700],
  },
  metricSectionWrapper: {
    display: 'flex',
    alignItems: 'center',
    marginTop: 45,
    marginBottom: 55,
    position: 'relative',

    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
    }
  },
  infoSectionWrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    marginLeft: 50,

    [theme.breakpoints.down('xs')]: {
      flexDirection: 'row',
      marginLeft: 0,
      flexFlow: 'wrap'
    }
  },
  tabsMenu: {
    '& .MuiTabs-root': {
      borderBottom: `1px solid ${theme.palette.neutral[700]}`,
    },
    position: 'relative',
  },
  divider: {
    marginTop: 100,
    backgroundColor: theme.palette.neutral[700],
    opacity: 1
  },
  tabDate: {
    paddingTop: 30,
    fontSize: 13,
    lineHeight: '18px',
    color: theme.palette.neutral[600],
  },
  adsWrapper: {
    marginTop: 30
  },
  tabLabel: {
    textTransform: "unset"
  },
  borders: {
    borderLeft: `1px solid ${theme.palette.background.start}`,
    borderRight: `1px solid ${theme.palette.background.start}`
  },
  note: {
    backgroundColor: theme.palette.primary.main,
    height: 82,
    color: theme.palette.common.offWhite,
    fontSize: 20,
    padding: 10,
    borderRadius: 4,
    marginTop: 20,
    lineHeight: '25px',

    [theme.breakpoints.down('xs')]: {
      fontSize: 15,
      lineHeight: '20px',
    }
  },
  title: {
    color: theme.palette.neutral[900],
    fontSize: 30
  },
  chartTitle: {
    fontSize: 20,
    lineHeight: '25px',
    alignItems: 'center',
    color: theme.palette.neutral[900],
    margin: '0 0 20px 10px',
  }
}))

export default function OrderCharts({ metrics, campaignIsNotStarted, order }) {
  const classes = useStyles();
  const [value, setValue] = useState(0);
  const { state, dispatch } = useAppState();
  const { cartId } = useParams();

  const handleTabClick = (e, index) => {
    e.preventDefault();
    setValue(index);
  };

  const fetchCampaign = async () => {
    const { data } = await DataController.getCampaign(cartId);
    dispatch({
      type: actions.SET_ORDER,
      payload: data,
    });
  }

  const status = order.is_inflight ? 'live campaign' : 'ended campaign';
  const className = metrics.is_inflight ? classes.liveCampaignBadgeColor : classes.endedCampaignBadgeColor;
  const totalClicks = metrics.totals && metrics.totals[0]?.clicks_formatted;
  const totalImpressions = metrics.totals && metrics.totals[0]?.impressions_formatted;
  const CTR = metrics.totals && metrics.totals[0]?.ctr_formatted;
  const dataKey = value ? 'impressions' : 'clicks';
  const dataKeyFormatted = `${dataKey}_formatted`;
  const percentFormatted = `${dataKey}_percent_formatted`;
  const tabDate = format(new Date(), 'MMM dd, yyyy')

  return (
    <Box id="metrics">
      <div className={classes.title}>
        Campaign Results
      </div>
      {campaignIsNotStarted && (
        <div className={classes.note}>
          Your campaign hasn't started yet. Once it has, you'll start to see performance data below.
        </div>
      )}
      <div className={classes.endedCampaignWrapper}>
        <div className={classes.cellWrapper}>
          <div className={classes.cellTitle}>TOTAL CLICKS</div>
          <div className={classes.cellValue}>{totalClicks || 0}</div>
        </div>
        <div/>
        <div className={classNames(classes.cellWrapper, classes.borders)}>
          {!campaignIsNotStarted && (
            <div className={`${classes.liveCampaignBadge} ${className}`}>{status}</div>
          )}
          <div className={classes.cellTitle}>IMPRESSIONS</div>
          <div className={classes.cellValue}>{totalImpressions || 0}</div>
        </div>
        <div className={classes.cellWrapper}>
          <div className={classes.cellTitle}>CTR</div>
          <div className={classes.cellValue}>{CTR ? `${CTR}%` : 0}</div>
        </div>
      </div>
      <div className={classes.tabsMenu}>
        <Tabs
          value={value}
          onChange={handleTabClick}
        >
          <Tabs.Label className={classes.tabLabel} label="Clicks"/>
          <Tabs.Label className={classes.tabLabel} label="Impressions"/>
          <Tabs.Label className={classes.tabLabel} label="Ads"/>
        </Tabs>
      </div>
      {[0, 1].includes(value) && (
        <>
          {tabDate && (
            <div className={classes.tabDate}>{`as of ${tabDate}`}</div>
          )}
          {(
            <>
              <div className={classes.metricSectionWrapper}>
                <AreaChart
                  data={metrics.rollup}
                  title={value ? "Total Impressions" : "Total Clicks"}
                  dataKey={dataKey}
                  nameKey="date_formatted"
                  areaColor={metrics.is_flight ? palette.primary[500] : palette.accent.customerCoral[500]}
                />
                {campaignIsNotStarted && (
                  <div style={{position: 'absolute', left: '40%', top: '40%'}}>
                    <NoCampaignBadge/>
                  </div>
                )}
              </div>
              <Divider className={classes.divider}/>
            </>
          )}
          {(
            <>
              <div className={classes.chartTitle}>By Devices</div>
              <div className={classes.metricSectionWrapper}>
                <PieChart
                  data={metrics.device}
                  title="By Devices"
                  colors={colors}
                  dataKey={dataKey}
                  nameKey="device"
                  campaignIsNotStarted={campaignIsNotStarted}
                />
                <div className={classes.infoSectionWrapper} style={campaignIsNotStarted ? {marginTop: 30} : {}}>
                  {metrics.device.map((device, index) => {
                    return (
                      <InfoItem
                        key={index}
                        label={device.device}
                        value={device[dataKeyFormatted]}
                        percent={device[percentFormatted]}
                        color={colors[index % colors.length]}
                      />
                    )
                  })}
                </div>
              </div>
              <Divider className={classes.divider}/>
            </>
          )}
          {(
            <>
              <div className={classes.chartTitle}>By Gender</div>
              <div className={classes.metricSectionWrapper}>
                <PieChart
                  data={metrics.gender}
                  title="By Gender"
                  colors={colors}
                  dataKey={dataKey}
                  nameKey="gender"
                  campaignIsNotStarted={campaignIsNotStarted}
                />
                <div className={classes.infoSectionWrapper} style={campaignIsNotStarted ? {marginTop: 30} : {}}>
                  {metrics.gender.map((gender, index) => {
                    return (
                      <InfoItem
                        key={index}
                        label={gender.gender}
                        value={gender[dataKeyFormatted]}
                        percent={gender[percentFormatted]}
                        color={colors[index % colors.length]}
                      />
                    )
                  })}
                </div>
              </div>
              <Divider className={classes.divider}/>
            </>
          )}
          {(
            <>
              <div className={classes.metricSectionWrapper}>
                <BarChart
                  data={metrics.age}
                  dataKey={dataKey}
                  nameKey="age"
                />
                {campaignIsNotStarted && (
                  <div style={{position: 'absolute', left: '40%', top: '40%'}}>
                    <NoCampaignBadge/>
                  </div>
                )}
              </div>
            </>
          )}
        </>
      )}
      {value === 2 && (
        <Box display="flex" justifyContent="center">
        {/*  {campaignIsNotStarted ? (
            <Box mt={20}>
              <NoCampaignBadge/>
            </Box>
          ) : (*/}
            <Box mt={5} width='100%'>
              {order?.id && <OrderFileSpecs order={state.order} onUploadComplete={fetchCampaign}/>}
            </Box>
        {/*  )}*/}
        </Box>
      )}
    </Box>
  )
}
