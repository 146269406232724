import * as React from 'react';
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from '@date-io/date-fns';
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
  datePicker: {
    maxWidth: 310,
    zIndex: 10000,

    '& .MuiPickersStaticWrapper-staticWrapperRoot': {
      borderRadius: 10
    }
  },
}));

function disableDays(date) {
  return date.getDay() !== 1;
}

export default function StaticDatePicker({ selectedDate, minDate, setSelectedDate }) {
  const classes = useStyles();

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <div className={classes.datePicker}>
        <DatePicker
          autoOk
          minDate={minDate}
          shouldDisableDate={disableDays}
          disableToolbar
          variant="static"
          openTo="date"
          value={selectedDate}
          onChange={setSelectedDate}
        />
      </div>
    </MuiPickersUtilsProvider>
  );
}
