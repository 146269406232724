import React, {useState} from 'react';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import { ReactComponent as GT } from "../../../images/gt.svg";
import Summary from '../common/Summary';
import LoadingButton from '../common/LoadingButton';
import {ReactComponent as RightArrow} from "../../../images/rightArrowWhite.svg";
import PersistentStorage from "../../../lib/controllers/PersistentStorage";
import DataController from "../../../lib/controllers/DataController";
import useAppState from "../../../lib/hooks/useAppState";
import { useHistory } from 'react-router-dom';
import {formatErrorObj} from "../../../lib/functions";
import {useSnackbar} from "notistack";
import {format} from "date-fns";

const useStyles = makeStyles(theme => ({
  buyNowSection: {
    backgroundColor: '#B7FF05',
    minHeight: 1024,
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    alignItems: 'center',

    [theme.breakpoints.down('xs')]: {
      minHeight: 'unset',
    },
  },
  contentContainer: {
    maxWidth: 1030,
    width: '100%',
    marginTop: 150,

    [theme.breakpoints.down('xl')]: {
      width: '80%',
    },

    [theme.breakpoints.down('xs')]: {
      width: '90%',
      marginTop: 50,
    },
  },
  sectionHeader: {
    fontSize: 34,
    letterSpacing: -0.45,
    color: '#3F3F3F',
    borderBottom: '4px solid rgba(193,181,245, .3)',
    paddingBottom: 30,
    display: 'flex'
  },
  content: {
    display: 'flex',
    alignItems: 'center',
    marginTop: 70,
    padding: 5,
    flexDirection: 'row',

    [theme.breakpoints.down(769)]: {
      flexDirection: 'column'
    },
  },
  description: {
    fontSize: 51,
    lineHeight: '60px',
    letterSpacing: '-0.5px',
    color: '#FDFDFD',
    flex: 1,

    [theme.breakpoints.down(1024)]: {
      fontSize: 28,
      lineHeight: '28px',
    },

    [theme.breakpoints.down('xs')]: {
      fontSize: 40,
      lineHeight: '40px',
    },
  },
  details: {
    color: theme.palette.neutral[900],
    fontSize: 20,
    opacity: 0.8,
    marginTop: 20,
  },
  stress: {
    color: theme.palette.primary[500],
    borderBottom: `3px solid ${theme.palette.primary[500]}`,
    cursor: "pointer"
  },
  summary: {
    flex: 1,
    display: "flex",
    flexDirection: 'column',
    justifyContent: "flex-end",
    alignItems: 'flex-end',
  },
  summaryWrapper: {
    width: 395,

    [theme.breakpoints.down(1024)]: {
      width: 324
    },

    [theme.breakpoints.down(769)]: {
      marginTop: 50,
      width: 395,
    },

    [theme.breakpoints.down('xs')]: {
      marginTop: 50,
      width: 345,
    },
  },
  buyNowButton: {
    marginTop: 20,
    width: '100%',
    maxWidth: 390,

    '& button': {
      width: '100%',
      backgroundColor: '#957EFE',
      color: 'white',

      '&:hover': {
        color: ' #3f3f3f',

        '& path': {
          stroke: '#3f3f3f'
        },
      },
    },

    [theme.breakpoints.down(769)]: {
      marginBottom: 50,
    },
  },
}));

export default function BuyNowSection({ changePage }) {

  const { state } = useAppState();
  const data = state.homeData;
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const { selectedImpression, selectedDate, location, zipCode, brandList } = data;

  const selectedBrands = brandList.filter(el => el.checked).map(el => el.brand);
  const impression = data?.impressions?.find(el => el.amount === data.selectedImpression);
  const selectedDealersCount = brandList.reduce(function(accumulator, currentValue) {
    return currentValue.checked ? accumulator + currentValue.total : accumulator;
  }, 0);

  const isReady = !!(selectedBrands.length && selectedImpression && location.city && selectedDate);
  async function createCard() {
    setLoading(true);
    const zip = zipCode;
    const brands = selectedBrands;
    const cartId = PersistentStorage.get('cartId');
    const date = format(selectedDate, 'yyyy-MM-dd');
    try {
      const { data, errors, message, success } = cartId
        ? await DataController.updateCard({zip, brands, impression: impression?.amount, date}, cartId)
        : await DataController.createCard({zip, brands, impression: impression?.amount, date})

      if (success) {
        PersistentStorage.set('cartId', data.uuid);
        history.push(`/checkout/${data.uuid}`);
      } else {
        if (Object.keys(errors).length > 0) {
          const errorObject = formatErrorObj(errors);
          console.log(errorObject);
        } else if (message) {
          enqueueSnackbar(message, {variant: 'error'})
        }
      }
    } catch (error) {
      console.log(error)
    } finally {
      setLoading(false);
    }
  }

  return (
    <Box className={classes.buyNowSection} id="page5">
      <Box className={classes.contentContainer}>
        <Box className={classes.sectionHeader}>
          <GT />&nbsp;&nbsp;
          Looks good!
        </Box>
        {isReady && (
          <div className={classes.details}>
            Here are your campaign details.
          </div>
        )}
        <Box className={classes.content}>
          {isReady ? (
            <Box className={classes.description}>
              Your campaign will reach consumers visiting <span className={classes.stress} onClick={() => changePage(2)}>{selectedDealersCount} dealers</span> in
              the <span onClick={() => changePage(1)} className={classes.stress}>{location.city} </span> area
              starting on <span onClick={() => changePage(4)} className={classes.stress}>{format(new Date(selectedDate), 'MM/dd/yyyy')}</span> and
              will run until your ad has been shown <span onClick={() => changePage(3)} className={classes.stress}>{impression.amount_formatted} times</span>.
            </Box>
          ) : (
            <Box className={classes.description}>
              Select from the parameters above to see the campaign details.
            </Box>
          )}
          <Box className={classes.summary}>
            <Box className={classes.summaryWrapper}>
              <Summary />
            </Box>
            <LoadingButton
              variant='outlined'
              className={classes.buyNowButton}
              disabled={!isReady}
              onClick={createCard}
              loading={loading}
            >
              Next&nbsp;&nbsp;
              {isReady && <RightArrow />}
            </LoadingButton>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
