/* eslint-disable */
import Box from '@material-ui/core/Box';
import DropFile from '../common/DropFile.js';
import { makeStyles } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import { ReactComponent as UploadIcon } from '../../../images/uploadLine.svg';
import { ReactComponent as DownloadButtonIcon } from '../../../images/downloadButton.svg';
import { ReactComponent as FileLineIcon } from '../../../images/fileLine.svg';
import { ReactComponent as TrimsIcon } from '../../../images/trims.svg';
import { ReactComponent as ZoomInIcon } from '../../../images/zoomIn.svg';
import React, { Fragment, useMemo, useState, useEffect } from 'react';
import AccordionWrap from '../common/AccordionWrap.js';
import Alert from '@material-ui/lab/Alert';
import classNames from 'classnames';
import OrderTrimlinesLegend from './OrderTrimlinesLegend.js';
import OrderFileDrawer from './OrderFileDrawer.js';
import { downloadFile } from '../../../lib/functions.js';
import useMediaQueries from '../../../lib/hooks/useMediaQueries.js';
import OrderControlsWrap from './OrderControlsWrap.js';
import FilePreviewDialog from '../common/FilePreviewDialog.js';

const useStyles = makeStyles((theme) => ({
  root: {
    flex: 1
  },
  dropOuter: {
    maxWidth: props => `${props.width}px`,
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  animation: {
    animationName: 'startOver',
    animationDuration: '1s',
  },
  dropWrap: {
    position: 'relative',
    paddingBottom: props => props.height === 'auto' ? 0 : `${(props.height / props.width) * 100}%`
  },
  drop: {
    position: props => props.height === 'auto' ? 'static' : 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0
  },
  exts: {
    color: theme.palette.neutral[600],
    textTransform: 'uppercase',
    fontSize: 17,
    lineHeight: '24px',
    textAlign: 'center'
  },
  unit: {
    textTransform: 'none'
  },
  buttonLabel: {
    display: 'flex',
    alignItems: 'inherit',
    justifyContent: 'inherit',
    cursor: 'pointer'
  },
  accordionSummary: {
    '&.Mui-expanded': {
      borderColor: 'transparent'
    }
  },
  accordionDetails: {
    paddingTop: 16,
    paddingBottom: 8
  },
  alertError: {
    marginTop: 24
  },
  fileNameWrapper: {
    padding: "10px 0px 10px 16px",
    backgroundColor: theme.palette.neutral[300],
    marginTop: 20,
  },
  deleteFileName: {
    fontWeight: 800,
    fontSize: 17,
    lineHeight: '24px',
    color: theme.palette.neutral[900],
  },
  titleError: {
    color: theme.palette.error.main,

    '& $titleLight': {
      color: theme.palette.error.main
    }
  },
  titleLight: {
    fontWeight: 'normal',
    color: theme.palette.neutral[600]
  },
  deleteFile: {
    position: 'absolute',
    zIndex: 10,
    left: '50%',
    top: '50%',
    transform: 'translate(-50%,-50%)',
    maxWidth: 'calc(100% - 24px)',
    opacity: 0.3,
    transition: `opacity 250ms ease`,

    '& svg': {
      marginLeft: 8,
      marginRight: 0
    },

    '&:hover': {
      opacity: 0.8
    }
  },
  togglePrintLines: {
    position: 'absolute',
    zIndex: 30,
    left: 10,
    bottom: 10,
    padding: 8,
    opacity: 0.7,
    transition: 'all 200ms ease',

    '&:hover': {
      opacity: 1
    }
  },
  startOver: {
    position: 'absolute',
    zIndex: 20,
    left: 0,
    top: 0,
    right: 0,
    bottom: 0,
    background: 'rgba(255,255,255,0.8)',
  },
  startOverButton: {
    padding: '10px 18px'
  },
  zoomWrap: {
    position: 'absolute',
    zIndex: 20,
    // left: 0,
    // top: 0,
    // right: 0,
    // bottom: 0
  },
  zoomWrapEddm: {
    '&:nth-of-type(1)': {
      top: 0,
      bottom: 'auto',
      height: '50%'
    },
    '&:nth-of-type(2)': {
      bottom: 0,
      top: 'auto',
      height: '50%'
    }
  },
  zoomButton: {
    padding: 8,
    minWidth: 0,

    '& svg': {
      margin: 0
    }
  }
}));

function getFilePreview(value) {
  if (!value) return;
  if (value.url) return value.url;
  else return URL.createObjectURL(value);
}

function OrderFileUploader({
                             orderId,
                             spec,
                             hasMultiple,
                             index,
                             length,
                             onSelect,
                             onClear,
                             onStartOver,
                             onError,
                             onWarning,
                             error,
                             value,
                             isEditableFiles,
                             isPendingFiles
                           }) {

  let width = spec.pixel_width || 586;
  let height = spec.pixel_height ? spec.pixel_height : (value && spec.mimetypes[0].startsWith('application/') ? 'auto' : 330);

  if (spec.pixel_height === "1920" && spec.pixel_width === "1080") {
    width = 500;
    height = 660;
  }

  const classes = useStyles({
    width,
    height,
  });
  const [drawerSpecOrder, setDrawerSpecOrder] = useState([]);
  const [showTrimlines, setShowTrimlines] = useState(false);
  const [fileViewerOpen, setFileViewerOpen] = useState(false);
  const [fileViewerPage, setFileViewerPage] = useState(1);
  const [animation, setAnimation]  =useState(false);
  const mediaQueries = useMediaQueries({
    sm: true,
    up: true
  });

  useEffect(() => {
    return () => {
      setShowTrimlines(false);
    };
  }, []);

  const inputId = `filespec-${spec.id}`;

  const handleSelect = file => {
    onSelect(spec, file);
  };

  const handleError = (error, file) => {
    onError(spec, error, file);
  };

  const handleWarning = (warning) => {
    if (isEditableFiles) onWarning(spec, warning);
  };

  const handleOpenDrawer = e => {
    e.preventDefault();
    setDrawerSpecOrder([orderId, spec]);
  };

  const handleCloseDrawer = () => {
    setDrawerSpecOrder([]);
  };

  const handleFileDrawerSelect = (file, order, spec) => {
    onSelect(spec, file);
  };

  const handleClearFile = e => {
    e.preventDefault();
    onClear(spec);
  };

  const handleStartOver = () => {
    setAnimation(true);
    setTimeout(() => {
      setAnimation(false);
      onStartOver(spec);
    }, 600);
  };

  const handleDownloadFile = e => {
    e.preventDefault();
    downloadFile(spec.file.original_filename, spec.file.url);
  };

  const handleToggleTrimlines = e => {
    e.preventDefault();
    setShowTrimlines(val => !val);
  };

  const handleZoom = e => {
    e.preventDefault();
    if (!value) return;
    setFileViewerOpen(true);
    setFileViewerPage(1);
  };

  const handleZoomPage2 = e => {
    e.preventDefault();
    if (!value) return;
    setFileViewerOpen(true);
    setFileViewerPage(2);
  };

  const handleViewFileDialogClose = () => {
    setFileViewerOpen(false);
  };

  // if one file, use Fragment
  const WrapperComponent = hasMultiple ? AccordionWrap : Fragment;
  const WrapperComponentProps = hasMultiple ? {
    id: `accord-${inputId}`,
    title: <Box className={classNames(
      classes.title,
      error ? classes.titleError : ''
    )}><span
      className={classes.titleLight}>{index} of {length} —</span> {spec.name}</Box>,
    initialValue: true,
    AccordionSummaryClass: classes.accordionSummary,
    AccordionDetailsClass: classes.accordionDetails
  } : {};

  const filePreview = useMemo(() => getFilePreview(value), [value]);

  const controls = [];

  const hasSelectedFile = !!(spec && spec.file);

  if (isEditableFiles) {
    const selectOrReplace = hasSelectedFile ? 'replace' : 'select';
    const deviceOrComputer = mediaQueries.sm.up ? 'computer' : 'device';
    if (!isPendingFiles) {
      controls.push(
        <Button
          key={0}
        >
          <label className={classes.buttonLabel} htmlFor={inputId}>
            <UploadIcon/>{selectOrReplace} file from {deviceOrComputer}
          </label>
        </Button>
      );
    }
    // if (spec.total_files > 0)
    //   controls.push(
    //     <Button
    //       key={1}
    //       disabled={!spec.total_files}
    //       onClick={handleOpenDrawer}
    //     >
    //       <FileLineIcon/>{selectOrReplace} from {spec.total_files} files
    //     </Button>
    //   );
  }

  if (!isPendingFiles && hasSelectedFile) {
    controls.push(
      <Button
        key={2}
        onClick={handleDownloadFile}
      >
        <DownloadButtonIcon/>download
      </Button>
    );
  }

  return <>
    <WrapperComponent {...WrapperComponentProps}>
      <Box className={classes.root}>
        <Box className={classNames(classes.dropOuter, animation && classes.animation)}>
          <Box className={classes.dropWrap}>
            <DropFile
              id={inputId}
              template={spec.template}
              className={classes.drop}
              width={parseInt(spec.pixel_width)}
              height={parseInt(spec.pixel_height)}
              maxSize={parseInt(spec.max_filesize)}
              inputAccept={spec.mimetypes.join(',')}
              onError={handleError}
              onWarning={handleWarning}
              onSelect={handleSelect}
              value={filePreview}
              measurements={spec.measurements}
              trimlines={spec.trimlines}
              hasSelectedFile={hasSelectedFile}
              hasSelectedFileShowTrimlines={showTrimlines}
              isEditableFiles={isEditableFiles}
              isError={spec?.file?.is_error}
              toggleTrimlinesButton={
                <Button
                  onClick={handleToggleTrimlines}
                  variant="contained"
                  className={classes.togglePrintLines}>
                  <TrimsIcon/>{`${showTrimlines ? "hide trim lines" : "view trim lines"}`}
                </Button>
              }
              zoomButton={
                <>
                  <Box display="flex"
                       alignItems="center"
                       justifyContent="center"
                       className={classNames(
                         classes.zoomWrap,
                         spec && spec.template === 'eddm' ? classes.zoomWrapEddm : ''
                       )}>
                    <Button
                      onClick={handleZoom}
                      variant="contained"
                      className={classes.zoomButton}>
                      <ZoomInIcon/>
                    </Button>
                  </Box>
                  {spec && spec.template === 'eddm' ?
                    <Box display="flex"
                         alignItems="center"
                         justifyContent="center"
                         className={classNames(
                           classes.zoomWrap,
                           spec && spec.template === 'eddm' ? classes.zoomWrapEddm : ''
                         )}>
                      <Button
                        onClick={handleZoomPage2}
                        variant="contained"
                        className={classes.zoomButton}>
                        <ZoomInIcon/>
                      </Button>
                    </Box> : ''}
                </>
              }
              startOverButton={
                <Box display="flex"
                     alignItems="center"
                     justifyContent="center"
                     className={classes.startOver}
                >
                  <Button
                    onClick={handleStartOver}
                    color="primary"
                    variant="contained"
                    className={classes.startOverButton}
                  >
                    Start over
                  </Button>
                </Box>
              }
            >
              <Box className={classes.exts}>{spec.filetypes.join(', ')} <span className={classes.unit}> | 150 KB</span></Box>
            </DropFile>
          </Box>
          {spec.trimlines && filePreview && value && (showTrimlines || !hasSelectedFile)
            ? <OrderTrimlinesLegend trimlines={spec.trimlines}/>
            : ''
          }
        </Box>
        {(value?.filename || value?.name) && (
          <Box className={classes.fileNameWrapper}>
            <span className={classes.deleteFileName}>{value && (value.filename || value.name)}</span>
          </Box>
        )}
        {error ? <Alert className={classes.alertError}><span dangerouslySetInnerHTML={{ __html: error }}/></Alert> : ''}
        <OrderControlsWrap controls={controls}/>
      </Box>
    </WrapperComponent>
    <OrderFileDrawer
      value={value}
      isOpen={drawerSpecOrder.length === 2}
      onClose={handleCloseDrawer}
      order={drawerSpecOrder[0]}
      spec={drawerSpecOrder[1]}
      onSelect={handleFileDrawerSelect}
    />
    <FilePreviewDialog
      onClose={handleViewFileDialogClose}
      page={fileViewerPage}
      file={value}
      isOpen={fileViewerOpen}
    />
  </>;
}

export default OrderFileUploader;
