import acura from "../images/acura.svg";
import audi from "../images/audi.svg";
import bmw from "../images/bmw.svg";
import cadillac from "../images/cadillac.svg";
import infiniti from "../images/infiniti.svg";
import jaguar from "../images/jaguar.svg";
//import landrover from "../images/landrover.svg";
//import ford from "../images/ford.png";
import ford from "../images/ford.svg";
import lexus from "../images/lexus.svg";
import subaru from "../images/subaru.svg";
import toyota from "../images/toyota.svg";
import vw from "../images/vw.svg";
import volvo from "../images/volvo.svg";
import jeep from "../images/jeep.svg";
import chevrolet from "../images/chevrolet.svg";
import honda from "../images/honda.svg";
import hyundai from "../images/hyundai.svg";
import mb from "../images/mb.svg";
import nissan from "../images/nissan.svg";
import porshce from "../images/porshce.svg";
import mazda from "../images/mazda.svg";
const brandsList = [
  {
    brand: 'Acura',
    total: 0,
    src: acura,
    checked: false
  },
  {
    brand: 'Audi',
    total: 0,
    src: audi,
    checked: false
  },
  {
    brand: 'BMW',
    total: 0,
    src: bmw,
    checked: false
  },
  {
    brand: 'Cadillac',
    total: 0,
    src: cadillac,
    checked: false
  },
  {
    brand: 'Infiniti',
    total: 0,
    src: infiniti,
    checked: false
  },
  {
    brand: 'Jaguar',
    total: 0,
    src: jaguar,
    checked: false
  },
/*  {
    brand: 'LandRover',
    total: 0,
    src: landrover,
    checked: false
  },*/
  {
    brand: 'Ford',
    total: 0,
    src: ford,
    checked: false
  },
  {
    brand: 'Lexus',
    total: 0,
    src: lexus,
    checked: false
  },
  {
    brand: 'Subaru',
    total: 0,
    src: subaru,
    checked: false
  },
  {
    brand: 'Toyota',
    total: 0,
    src: toyota,
    checked: false
  },
  {
    brand: 'Volkswagen',
    total: 0,
    src: vw,
    checked: false
  },
  {
    brand: 'Volvo',
    total: 0,
    src: volvo,
    checked: false
  },
  {
    brand: 'Dodge/Jeep',
    total: 0,
    src: jeep,
    checked: false
  },
  {
    brand: 'Chevrolet',
    total: 0,
    src: chevrolet,
    checked: false
  },
  {
    brand: 'Honda',
    total: 0,
    src: honda,
    checked: false
  },
  {
    brand: 'Hyundai',
    total: 0,
    src: hyundai,
    checked: false
  },
  {
    brand: 'Mercedes',
    total: 0,
    src: mb,
    checked: false
  },
  {
    brand: 'Nissan',
    total: 0,
    src: nissan,
    checked: false
  },
  {
    brand: 'Porsche',
    total: 0,
    src: porshce,
    checked: false
  },
  {
    brand: 'Mazda',
    total: 0,
    src: mazda,
    checked: false
  }
]

export default brandsList;
