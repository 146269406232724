import React, {useState} from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from 'react-router-dom';
import theme from './theme/theme.js';
import CssBaseline from '@material-ui/core/CssBaseline';
import { ThemeProvider } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import useGlobalStyles from './lib/hooks/useGlobalStyles.js';
import Home from './components/pages/Home'
import Invoice from './components/pages/Payment'
import Analytics from './components/pages/Analytics'
import Upload from './components/pages/Upload'
import Header from './components/elements/common/Header'
import useZipCode from "./lib/hooks/useZipCode";
import PaymentSuccess from './components/pages/PaymentSuccess/PaymentSuccess'
import StripeProvider from "./components/elements/form/StripeProvider";
import SnackbarWrapper from './components/layout/SnackbarWrapper.js';

function App() {
  const [isOpen, setIsOpen] = useState(false);
  useGlobalStyles();
  useZipCode();

  function onClose() {
    setIsOpen(false);
  }

  function openAboutButtonModal() {
    setIsOpen(true);
  }

  return (
    <Router>
      <ThemeProvider theme={theme}>
        <CssBaseline/>
        <SnackbarWrapper>
          <Container style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }} component="main" id="app-root">
            <Header openAboutButtonModal={openAboutButtonModal}/>
            <Switch>
              <Route path="/" exact>
                <Home openAboutButtonModal={openAboutButtonModal} onClose={onClose} isOpen={isOpen}/>
              </Route>
              <Route path="/checkout/:cartId" exact>
                <StripeProvider>
                  <Invoice />
                </StripeProvider>
              </Route>
              <Route path="/analytics/:cartId" exact>
                <Analytics />
              </Route>
              <Route path="/upload/:cartId" exact>
                <Upload />
              </Route>
              <Route path="/success/:cartId" exact>
                <PaymentSuccess />
              </Route>
            </Switch>
        </Container>
        </SnackbarWrapper>
      </ThemeProvider>
    </Router>
  );
}

export default App;
