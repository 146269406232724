const defaultMetrics = {
  totals: [],
  rollup: [
    {
      "date": "2021-03-15",
      "impressions": 0,
      "clicks": 0,
      "impressions_formatted": "0",
      "clicks_formatted": "0",
      "ctr": 0,
      "ctr_formatted": "0",
      "date_formatted": "Mar 15, 2021"
    },
    {
      "date": "2021-03-16",
      "impressions": 0,
      "clicks": 0,
      "impressions_formatted": "0",
      "clicks_formatted": "0",
      "ctr": 0,
      "ctr_formatted": "0",
      "date_formatted": "Mar 16, 2021"
    },
    {
      "date": "2021-03-17",
      "impressions": 0,
      "clicks": 0,
      "impressions_formatted": "0",
      "clicks_formatted": "0",
      "ctr": 0,
      "ctr_formatted": "0",
      "date_formatted": "Mar 17, 2021"
    },
    {
      "date": "2021-03-18",
      "impressions": 0,
      "clicks": 0,
      "impressions_formatted": "0",
      "clicks_formatted": "0",
      "ctr": 0,
      "ctr_formatted": "0",
      "date_formatted": "Mar 18, 2021"
    },
    {
      "date": "2021-03-19",
      "impressions": 0,
      "clicks": 0,
      "impressions_formatted": "0",
      "clicks_formatted": "0",
      "ctr": 0,
      "ctr_formatted": "0",
      "date_formatted": "Mar 19, 2021"
    },
  ],
  age: [
    {
      "age": "18-24",
      "impressions": 0,
      "clicks": 0,
      "ctr": 0,
      "impressions_percent": 0,
      "clicks_percent": 0,
    },
    {
      "age": "25-34",
      "impressions": 0,
      "clicks": 0,
      "ctr": 0,
      "impressions_percent": 0,
      "clicks_percent": 0,
    },
    {
      "age": "35-44",
      "impressions": 0,
      "clicks": 0,
      "ctr": 0,
      "impressions_percent": 0,
      "clicks_percent": 0,
    },
    {
      "age": "45-54",
      "impressions": 0,
      "clicks": 0,
      "ctr": 0,
      "impressions_percent": 0,
      "clicks_percent": 0,
    },
    {
      "age": "55-64",
      "impressions": 0,
      "clicks": 0,
      "ctr": 0,
      "impressions_percent": 0,
      "clicks_percent": 0,
    },
    {
      "age": "65 or more",
      "impressions": 0,
      "clicks": 0,
      "ctr": 0,
      "impressions_percent": 0,
      "clicks_percent": 0,
    },
    {
      "age": "undet.",
      "impressions": 0,
      "clicks": 0,
      "ctr": 0,
      "impressions_percent": 0,
      "clicks_percent": 0,
    }
  ],
  device: [
    {
      "device": "Desktop",
      "impressions": 0,
      "clicks": 0,
      "ctr": 0,
      "impressions_percent": 0,
      "clicks_percent": 0,
    },
    {
      "device": "TV",
      "impressions": 0,
      "clicks": 0,
      "ctr": 0,
      "impressions_percent": 0,
      "clicks_percent": 0,
    },
    {
      "device": "Mobile",
      "impressions": 0,
      "clicks": 0,
      "ctr": 0,
      "impressions_percent": 0,
      "clicks_percent": 0,
    },
    {
      "device": "Tablet",
      "impressions": 0,
      "clicks": 0,
      "ctr": 0,
      "impressions_percent": 0,
      "clicks_percent": 0,
    }],
  gender: [
    {
      "gender": "Female",
      "impressions": 0,
      "clicks": 0,
      "ctr": 0,
      "impressions_percent": 0,
      "clicks_percent": 0,
    },
    {
      "gender": "Male",
      "impressions": 0,
      "clicks": 0,
      "ctr": 0,
      "impressions_percent": 0,
      "clicks_percent": 0,
    },
    {
      "gender": "undet.",
      "impressions": 0,
      "clicks": 0,
      "ctr": 0,
      "impressions_percent": 0,
      "clicks_percent": 0,
    }
  ]
}

const testMetrics = {
      totals: [
      {
        "impressions": 169845,
        "clicks": 324,
        "impressions_formatted": "169,845",
        "clicks_formatted": "324",
        "ctr": 0.19,
        "ctr_formatted": "0.19"
      }
    ],
    rollup: [
      {
        "date": "2021-03-15",
        "impressions": 383,
        "clicks": 5,
        "impressions_formatted": "383",
        "clicks_formatted": "5",
        "ctr": 1.31,
        "ctr_formatted": "1.31",
        "date_formatted": "Mar 15, 2021"
      },
      {
        "date": "2021-03-16",
        "impressions": 617,
        "clicks": 22,
        "impressions_formatted": "617",
        "clicks_formatted": "22",
        "ctr": 3.57,
        "ctr_formatted": "3.57",
        "date_formatted": "Mar 16, 2021"
      },
      {
        "date": "2021-03-17",
        "impressions": 66,
        "clicks": 8,
        "impressions_formatted": "66",
        "clicks_formatted": "8",
        "ctr": 12.12,
        "ctr_formatted": "12.12",
        "date_formatted": "Mar 17, 2021"
      },
      {
        "date": "2021-03-18",
        "impressions": 400,
        "clicks": 23,
        "impressions_formatted": "400",
        "clicks_formatted": "23",
        "ctr": 5.75,
        "ctr_formatted": "5.75",
        "date_formatted": "Mar 18, 2021"
      },
      {
        "date": "2021-03-19",
        "impressions": 173,
        "clicks": 17,
        "impressions_formatted": "173",
        "clicks_formatted": "17",
        "ctr": 9.83,
        "ctr_formatted": "9.83",
        "date_formatted": "Mar 19, 2021"
      },
      {
        "date": "2021-03-20",
        "impressions": 288,
        "clicks": 30,
        "impressions_formatted": "288",
        "clicks_formatted": "30",
        "ctr": 10.42,
        "ctr_formatted": "10.42",
        "date_formatted": "Mar 20, 2021"
      },
      {
        "date": "2021-03-21",
        "impressions": 341,
        "clicks": 24,
        "impressions_formatted": "341",
        "clicks_formatted": "24",
        "ctr": 7.04,
        "ctr_formatted": "7.04",
        "date_formatted": "Mar 21, 2021"
      },
      {
        "date": "2021-03-29",
        "impressions": 20185,
        "clicks": 4,
        "impressions_formatted": "20,185",
        "clicks_formatted": "4",
        "ctr": 0.02,
        "ctr_formatted": "0.02",
        "date_formatted": "Mar 29, 2021"
      },
      {
        "date": "2021-03-30",
        "impressions": 29023,
        "clicks": 6,
        "impressions_formatted": "29,023",
        "clicks_formatted": "6",
        "ctr": 0.02,
        "ctr_formatted": "0.02",
        "date_formatted": "Mar 30, 2021"
      },
      {
        "date": "2021-03-31",
        "impressions": 29432,
        "clicks": 4,
        "impressions_formatted": "29,432",
        "clicks_formatted": "4",
        "ctr": 0.01,
        "ctr_formatted": "0.01",
        "date_formatted": "Mar 31, 2021"
      },
      {
        "date": "2021-04-01",
        "impressions": 16718,
        "clicks": 6,
        "impressions_formatted": "16,718",
        "clicks_formatted": "6",
        "ctr": 0.04,
        "ctr_formatted": "0.04",
        "date_formatted": "Apr 1, 2021"
      },
      {
        "date": "2021-04-02",
        "impressions": 16288,
        "clicks": 5,
        "impressions_formatted": "16,288",
        "clicks_formatted": "5",
        "ctr": 0.03,
        "ctr_formatted": "0.03",
        "date_formatted": "Apr 2, 2021"
      },
      {
        "date": "2021-04-03",
        "impressions": 16867,
        "clicks": 3,
        "impressions_formatted": "16,867",
        "clicks_formatted": "3",
        "ctr": 0.02,
        "ctr_formatted": "0.02",
        "date_formatted": "Apr 3, 2021"
      },
      {
        "date": "2021-04-04",
        "impressions": 17332,
        "clicks": 5,
        "impressions_formatted": "17,332",
        "clicks_formatted": "5",
        "ctr": 0.03,
        "ctr_formatted": "0.03",
        "date_formatted": "Apr 4, 2021"
      },
      {
        "date": "2021-04-05",
        "impressions": 14480,
        "clicks": 1,
        "impressions_formatted": "14,480",
        "clicks_formatted": "1",
        "ctr": 0.01,
        "ctr_formatted": "0.01",
        "date_formatted": "Apr 5, 2021"
      },
      {
        "date": "2021-04-06",
        "impressions": 1510,
        "clicks": 11,
        "impressions_formatted": "1,510",
        "clicks_formatted": "11",
        "ctr": 0.73,
        "ctr_formatted": "0.73",
        "date_formatted": "Apr 6, 2021"
      },
      {
        "date": "2021-04-07",
        "impressions": 608,
        "clicks": 12,
        "impressions_formatted": "608",
        "clicks_formatted": "12",
        "ctr": 1.97,
        "ctr_formatted": "1.97",
        "date_formatted": "Apr 7, 2021"
      },
      {
        "date": "2021-04-08",
        "impressions": 1356,
        "clicks": 24,
        "impressions_formatted": "1,356",
        "clicks_formatted": "24",
        "ctr": 1.77,
        "ctr_formatted": "1.77",
        "date_formatted": "Apr 8, 2021"
      },
      {
        "date": "2021-04-09",
        "impressions": 1497,
        "clicks": 49,
        "impressions_formatted": "1,497",
        "clicks_formatted": "49",
        "ctr": 3.27,
        "ctr_formatted": "3.27",
        "date_formatted": "Apr 9, 2021"
      },
      {
        "date": "2021-04-10",
        "impressions": 1135,
        "clicks": 29,
        "impressions_formatted": "1,135",
        "clicks_formatted": "29",
        "ctr": 2.56,
        "ctr_formatted": "2.56",
        "date_formatted": "Apr 10, 2021"
      },
      {
        "date": "2021-04-11",
        "impressions": 1146,
        "clicks": 36,
        "impressions_formatted": "1,146",
        "clicks_formatted": "36",
        "ctr": 3.14,
        "ctr_formatted": "3.14",
        "date_formatted": "Apr 11, 2021"
      }
    ],
    age: [
      {
        "age": "18-24",
        "impressions": 20767,
        "clicks": 28,
        "impressions_formatted": "20,767",
        "clicks_formatted": "28",
        "ctr": 0.13,
        "ctr_formatted": "0.13",
        "impressions_percent": 12.23,
        "impressions_percent_formatted": "12.23",
        "clicks_percent": 8.64,
        "clicks_percent_formatted": "8.64"
      },
      {
        "age": "25-34",
        "impressions": 21832,
        "clicks": 49,
        "impressions_formatted": "21,832",
        "clicks_formatted": "49",
        "ctr": 0.22,
        "ctr_formatted": "0.22",
        "impressions_percent": 12.85,
        "impressions_percent_formatted": "12.85",
        "clicks_percent": 15.12,
        "clicks_percent_formatted": "15.12"
      },
      {
        "age": "35-44",
        "impressions": 12082,
        "clicks": 44,
        "impressions_formatted": "12,082",
        "clicks_formatted": "44",
        "ctr": 0.36,
        "ctr_formatted": "0.36",
        "impressions_percent": 7.11,
        "impressions_percent_formatted": "7.11",
        "clicks_percent": 13.58,
        "clicks_percent_formatted": "13.58"
      },
      {
        "age": "45-54",
        "impressions": 11734,
        "clicks": 54,
        "impressions_formatted": "11,734",
        "clicks_formatted": "54",
        "ctr": 0.46,
        "ctr_formatted": "0.46",
        "impressions_percent": 6.91,
        "impressions_percent_formatted": "6.91",
        "clicks_percent": 16.67,
        "clicks_percent_formatted": "16.67"
      },
      {
        "age": "55-64",
        "impressions": 19175,
        "clicks": 39,
        "impressions_formatted": "19,175",
        "clicks_formatted": "39",
        "ctr": 0.2,
        "ctr_formatted": "0.20",
        "impressions_percent": 11.29,
        "impressions_percent_formatted": "11.29",
        "clicks_percent": 12.04,
        "clicks_percent_formatted": "12.04"
      },
      {
        "age": "65 or more",
        "impressions": 33419,
        "clicks": 67,
        "impressions_formatted": "33,419",
        "clicks_formatted": "67",
        "ctr": 0.2,
        "ctr_formatted": "0.20",
        "impressions_percent": 19.68,
        "impressions_percent_formatted": "19.68",
        "clicks_percent": 20.68,
        "clicks_percent_formatted": "20.68"
      },
      {
        "age": "undet.",
        "impressions": 50836,
        "clicks": 43,
        "impressions_formatted": "50,836",
        "clicks_formatted": "43",
        "ctr": 0.08,
        "ctr_formatted": "0.08",
        "impressions_percent": 29.93,
        "impressions_percent_formatted": "29.93",
        "clicks_percent": 13.27,
        "clicks_percent_formatted": "13.27"
      }
    ],
    device: [
      {
        "device": "Desktop",
        "impressions": 123288,
        "clicks": 15,
        "impressions_formatted": "123,288",
        "clicks_formatted": "15",
        "ctr": 0.01,
        "ctr_formatted": "0.01",
        "impressions_percent": 72.59,
        "impressions_percent_formatted": "72.59",
        "clicks_percent": 4.63,
        "clicks_percent_formatted": "4.63"
      },
      {
        "device": "TV",
        "impressions": 2,
        "clicks": 0,
        "impressions_formatted": "2",
        "clicks_formatted": "0",
        "ctr": 0,
        "ctr_formatted": "0.00",
        "impressions_percent": 0,
        "impressions_percent_formatted": "0.00",
        "clicks_percent": 0,
        "clicks_percent_formatted": "0.00"
      },
      {
        "device": "Mobile",
        "impressions": 37325,
        "clicks": 282,
        "impressions_formatted": "37,325",
        "clicks_formatted": "282",
        "ctr": 0.76,
        "ctr_formatted": "0.76",
        "impressions_percent": 21.98,
        "impressions_percent_formatted": "21.98",
        "clicks_percent": 87.04,
        "clicks_percent_formatted": "87.04"
      },
      {
        "device": "Tablet",
        "impressions": 9230,
        "clicks": 27,
        "impressions_formatted": "9,230",
        "clicks_formatted": "27",
        "ctr": 0.29,
        "ctr_formatted": "0.29",
        "impressions_percent": 5.43,
        "impressions_percent_formatted": "5.43",
        "clicks_percent": 8.33,
        "clicks_percent_formatted": "8.33"
      }
    ],
    parent: [
      {
        "parent": "Not a parent",
        "impressions": 62324,
        "clicks": 166,
        "impressions_formatted": "62,324",
        "clicks_formatted": "166",
        "ctr": 0.27,
        "ctr_formatted": "0.27",
        "impressions_percent": 36.69,
        "impressions_percent_formatted": "36.69",
        "clicks_percent": 51.23,
        "clicks_percent_formatted": "51.23"
      },
      {
        "parent": "Parent",
        "impressions": 19467,
        "clicks": 103,
        "impressions_formatted": "19,467",
        "clicks_formatted": "103",
        "ctr": 0.53,
        "ctr_formatted": "0.53",
        "impressions_percent": 11.46,
        "impressions_percent_formatted": "11.46",
        "clicks_percent": 31.79,
        "clicks_percent_formatted": "31.79"
      },
      {
        "parent": "undet.",
        "impressions": 88054,
        "clicks": 55,
        "impressions_formatted": "88,054",
        "clicks_formatted": "55",
        "ctr": 0.06,
        "ctr_formatted": "0.06",
        "impressions_percent": 51.84,
        "impressions_percent_formatted": "51.84",
        "clicks_percent": 16.98,
        "clicks_percent_formatted": "16.98"
      }
    ],
    gender: [
      {
        "gender": "Female",
        "impressions": 50446,
        "clicks": 166,
        "impressions_formatted": "50,446",
        "clicks_formatted": "166",
        "ctr": 0.33,
        "ctr_formatted": "0.33",
        "impressions_percent": 29.7,
        "impressions_percent_formatted": "29.70",
        "clicks_percent": 51.23,
        "clicks_percent_formatted": "51.23"
      },
      {
        "gender": "Male",
        "impressions": 96514,
        "clicks": 138,
        "impressions_formatted": "96,514",
        "clicks_formatted": "138",
        "ctr": 0.14,
        "ctr_formatted": "0.14",
        "impressions_percent": 56.82,
        "impressions_percent_formatted": "56.82",
        "clicks_percent": 42.59,
        "clicks_percent_formatted": "42.59"
      },
      {
        "gender": "undet.",
        "impressions": 22885,
        "clicks": 20,
        "impressions_formatted": "22,885",
        "clicks_formatted": "20",
        "ctr": 0.09,
        "ctr_formatted": "0.09",
        "impressions_percent": 13.47,
        "impressions_percent_formatted": "13.47",
        "clicks_percent": 6.17,
        "clicks_percent_formatted": "6.17"
      }
    ],
}

export {
  defaultMetrics,
  testMetrics
};
