import React from 'react';
import Box from '@material-ui/core/Box';
import { Tabs as MuiTabs, Tab as MuiTab } from '@material-ui/core';
import makeStyles from '@material-ui/core/styles/makeStyles';
import classNames from 'classnames';

const useStyles = makeStyles((theme) => ({
  tabPanel: {
    //padding: '24px 0'
  }
}));

const TabsWrapper = ({ children, value, index, ...rest }) => {

  const classes = useStyles();

  return <Box
    role="tabpanel"
    hidden={value !== index}
    {...rest} className={classNames(
    classes.tabPanel,
    rest.className ? rest.className : ''
  )}>
    {value === index && children}
  </Box>;
};

const Tab = (props) => {
  return <MuiTab {...props}/>;
};

const Tabs = (props) => {
  return <MuiTabs {...props} />;
};

Tabs.Label = Tab;
Tabs.Panel = TabsWrapper;

export default Tabs;
