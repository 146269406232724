/* eslint-disable */
import CustomDialog from './CustomDialog.js';
import { ReactComponent as CheckmarkButtonIcon } from '../../../images/checkmarkButton.svg';
import { ReactComponent as DownloadButtonIcon } from '../../../images/downloadButton.svg';
import Box from '@material-ui/core/Box';
import classNames from 'classnames';
import PDFViewer from './PDFViewer.js';
import React, { useRef, useMemo, useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core';
import useWindowSize from '../../../lib/hooks/useWindowSize.js';
import { downloadFile } from '../../../lib/functions.js';

const useStyles = makeStyles(theme => ({
  dialogContent: {
    maxHeight: '100%',
    overflow: 'hidden'
  },
  preview: {
    marginBottom: 24,
    maxWidth: '100%',
    marginLeft: 'auto',
    marginRight: 'auto'
  },
  previewImage: {
    '& img': {
      width: '100%',
      height: '100%'
    }
  },
  previewVideo: {
    '& video': {
      width: '100%',
      height: '100%'
    }
  },
  previewPDF: {
    '& canvas': {}
  }
}));

/**
 * Calculate preview dimensions based on element width/height and window size
 *
 * @param width
 * @param height
 * @param windowWidth
 * @param windowHeight
 */
const calculatePreviewDimensions = (width, height, windowWidth, windowHeight) => {

  const availableSpace = {
    width: windowWidth - 108,
    height: windowHeight - 308 // 64 outer margin plus header/footer of dialog
  };
  const ratio = Math.min(availableSpace.width / width, availableSpace.height / height);
  const newDimensions = {
    width: width * ratio,
    height: height * ratio
  };

  if (isNaN(newDimensions.width) || isNaN(newDimensions.height)) return {};

  return { width: `${Math.round(width * ratio)}px`, height: `${Math.round(height * ratio)}px` };
};

function FilePreviewDialog({ isOpen, file, onClose, onApply, page = 1, hasApplyButton = false }) {

  const fileEl = useRef(null);
  const classes = useStyles();
  const { windowWidth, windowHeight } = useWindowSize();
  const [initialPreviewDimensions, setInitialPreviewDimensions] = useState({ width: 0, height: 0 });

  useEffect(() => {
    if (!file) {
      setInitialPreviewDimensions({ width: 0, height: 0 });
    }
  }, [file]);

  const actions = [];
  if (hasApplyButton) actions.push(
    {
      label: <Box display="flex" alignItems="center"><CheckmarkButtonIcon/> Apply</Box>,
      color: 'primary',
      fullWidth: true,
      onClick: e => {
        e.preventDefault();
        onApply(file);
        onClose();
      }
    }
  );
  actions.push({
    label: <Box display="flex" alignItems="center"><DownloadButtonIcon/> download</Box>,
    fullWidth: true,
    variant: 'text',
    onClick: e => {
      e.preventDefault();
      downloadFile(file.filename, file.url);
    }
  });

  const previewDimensions = useMemo(() =>
      calculatePreviewDimensions(initialPreviewDimensions.width, initialPreviewDimensions.height, windowWidth, windowHeight),
    [windowWidth, windowHeight, initialPreviewDimensions.width, initialPreviewDimensions.height]);

  const handleImageLoad = () => {
    setInitialPreviewDimensions({
      width: fileEl.current.clientWidth,
      height: fileEl.current.clientHeight
    });
  };

  const handlePDFLoad = ({ width, height }) => {
    setInitialPreviewDimensions({
      width,
      height
    });
  };

  const handleVideoLoad = () => {
    setInitialPreviewDimensions({
      width: 16,
      height: 9
    });
  };

  const renderPreview = () => {
    if (!file || !file.mimetype) return '';
    if (file.mimetype.startsWith('image/')) {
      return <Box
        style={{ ...previewDimensions }}
        className={classNames(
          classes.preview,
          classes.previewImage
        )}>
        <img
          onLoad={handleImageLoad}
          ref={fileEl}
          src={file.url}
          alt="preview image"/>
      </Box>;
    } else if (file.mimetype.startsWith('application/')) {
      return <Box
        style={{ ...previewDimensions }}
        className={classNames(
          classes.preview,
          classes.previewPDF
        )}>
        <PDFViewer
          enableZoom={true}
          size="large"
          pageNumber={page}
          wrapRef={fileEl}
          src={file.url}
          onLoad={handlePDFLoad}/>
      </Box>;
    } else if (file.mimetype.startsWith('video/')) {
      return <Box
        style={{ ...previewDimensions }}
        className={classNames(
          classes.preview,
          classes.previewVideo
        )}>
        <video
          onCanPlay={handleVideoLoad}
          ref={fileEl}
          controls
          src={file.url}/>
      </Box>;
    }
    return '';
  };

  return <CustomDialog
    isOpen={isOpen}
    onClose={onClose}
    title={file && file.filename}
    positionedAbove
    hasXClose
    maxWidth={false}
    actions={actions}
    dialogContentClass={classes.dialogContent}>
    {renderPreview()}
  </CustomDialog>;
}

export default FilePreviewDialog;
