/* eslint-disable */
import React, {useEffect} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import classNames from "classnames";

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'space-between',
  },
  stepContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    marginTop: 20
  },
  step: {
    height: 10,
    width: 10,
    borderRadius: 5,
    backgroundColor: theme.palette.neutral.ink,
    opacity: 0.5,
  },
  active: {
    border: `5px solid ${theme.palette.neutral[900]}`,
    backgroundColor: 'white',
    borderRadius: 10,
    height: 20,
    width: 20,
    opacity: 0.7,
  },
  label: {
    marginRight: 5,
    fontSize: 11
  },
  stepWrapper: {
    width: 20,
    display: 'flex',
    justifyContent: 'center',
    cursor: 'pointer'
  }
}));

function debounce(func, wait, immediate) {
  let timeout;

  return function executedFunction() {
    const context = this;
    const args = arguments;

    const later = function() {
      timeout = null;
      if (!immediate) func.apply(context, args);
    };

    const callNow = immediate && !timeout;

    clearTimeout(timeout);

    timeout = setTimeout(later, wait);

    if (callNow) func.apply(context, args);
  }
}

export default function Stepper({ active = 1, setPage, page }) {

  const classes = useStyles();

  const handleScroll = debounce(function() {
    if (window.scrollY < 1500 && page !== 1) {
      setPage(1);
      console.log(1, '-', window.scrollY, '-', page);
      return;
    }
    if (window.scrollY >= 1500 && window.scrollY < 2455 && page !== 2) {
      setPage(2);
      console.log(2, '-', window.scrollY, '-', page);
      return;
    }
    if (window.scrollY >= 2455 && window.scrollY < 3475 && page !== 3) {
      setPage(3);
      console.log(3, '-', window.scrollY, '-', page);
      return;
    }
    if (window.scrollY >= 3475 && window.scrollY < 4542 && page !== 4) {
      setPage(4);
      console.log(4, '-', window.scrollY, '-', page);
      return;
    }
    if (window.scrollY >= 4542 && page !== 5) {
      setPage(5);
      console.log(5, '-', window.scrollY, '-', page);
    }
  }, 300);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll)

    return function cleanup() {
      window.removeEventListener('scroll', handleScroll);
    }
  }, [page, setPage])

  const onPress = (page) => {
    const element = document.getElementById(`page${page}`);
    const y = element.getBoundingClientRect().top + window.scrollY;
    window.scrollTo({top: y, behavior: 'smooth'});
  }

  return (
    <div className={classes.container}>

      <div className={classes.stepContainer}>
        {active === 1 && <div className={classes.label}>START</div>}
        <div className={classes.stepWrapper} onClick={() => onPress(1)}>
          <div className={classNames(classes.step, active === 1 ? classes.active : '')} />
        </div>
      </div>

      <div className={classes.stepContainer}>
        {active === 2 && <div className={classes.label}>WHERE</div>}
        <div className={classes.stepWrapper} onClick={() => onPress(2)}>
          <div className={classNames(classes.step, active === 2 ? classes.active : '')} />
        </div>
      </div>

      <div className={classes.stepContainer}>
        {active === 3 && <div className={classes.label}>IMPRESSIONS</div>}
        <div className={classes.stepWrapper} onClick={() => onPress(3)}>
          <div className={classNames(classes.step, active === 3 ? classes.active : '')} />
        </div>
      </div>

      <div className={classes.stepContainer}>
        {active === 4 && <div className={classes.label}>START DATE</div>}
        <div className={classes.stepWrapper} onClick={() => onPress(4)}>
          <div className={classNames(classes.step, active === 4 ? classes.active : '')} />
        </div>
      </div>

      <div className={classes.stepContainer}>
        {active === 5 && <div className={classes.label}>ALL SET</div>}
        <div className={classes.stepWrapper} onClick={() => onPress(5)}>
          <div className={classNames(classes.step, active === 5 ? classes.active : '')} />
        </div>
      </div>
    </div>
  );
}
