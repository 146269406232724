import { apiUrl, orderApiUrl } from '../config.js';
import PersistentStorage from '../../lib/controllers/PersistentStorage';

const appendParams = (params) => {
  if (!Object.keys(params).length) {
    return null;
  }
  const formData = new FormData();
  Object.keys(params).forEach((el) => {
    formData.append(el, params[el]);
  });
  return formData;
}

class DataController {

  constructor() {
    this.baseURL = apiUrl;
    this.orderBaseURL = orderApiUrl;
    this.apiCallInc = 0;
  }

  async APICall(url, opts, base) {
    // logging
    console.log(`[DataController] APICall ${this.apiCallInc}`, url, opts);
    this.apiCallInc++;
    const b = base ? base : this.baseURL;
    // fetch w/advanced error handling
    try {
      const res = await fetch(`${b}${url}`, opts);
      return  await res.json();
    } catch (error) {
      throw new Error(error);
    }
  }

  APICallAuth(url, opts, base) {
    const token = PersistentStorage.get('token');

    if (!token) {
      console.log('[DataController] token not found');
      return new Promise((resolve) => resolve({
        success: false
      }));
    }

    const authHeaders = {
      'Accept': 'application/json',
      'Authorization': `Bearer ${token}`
    };
    const optHeaders = (opts.headers ? opts.headers : {});
    const newHeaders = { ...authHeaders, ...optHeaders };

    const newOpts = Object.assign(opts, {
      headers: newHeaders
    });

    return this.APICall(url, newOpts, base);
  }

  getLocation(zipcode) {
    const opts = {
      method: 'GET',
    };

    return this.APICall(`/location/poi?zip=${zipcode}`, opts);
  }

  createCard({ zip, brands, impression, date }) {
    const body = appendParams({
      zip,
      mediacode: 'E2BCAR',
      brands: brands.join(','),
      impressions: impression,
      date,
    })

    const opt = {
      method: 'POST',
      body,
    };

    return this.APICall(`/checkout/create`, opt);
  }

  updateCard({ zip, brands, impression, date}, cartId ) {
    const body = appendParams({
      zip: zip,
      mediacode: 'E2BCAR',
      brands: brands.join(','),
      impressions: impression,
      date
    })

    const opt = {
      method: 'POST',
      body,
    };

    return this.APICall(`/checkout/${cartId}/update`, opt);
  }

  getCardInfo(cartId) {
    const opts = {
      method: 'GET',
    };

    return this.APICall(`/checkout/${cartId}`, opts);
  }

  placeOrder(data, cartId) {
    const formData = new FormData();
    Object.keys(data).forEach((el) => {
      formData.append(el, data[el]);
    })

    const opt = {
      method: 'POST',
      body: formData,
    };

    return this.APICall(`/checkout/${cartId}/payment`, opt);
  }

  getCampaign(cartId) {
    const opt = {
      method: 'GET',
    };

    return this.APICall(`/campaign/${cartId}`, opt);
  }

  getOrder(orderId) {
    const opt = {
      method: 'GET',
    };

    return this.APICallAuth(`/order/${orderId}`, opt);
  }

  getToken(cartId) {
    const opt = {
      method: 'GET',
    };

    return this.APICall(`/campaign/${cartId}/token`, opt);
  }

  getMetrics(id) {
    const opts = {
      method: 'GET'
    };

    return this.APICallAuth(`/order/${id}/metrics`, opts, orderApiUrl);
  }

  updateCampaignUrl({orderId, link}) {
    const formData = new FormData();
    if (link) {
      formData.append('link', link);
    }

    const opts = {
      method: 'POST',
      body: formData
    };

    return this.APICallAuth(`/files/url/${orderId}`, opts, orderApiUrl);
  }

  getPlaidLinkToken({ firstName, lastName, email, phone }, cartId) {
    const body = appendParams({
      firstname: firstName,
      lastname: lastName,
      email,
      phone,
    })

    const opt = {
      method: 'POST',
      body,
    };

    return this.APICall(`/checkout/${cartId}/ach`, opt);
  }

  postAssociateFiles(orderId, data) {
    const opts = {
      method: 'POST',
      body: JSON.stringify(data),
      headers: { 'Content-Type': 'application/json' }
    };

    return this.APICallAuth(`/files/associate/${orderId}`, opts, orderApiUrl);
  }

  getFilesPickup(id) {
    const opts = {
      method: 'GET'
    };

    return this.APICallAuth(`/files/pickup/${id}`, opts, orderApiUrl);
  }

  getOrderSpecFiles(order, spec) {
    const opts = {
      method: 'GET'
    };

    return this.APICallAuth(`/files/order/${order}/${spec}`, opts, orderApiUrl);
  }

  postFilesPickup(id, ids) {
    const formData = new FormData();
    formData.append('tids', ids.join(','));

    const opts = {
      method: 'POST',
      body: formData
    };

    return this.APICallAuth(`/files/pickup/${id}`, opts, orderApiUrl);
  }

  getFileUploadConfig(filename) {
    const opts = {
      method: 'GET'
    };

    return this.APICallAuth(`/file/upload/config?filename=${filename}`, opts, orderApiUrl);
  }

  downloadSpecPdf() {
    const URL = "https://adverator-assets.s3.amazonaws.com/assets/pdf/groundtruth-specs-for-online-ads.pdf";
    return fetch(URL, { method: 'GET' })
  }

  uploadProgressAuth(url, opts, onProgress, auth = true) {
    return new Promise((resolve, reject) => {
      try {
        const request = new XMLHttpRequest();

        request.open(opts.method || 'POST', url.startsWith('http') ? url : `${this.orderBaseURL}${url}`, true);

        const token = PersistentStorage.get('token');
        //request.setRequestHeader('Content-Type', 'multipart/form-data');
        request.setRequestHeader('Accept', 'application/json');
        if (auth) request.setRequestHeader('Authorization', `Bearer ${token}`);

        request.upload.addEventListener('progress', (e) => {
          if (typeof onProgress === 'function') onProgress((e.loaded / e.total) * 100);
        });

        request.addEventListener('load', (e) => {
          resolve(opts.raw ? request.response : JSON.parse(request.response));
        });

        request.addEventListener('error', e => resolve({ error: e }));
        request.addEventListener('abort', e => resolve({ error: e }));

        request.send(opts.body);
      } catch (e) {
        resolve({ error: e });
      }
    });
  }
}

export default new DataController();
