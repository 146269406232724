/* eslint-disable */
import makeStyles from '@material-ui/core/styles/makeStyles.js';
import { useRef, useEffect, useState } from 'react';

const imageNotLoaded = 'QIECAAAECBAgQIECAAAECEwEH8KRIMQgQIECAAAECBAgQIECAAAECBAgQIECAAAECBAgQIOAAtgECBAgQIECAAAECBAgQ';

const useStyles = makeStyles(theme => ({
  zoom: {
    backgroundPosition: '50% 50%',
    backgroundSize: '200% auto',
    position: 'relative',
    overflow: 'hidden',
    cursor: 'crosshair',
    margin: 0,

    '&:hover': {
      '& img': {
        opacity: 0
      }
    }
  },
  img: {
    transition: 'opacity .5s',
    display: 'block',
    width: '100%',
    height: 'auto'
  }
}));

let imgNotLoadedTimeout = null;

function MagnifyImage({ src, width, height }) {

  const classes = useStyles();
  const figure = useRef(null);
  const [renderCount, setRenderCount] = useState(0);

  useEffect(() => {
    if (src && src.includes(imageNotLoaded) && !imgNotLoadedTimeout) {
      imgNotLoadedTimeout = window.setInterval(() => {
        setRenderCount(val => val + 1);
      }, 100);
    } else if (imgNotLoadedTimeout) {
      window.clearInterval(imgNotLoadedTimeout);
      imgNotLoadedTimeout = null;
    }
    return () => {
      if (imgNotLoadedTimeout) {
        window.clearInterval(imgNotLoadedTimeout);
        imgNotLoadedTimeout = null;
      }
    };
  }, [src]);

  useEffect(() => {
    figure.current.addEventListener('mousemove', handleMouseMove);
    return () => {
      figure?.current?.removeEventListener('mousemove', handleMouseMove);
    };
  }, [figure]);

  const handleMouseMove = e => {
    const zoomImageEl = e.currentTarget;
    let offsetX, offsetY, x, y;

    if (e.offsetX) {
      offsetX = e.offsetX;
    }
    if (e.offsetY) {
      offsetY = e.offsetY;
    }

    // if (!offsetX && e.touches && e.touches[0]) {
    //     offsetX = e.touches[0].pageX;
    // }

    x = offsetX / zoomImageEl.offsetWidth * 100;
    y = offsetY / zoomImageEl.offsetHeight * 100;

    zoomImageEl.style.backgroundPosition = x.toFixed(2) + '% ' + y.toFixed(2) + '%';
  };

  return <figure
    ref={figure}
    className={classes.zoom}
    style={{ backgroundImage: `url("${src}")` }}>
    <img
      key={renderCount}
      className={classes.img}
      width={width}
      height={height}
      src={src}/>
  </figure>;
}

export default MagnifyImage;
