import React from 'react';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  emptyState: {
    display: "flex",
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',

    '& span': {
      maxWidth: 380,
    }
  },
  arrowUp: {
    height: 25,
    width: 25,
    marginBottom: 20
  }
}));

export default function EmptyState({ img, children }) {

  const classes = useStyles();

  function handleClick() {
    window.scrollTo({top: 0, behavior: 'smooth'});
  }

  return (
    <Box className={classes.emptyState}>
      <img src={img} alt="arrow" className={classes.arrowUp} onClick={handleClick}/>
      <span>{children}</span>
    </Box>
  );
}
