import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Icon from '@material-ui/core/Icon';
import DialogTitle from '@material-ui/core/DialogTitle';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import { ReactComponent as CloseDialogIcon } from '../../../images/closeDialog.svg';
import { makeStyles } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import ProgressButton from '../form/ProgressButton.js';
import classNames from 'classnames';

const useStyles = makeStyles((theme) => ({
  rootPositionedAbove: {
    zIndex: '2000 !important'
  },
  xCloseButton: {
    marginLeft: 16,
    marginRight: 0,
    marginTop: 2
  },
  dialogTitle: {
    marginRight: 'auto',
    flex: 1,
    overflow: 'hidden',

    '& > *': {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis'
    }
  },
  dialogTitleCloseButton: {
    display: 'flex',
    width: '100%'
  },
  fullWidthActions: {
    '& .MuiButtonBase-root': {
      marginLeft: 0,
      marginRight: 0,

      '& + .MuiButtonBase-root': {
        marginTop: 16
      }
    }
  }
}));

function CustomDialog({
                        title,
                        disableBackdropClick,
                        isOpen,
                        actions,
                        onClose,
                        children,
                        hasXClose,
                        dialogContentClass,
                        hideTitleDivider = false,
                        positionedAbove = false,
                        maxWidth = 'xs',
                        className,
                        ...rest
                      }) {

  const classes = useStyles();

  const handleClose = (e) => {
    if (onClose) onClose(e);
  };

  const container = document.querySelector('#app-root');

  const hasFullWidthActions = useMemo(() => actions.filter(act => act.fullWidth).length > 0, [actions]);

  return (
    <Dialog
      container={container}
      maxWidth={maxWidth}
      open={isOpen}
      onClose={handleClose}
      disableBackdropClick={disableBackdropClick}
      PaperProps={{ elevation: 0 }}
      className={classNames(
        className ? className : '',
        positionedAbove ? classes.rootPositionedAbove : ''
      )}
      {...rest}
    >
      {title ? <>
        <Box className={hasXClose ? classes.dialogTitleCloseButton : ''}>
          <DialogTitle id="alert-dialog-title" className={classes.dialogTitle}>
            {title}
          </DialogTitle>
          {hasXClose ? <IconButton className={classes.xCloseButton}
                                   onClick={handleClose}
                                   size="small"
                                   aria-label="close"
                                   color="secondary">
            <CloseDialogIcon/>
          </IconButton> : ''}
        </Box>
        {hideTitleDivider ? '' : <Divider/>}
      </> : ''}
      <DialogContent className={dialogContentClass}>
        <Box mb={2}>
          {children}
        </Box>
      </DialogContent>
      <DialogActions className={hasFullWidthActions ? classes.fullWidthActions : ''}>
        {actions.map((action, actionIndex) => (
          <ProgressButton
            variant={action.variant || 'contained'}
            progress={action.progress}
            key={actionIndex}
            onClick={action.onClick}
            fullWidth={!!action.fullWidth}
            disabled={action.disabled}
            color={action.color ? action.color : 'default'}>
            {action.icon ? <Icon>{action.icon}</Icon> : ''}
            {action.label}
          </ProgressButton>
        ))}
      </DialogActions>
    </Dialog>
  );
}

CustomDialog.propTypes = {
  title: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired,
  isOpen: PropTypes.bool,
  disableBackdropClick: PropTypes.bool,
  actions: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.node),
      PropTypes.node,
      PropTypes.string
    ]),
    onClick: PropTypes.func
  })).isRequired,
  onClose: PropTypes.func
};

export default CustomDialog;
