/* eslint-disable */
import Box from '@material-ui/core/Box';
import OrderFileUploader from './OrderFileUploader.js';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core';
import ProgressButton from '../form/ProgressButton.js';
import Divider from '@material-ui/core/Divider';
import useFormControl from '../../../lib/hooks/useFormControl.js';
import React, { useMemo, useState, useRef } from 'react';
import { validateRequired, validateURL } from '../../../lib/validators.js';
import S3UploadController from '../../../lib/controllers/S3UploadController.js';
import { useSnackbar } from 'notistack';
import DataController from '../../../lib/controllers/DataController.js';
import OrderDuplicateAdDialog from './OrderDuplicateAdDialog.js';
import { actions } from '../../../store/store.js';
import useAppState from '../../../lib/hooks/useAppState.js';
import OrderNotes from './OrderNotes.js';
import OrderMainAlert from './OrderMainAlert.js';
import OrderViewURL from './OrderViewURL.js';
import OrderDesignService from './OrderDesignService.js';
import Confetti from '../common/Confetti.js';
import useLinkHandler from "../../../lib/hooks/useLinkHandler";
import { useHistory, useParams } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor:  theme.palette.common.offWhite,
  },
  stepper: {
    paddingTop: 8,

    [theme.breakpoints.down('xs')]: {
      paddingLeft: 0,
      paddingRight: 0
    }
  },
  comment: {},
  multipleDivider: {
    marginTop: 0,
    marginBottom: 48,
    backgroundColor: theme.palette.neutral[700],
    opacity: 1
  },
  urlDivider: {
    backgroundColor: theme.palette.neutral[700],
    opacity: 1,
    marginTop: 24,
    marginBottom: 48
  },
  urlTitle: {
    fontSize: 20,
    lineHeight: '25px',
    color: theme.palette.neutral[900],
    marginBottom: 24
  },
  linkWrapper: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'end',
    paddingBottom: 25,
    paddingRight: 40,

    [theme.breakpoints.down('sm')]: {
      paddingRight: 0,
      justifyContent: 'center',
    }
  },
  link: {
    marginLeft: 10,
    cursor: 'pointer',
    fontSize: 17,
    lineHeight: '24px',
  },
  uploadBtn: {
    backgroundColor: theme.palette.neutral[900],
  }
}));

function OrderFileSpecs({ order, onUploadComplete }) {

  const classes = useStyles();
  const { dispatch } = useAppState();
  const [progress, setProgress] = useState(0);
  const [isDirty, setIsDirty] = useState(false);
  const [hasFileChanged, setHasFileChanged] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [dupeAdDialog, setDupeAdDialog] = useState(false);
  const [dupeAdDialogData, setDupeAdDialogData] = useState([]);
  const { enqueueSnackbar } = useSnackbar();
  const confetti = useRef();
  let { cartId } = useParams();
  const history = useHistory();
  const { getLinkButtonProps } = useLinkHandler();
  const isPendingFiles = order.is_pending_files;

  const hasURL = !!(order.filespecs[0] && order.filespecs[0].requires_url);

  const defaultForm = {
    comment: ''
  };
  if (hasURL) defaultForm.url = order?.filenotes?.campaign_url || '';
  for (let i = 0; i < order.filespecs.length; i++) {
    defaultForm[`filespec${order.filespecs[i].id}`] = order.filespecs[i].file || null;
  }

  const {
    getFieldProps,
    handleInputChange,
    setFieldError,
    setWarnings,
    errors,
    warnings,
    formData
  } = useFormControl({
    defaultForm,
    onChangeGlobal: () => {
      setIsDirty(true);
    }
  });

  const handleSubmit = async e => {
    e.preventDefault();

    console.log('submit', formData);

    const errorCallback = message => {
      setProgress(0);
      setIsSubmitted(false);

      if (message)
        enqueueSnackbar(message, {
          variant: 'error'
        });
    };

    setProgress(0);
    setIsSubmitted(true);
    const JSONData = {
      notes: formData.comment,
      files: []
    };

    const totalFiles = Object.keys(formData).filter(key => key.startsWith('filespec')).length;

    if (formData.url) JSONData.url = formData.url;

    for (let key in formData) {
      if (formData.hasOwnProperty(key)) {
        if (key.startsWith('filespec')) {
          const filespec_id = key.split('filespec')[1];
          if (formData[key].id) {
            JSONData.files.push({
              filespec_id,
              file_id: formData[key].id
            });
            setProgress(val => val + (100 / totalFiles));
          } else {
            const beforeStartProgress = progress;
            const fileUploadLocation = await S3UploadController.putUploadFile({
              file: formData[key],
              path: order.id,
              onProgress: perc => {
                console.warn(perc);
                setProgress(val => beforeStartProgress + ((100 / totalFiles) * (perc / 100)));
              }
            });
            JSONData.files.push({
              filespec_id,
              url: fileUploadLocation
            });
          }
        }
      }
    }

    console.warn(JSONData);

    const res = await DataController.postAssociateFiles(order.id, JSONData);

    if (!res.success) {
      errorCallback(res.message);
      return;
    }

    console.warn('res', res);

    // update order
    if (res.data) {
      dispatch({
        type: actions.UPDATE_ROW,
        payload: {
          location: 'orders.current',
          data: res.data
        }
      });
    }

    // look for ads to duplicate to
    const filesPickup = await DataController.getFilesPickup(order.id);

    if (filesPickup.success) {
      if (filesPickup.data && filesPickup.data.length > 0) {
        setDupeAdDialogData(filesPickup.data);
      }

      setDupeAdDialog(true);
      setTimeout(() => {
        confetti.current.playConfetti();
      }, 200);
    }

    // reset
    setProgress(0);
    setIsSubmitted(false);
    setHasFileChanged(false);
  };

  const handleFileSelect = (spec, file) => {
    setHasFileChanged(true);
    setFieldError(`filespec${spec.id}`);
    handleInputChange({ target: { value: file } }, `filespec${spec.id}`);
  };

  const handleClearFile = spec => {
    setFieldError(`filespec${spec.id}`);
    handleInputChange({ target: { value: null } }, `filespec${spec.id}`);
  };

  const handleStartOver = spec => {
    const newSpecs = [...order.filespecs];
    for (let i = 0; i < newSpecs.length; i++) {
      if (newSpecs[i].id === spec.id) {
        newSpecs[i].file = null;
        newSpecs[i].is_error = false;
      }
    }
    dispatch({
      type: actions.UPDATE_ROW,
      payload: {
        location: 'orders.current',
        data: {
          file_message: null,
          is_editable: true,
          is_editable_files: true,
          filespecs: newSpecs
        }
      }
    });
    setFieldError(`filespec${spec.id}`);
    handleInputChange({ target: { value: null } }, `filespec${spec.id}`);
    //setErrors({});
    setWarnings({});
  };

  const handleFileError = (spec, error, file) => {
    setFieldError(`filespec${spec.id}`, error);
    handleInputChange({ target: { value: '' } }, `filespec${spec.id}`);
  };

  const handleFileWarning = (spec, warning) => {
    setFieldError(`filespec${spec.id}`, warning, 'warning');
  };

  const handleDupeAdClose = () => {
    setDupeAdDialog(false);
    onUploadComplete && onUploadComplete();
    history.push(`/analytics/${cartId}`);
  };

  const isFormDataValid = useMemo(() => {
    let isValid = true;
    for (let key in formData) {
      if (formData.hasOwnProperty(key)) {
        if (key !== 'comment' && !formData[key]) {
          isValid = false;
          break;
        } else if (errors[key]) {
          isValid = false;
          break;
        }
      }
    }
    return isValid;
  }, [formData]);

  // if file is set and
  // if file has changed, show comment and url as editable fields
  const isURLSet = !!order?.filenotes?.campaign_url;
  const isNoteSet = !!order?.filenotes?.note;
  // hasFileChanged

  return <>
    <Box className={classes.root}>
      {order.has_design_service ?
        <OrderDesignService url={order.design_service_url}/> :
        <>
          <OrderMainAlert order={order}/>
          {order.filespecs.map((spec, specIndex) =>
            <OrderFileUploader
              orderId={order.id}
              value={formData[`filespec${spec.id}`]}
              error={errors[`filespec${spec.id}`] || warnings[`filespec${spec.id}`]}
              onSelect={handleFileSelect}
              onClear={handleClearFile}
              onStartOver={handleStartOver}
              onError={handleFileError}
              onWarning={handleFileWarning}
              key={spec.id}
              index={specIndex + 1}
              length={order.filespecs.length}
              hasMultiple={order.filespecs.length > 1}
              isEditableFiles={order.is_editable_files}
              spec={spec}
              isPendingFiles={isPendingFiles}
            />)}
          {order.filespecs.length > 1 ? <Divider className={classes.multipleDivider}/> : ''}
          {!isPendingFiles && (
            <>
              <Box my={3}>
                {isURLSet || isNoteSet && !hasFileChanged ?
                  <>
                    <OrderViewURL order={order}/>
                    <OrderNotes order={order}/>
                  </> :
                  <>
                    {hasURL ? <Box>
                      <Box className={classes.urlTitle}>Campaign URL</Box>
                      <TextField
                        className={classes.comment}
                        placeholder="https://"
                        {...getFieldProps('url', false, null, [validateRequired, validateURL])}
                      />
                      <Divider className={classes.urlDivider}/>
                    </Box> : ''}
                    <TextField
                      className={classes.comment}
                      placeholder="Leave a comment"
                      multiline
                      rows={3}
                      {...getFieldProps('comment')}
                    />
                  </>
                }
              </Box>
              {order.is_editable || order.is_editable_files ?
                <>
                  <ProgressButton
                    className={classes.uploadBtn}
                    progress={progress}
                    variant="contained"
                    disabled={!isFormDataValid || isSubmitted || !isDirty || !hasFileChanged}
                    fullWidth
                    isLoaderBlack
                    onClick={handleSubmit}
                  >
                    Confirm and Upload Ad
                  </ProgressButton>
                </> : ''}
            </>
          )}
          <Divider/>
        </>}
    </Box>
    <OrderDuplicateAdDialog
      onClose={handleDupeAdClose}
      orderId={order.id}
      order={order}
      data={dupeAdDialogData}
      isOpen={dupeAdDialog}
    />
    <Confetti ref={confetti}/>
  </>;
}

export default OrderFileSpecs;
