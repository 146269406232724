/* eslint-disable */
import React, { useEffect, useState } from 'react';
import { Box, makeStyles } from "@material-ui/core";
import Charts from "../../elements/Analytics/Charts";
import Sidebar from "../../elements/common/SideBar";
import Container from '@material-ui/core/Container';
import DataController from "../../../lib/controllers/DataController";
import {useSnackbar} from "notistack";
import {useParams} from "react-router-dom";
import PersistentStorage from "../../../lib/controllers/PersistentStorage";
import useAppState from "../../../lib/hooks/useAppState";
import { actions } from '../../../store/store';

const useStyles = makeStyles(theme => ({
  wrapper: {
    width: '100%',
    maxWidth: 1100,
    padding: '0 40px 50px 40px',

    [theme.breakpoints.down('xs')]: {
      padding: '0 10px 50px 10px',
    },
  },
  container: {
    display: 'flex',
    backgroundColor: theme.palette.common.offWhite,
    border: `1px solid ${theme.palette.neutral[400]}`,
    marginTop: 100,
    boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.12), 0px 1px 1px rgba(0, 0, 0, 0.14)',
    borderRadius: 10,

    [theme.breakpoints.down(769)]: {
      flexDirection: 'column'
    },

    [theme.breakpoints.down('xs')]: {
      marginTop: 40,
    },
  },
  charts: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    padding: 20
  },
  details: {
    display: 'flex',
    flexDirection: 'column',
    width: 450,

    [theme.breakpoints.down('md')]: {
      width: 290
    },

    [theme.breakpoints.down(769)]: {
      width: '100%'
    },
  },
}));

export default function AnalyticsPage() {
  const classes = useStyles();
  const [campaignIsNotStarted, setCampaignIsNotStarted] = useState(true);
  const { enqueueSnackbar } = useSnackbar();
  const { cartId } = useParams();
  const { state, dispatch } = useAppState();
  const { token, order, metrics } = state;

  useEffect(() => {
    window.scrollTo({top: 0, behavior: "smooth"});
  }, []);

  useEffect(() => {
    async function fetchToken() {
      try {
        const { data, message, success } = await DataController.getToken(cartId);
        if (success) {
          PersistentStorage.set('token', data.token);
          dispatch({
            type: actions.SET_TOKEN,
            payload:data.token
          });
          return;
        }
        if (message) {
          enqueueSnackbar(message, {variant: 'error'})
        }
      } catch (error) {
        console.log(error)
      }
    }

    if (cartId) {
      fetchToken()
    }
  }, [cartId]);

  useEffect(() => {
    async function getMetrics() {
      try {
        const { data, message, success } = await DataController.getMetrics(order.id);
        if (success) {
          dispatch({
            type: actions.SET_METRICS,
            payload: data
          });
          setCampaignIsNotStarted(false);
        }
      } catch (error) {
        console.log(error)
      }
    }

    if (cartId && token && order.id) {
      getMetrics()
    }
  }, [cartId, token, order.id]);

  return (
    <Box className={classes.wrapper}>
      <Container className={classes.container}>
        <Box className={classes.charts}>
          <Charts
            metrics={metrics}
            campaignIsNotStarted={campaignIsNotStarted}
            order={order}
          />
        </Box>
        <Box className={classes.details}>
          <Sidebar />
        </Box>
      </Container>
    </Box>
  )
}
