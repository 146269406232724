/* eslint-disable */
import React from 'react';
import { ReactSVG } from 'react-svg'
import Box from '@material-ui/core/Box';
import Checkbox from '@material-ui/core/Checkbox';
import { makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import useAppState from '../../../lib/hooks/useAppState';
import {actions} from "../../../store/store";

const useStyles = makeStyles(theme => ({
  impressionsSection: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '5px 8px'
  },
  dealer: {
    borderRadius: 8,
    height: 80,
    width: 80,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: theme.palette.common.offWhite,
    marginBottom: 10,
    position: 'relative',
    cursor: 'pointer',

    [theme.breakpoints.down('xs')]: {
      height: 70,
      width: 70,
    },
  },
  checkbox: {
    position: "absolute",
    right: -3,
    top: -3,

    '& svg': {
      width: 16,
      height: 16,
    }
  },
  container: {
    display: 'flex',
    justifyContent: 'space-between',
    width: 380,
    flexWrap: 'wrap',
    [theme.breakpoints.down('xs')]: {
      width: 340,
    },
  },
  disabled: {
    opacity: 0.2
  },
  selected: {
    backgroundColor: '#B7FF05',
  },
}));

export default function DealershipList() {
  const { state, dispatch } = useAppState();
  const classes = useStyles();
  const { brandList } = state.homeData;

  function handleBrandClick(selectedBrand) {
    if (!selectedBrand.total) {
      return;
    }
    const updatedBrandList = brandList.map((brand) => {
      if (selectedBrand.brand === brand.brand) return { ...brand, checked: !brand.checked };
      return brand;
    });
    dispatch({
      type: actions.UPDATE_BRAND_LIST,
      payload: updatedBrandList
    })
  }

  return (
    <Box className={classes.container}>
      {brandList.map((brand, key) => {
        const isDisabled = brand.total === 0;
        return (
          <div
            key={key}
            className={classNames(classes.dealer, isDisabled ? classes.disabled : '', brand.checked ? classes.selected : '')}
            onClick={() => handleBrandClick(brand)}
          >
            {!isDisabled && <Checkbox className={classes.checkbox} checked={brand.checked}/>}
            <ReactSVG src={brand.src} alt={brand.brand}/>
          </div>
        )
      })}
    </Box>
  );
}
