import React from 'react';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import { ReactComponent as E2B } from "../../../images/e2b.svg";

const useStyles = makeStyles(theme => ({
  logo: {
    position: "absolute",
    top: 10,
    left: 100,
    zIndex: 10,
    pointer: 'cursor',

    [theme.breakpoints.down('xs')]: {
      left: 20,
      height: 60,
      width: 74
    }
  },
  header: {
    height: 50,
    width: '100%',
    backgroundColor: '#B7FF05',
    position: "relative",
    boxShadow: "0px 1px 3px rgba(0, 0, 0, 0.12), 0px 1px 1px rgba(0, 0, 0, 0.14)",
  },
}));

export default function Header({ openAboutButtonModal }) {

  const classes = useStyles();

  return (
    <Box className={classes.header}>
      <E2B className={classes.logo} onClick={openAboutButtonModal}/>
    </Box>
  );
}
