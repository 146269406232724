import DataController from './DataController.js';

class S3UploadController {

  constructor() {
    this.s3SDK = 'https://sdk.amazonaws.com/js/aws-sdk-2.1.24.min.js';
    this.isLoading = false;
    this.isScriptLoaded = false;
    this.uploadConfig = null;
    this.s3 = null;
  }

  getUploadConfig(fileName) {
    return new Promise(resolve => {
      if (this.uploadConfig && !fileName) {
        resolve(true);
        return;
      }
      DataController.getFileUploadConfig(fileName).then(res => {
        console.log("");
        this.uploadConfig = res.data;
        resolve(true);
      });
    });
  }

  async putUploadFile({ file, path, onProgress }) {

    const fileName = file.name;
    const filePath = `${path ? `${path}/` : ''}${new Date().getTime()}/${fileName}`;
    const uploadConfig = await this.getUploadConfig(filePath);
    console.log('uploadConfig', uploadConfig, this);

    const opts = {
      method: 'PUT',
      body: file,
      raw: true
    };

    console.log(":::UPLOAD_PROGRESS_STARTED");
    console.log(":::UPLOAD_CONFIG", this.uploadConfig);

    await DataController.uploadProgressAuth(
      this.uploadConfig.presigned_url,
      opts,
      perc => onProgress(perc),
      false
    );

    return this.uploadConfig.presigned_url.split('?')[0];
  }
}

export default new S3UploadController();
