import React, { Fragment } from 'react';
import Divider from '@material-ui/core/Divider';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core';
import classNames from 'classnames';

const convertToColStyles = {
  flexDirection: 'column',
  paddingTop: 16,
  paddingBottom: 16,

  '& .MuiButtonBase-root': {},

  '& $controlsDivider': {
    width: '100%',
    height: 1,
    marginBottom: 16,
    marginTop: 16
  }
};

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: 72,
    flexWrap: 'wrap',
  },
  rootHas1: {},
  rootHas2: {
    [theme.breakpoints.down('sm')]: {
      ...convertToColStyles
    }
  },
  rootHas3: {
    [theme.breakpoints.down('md')]: {
      ...convertToColStyles
    }
  },
  controlsDivider: {
    marginLeft: 16,
    marginRight: 16,
    height: 24
  }
}));

function OrderControlsWrap({ controls }) {

  const classes = useStyles();

  if (controls.length === 0) return '';

  let controlsRootClass;
  if (controls.length < 2) {
    controlsRootClass = 'rootHas1';
  } else if (controls.length === 2) {
    controlsRootClass = 'rootHas2';
  } else {
    controlsRootClass = 'rootHas3';
  }

  return <Box className={classNames(
    classes.root,
    classes[controlsRootClass]
  )}>
    {controls.map((control, controlIndex) => {
      if (controlIndex !== 0) {
        return (
          <Fragment key={`div-${controlIndex}`}>
            <Divider className={classes.controlsDivider} orientation="vertical"/>
            {control}
          </Fragment>
        );
      }
      return control;
    })}
  </Box>;
}

export default OrderControlsWrap;
