import React from 'react';
import { makeStyles } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Alert from '@material-ui/lab/Alert';
import { ReactComponent as ClockIcon } from '../../../images/clock.svg';
import { ReactComponent as SmileIcon } from '../../../images/smile.svg';
import { ReactComponent as EditIcon } from '../../../images/editAlert.svg';

const useStyles = makeStyles(theme => ({
  root: {},
  alert: {
    '& + $alert': {
      marginTop: 16
    }
  }
}));

function OrderMainAlert({ order }) {

  const classes = useStyles();

  if (!order?.file_message?.type) return '';

  let alertIcon = '';
  if (order?.file_message?.type) {
    if (order.file_message.type === 'warning') {
      alertIcon = <ClockIcon/>;
    } else if (order.file_message.type === 'success') {
      alertIcon = <SmileIcon/>;
    } else if (order.file_message.type === 'edit') {
      alertIcon = <EditIcon/>;
    }
  }

  const type = order.file_message.type === 'edit' ? 'success' : order.file_message.type;

  return <Box mb={3} className={classes.root}>
    <Alert
      icon={alertIcon}
      className={classes.alert}
      severity={type}>
            <span dangerouslySetInnerHTML={{
              __html: order.file_message.body
            }}/>
    </Alert>
  </Box>;
}

export default OrderMainAlert;
