import React from 'react';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import {Button} from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  buyNowSection: {
    height: 80,
    backgroundColor: '#A5E604',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '0 24px'
  },
  aboutButton: {
    color: theme.palette.primary[700],

    '& span': {
      [theme.breakpoints.down('sm')]: {
        fontSize: 15,
      },
    }
  },
  copyright: {
    color: theme.palette.neutral[900],
    fontSize: 17,

    [theme.breakpoints.down('sm')]: {
      fontSize: 15,
      textAlign: 'right'
    },
  },
}));

export default function Footer({ openAboutButtonModal }) {
  const classes = useStyles();

  return (
    <Box className={classes.buyNowSection}>
      <div>
        <Button
          className={classes.aboutButton}
          onClick={openAboutButtonModal}
        >
          About GroundTruth
        </Button>
      </div>
      <div className={classes.copyright}>
        © 2022 GroundTruth. All rights reserved.
      </div>
    </Box>
  );
}
