/* eslint-disable */
import React from "react";
import { Box, makeStyles } from "@material-ui/core";
import OrderFileSpecs from "./OrderFileSpecs";
import useAppState from "../../../lib/hooks/useAppState";
import {useSnackbar} from "notistack";
import {useParams} from "react-router-dom";

const useStyles = makeStyles(theme => ({
  wrap: {
    minHeight: '100%',
  },
  title: {
    fontSize: 34,
    lineHeight: '34px',
    letterSpacing: -0.45,
    paddingBottom: 20,
  },
  accordionWrap: {
    padding: '24px 16px',

    [theme.breakpoints.up('xl')]: {
      padding: '24px 32px',
    },
  },
  addOnsWrap: {
    flex: 1,
    background: theme.palette.neutral[300],
    padding: '24px 16px',

    [theme.breakpoints.up('xl')]: {
      padding: '24px 32px',
    },
  },
  item: {
    display: 'flex',
    justifyContent: 'space-between',
    borderBottom: `1px solid ${theme.palette.neutral[300]}`,
    padding: '15px 0',
    marginTop: 5,
  },
}))

export default function FileUpload() {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const { dispatch, state } = useAppState();
  const { cartId } = useParams();

  return (
    <Box className={classes.wrap} display="flex" flexDirection="column">
      <span className={classes.title}>Upload your ads</span>
      {state?.order?.id && <OrderFileSpecs order={state.order} />}
    </Box>
  )
}
