import React from 'react';
import Box from '@material-ui/core/Box';
import {makeStyles} from '@material-ui/core/styles';
import useAppState from "../../../lib/hooks/useAppState";
import {useLocation} from "react-router-dom";
import {format} from "date-fns";

const useStyles = makeStyles(theme => ({
  dateSection: {
    backgroundColor: theme.palette.common.offWhite,
    display: 'flex',
    flexDirection: 'column',
    padding: '40px 20px',
    borderRadius: 10,
    border: `2px solid ${theme.palette.neutral[900]}`,
  },
  item: {
    display: 'flex',
    justifyContent: 'space-between',
    borderBottom: `1px solid ${theme.palette.neutral[300]}`,
    padding: '15px 0',
  },
  result: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: 40,

    '& div': {
      color: theme.palette.neutral[900],
      fontWeight: 800,
      fontSize: 20,
      lineHeight: '25px'
    }
  },
  label: {
    color: theme.palette.neutral[900],
    fontWeight: 800,
    fontSize: 20,
    marginTop: 20
  }
}));

export default function Summary({ summaryE2b = {} }) {

  const classes = useStyles();
  const {state} = useAppState();
  const summary = state.homeData;
  let location = useLocation();

  const selectedDealersCount = summary.brandList.reduce(function (accumulator, currentValue) {
    return currentValue.checked ? accumulator + currentValue.total : accumulator;
  }, 0);

  const impression = summary?.impressions?.find(el => el.amount === summary.selectedImpression);

  let who = summaryE2b.who;
  let what = summaryE2b.what;
  let where = summaryE2b.where;
  let when = summaryE2b.when;
  let total = summaryE2b.Total;

  if (location.pathname === "/") {
    who = selectedDealersCount ? `cars shoppers at ${selectedDealersCount} dealers` : '';
    what = impression?.amount_formatted ? `${impression?.amount_formatted} impressions` : '';
    where = summary.location?.city ? `${summary.location?.city}` : '';
    when = summary?.selectedDate ? `${format(new Date(summary?.selectedDate), 'MM/dd/yyyy')} start` : '';
    total = impression?.price_formatted ? `$ ${impression?.price_formatted}` : '';
  }

  return (
    <div className={classes.dateSection}>
      <div className={classes.label}>Summary</div>
      <Box className={classes.item}/>
      <Box className={classes.item}>
        <div>who</div>
        <div>{who || 'nothing selected'}</div>
      </Box>
      <Box className={classes.item}>
        <div>what</div>
        <div>{what || 'nothing selected'}</div>
      </Box>
      <Box className={classes.item}>
        <div>where</div>
        <div>{where || 'nothing selected'}</div>
      </Box>
      <Box className={classes.item}>
        <div>when</div>
        <div>{when || 'nothing selected'}</div>
      </Box>
      <Box className={classes.result}>
        <div>Price</div>
        <div>{total || '$0'}</div>
      </Box>
    </div>
  );
}
