import React, {useState} from 'react';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import Button from "@material-ui/core/Button";
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import {ReactComponent as RightArrow} from "../../../images/rightArrow.svg";
import {ReactComponent as Arrow} from "../../../images/arrow.svg";
import {ReactComponent as Dollar} from "../../../images/dollar.svg";
import classNames from "classnames";
import {actions} from "../../../store/store";
import useAppState from "../../../lib/hooks/useAppState";
import section2 from "../../../images/section2.png";
import arrowUp from "../../../images/arrowUp.png";
import EmptyState from "./EmptyState";
import Dialog from "@material-ui/core/Dialog";
import Inventory from "./Modals/Inventory";

const useStyles = makeStyles(theme => ({
  impressionsSection: {
    backgroundColor: theme.palette.background.start,
    minHeight: 1024,
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    alignItems: 'center',

    [theme.breakpoints.down('xs')]: {
      minHeight: 'unset',
      paddingBottom: 50
    },
  },
  contentContainer: {
    maxWidth: 1030,
    width: '100%',
    marginTop: 150,

    [theme.breakpoints.down('xl')]: {
      width: '80%',
    },

    [theme.breakpoints.down('xs')]: {
      width: '90%',
      marginTop: 50,
    },
  },
  sectionHeader: {
    fontSize: 34,
    lineHeight: '34px',
    letterSpacing: -0.45,
    color: theme.palette.accent.marketMint[900],
    borderBottom: '4px solid rgba(177,177,177, .2)',
    paddingBottom: 15,
  },
  description: {
    color: theme.palette.text.primary,
    opacity: 0.8,
    marginTop: 20,
    fontSize: 20,
    lineHeight: '25px'
  },
  optionsWrapper: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: 70,
    zIndex: 6000,

    [theme.breakpoints.down('xs')]: {
      marginTop: 20,
    },
  },
  footerWrapper: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: 100,
    color: theme.palette.common.offWhite,
    zIndex: 3000,

    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
      marginTop: 50,
    },
  },
  zipInputButton: {
    zIndex: 6000,

    [theme.breakpoints.down('xs')]: {
      width: '100%',
      marginTop: 30
    },
  },
  impressionNumber: {
    fontSize: 17,
    fontWeight: 800,
    color: theme.palette.accent.marketMint[900],
  },
  activeImpressionNumber: {
    color: '#7F6BD8',

    '& span': {
      color: '#7F6BD8',
    }
  },
  impressionAmount: {
    color: theme.palette.primary[400],
    letterSpacing: -0.274271,
    lineHeight: '110px',
    fontSize: 110,
    opacity: 0.5,
    paddingTop: 5,
    paddingBottom: 5,
    display: 'flex',
    justifyContent: "flex-end",
    alignItems: 'center',

    [theme.breakpoints.down(769)]: {
      fontSize: 55,
      lineHeight: '55px',
    },

    [theme.breakpoints.down('xs')]: {
      fontSize: 17,
      lineHeight: '50px',
      opacity: 1,
      color: theme.palette.accent.marketMint[900],
      fontWeight: 800
    },
  },
  activeImpressionAmount: {
    color: '#7F6BD8',
    letterSpacing: -0.5,
    fontSize: 200,
    opacity: 1,

    [theme.breakpoints.down(769)]: {
      fontSize: 100,
    },

    [theme.breakpoints.down('xs')]: {
      fontSize: 17,
      color: theme.palette.accent.marketMint[900],
      fontWeight: 800
    },
  },
  impressionItem: {
    marginTop: 10,
    marginBottom: 10,
  },
  section2: {
    display: 'inline-block',
    position: 'absolute',
    left: 0,
    zIndex: 500,
    top: -120,

    [theme.breakpoints.down('md')]: {
      right: 0,
    },

    [theme.breakpoints.down(769)]: {
      display: 'none',
    },
  },
  section2Img: {
    height: 1587,

    [theme.breakpoints.down('md')]: {
      right: 0,
      height: 1300,
    },
  },
  arrow: {
    [theme.breakpoints.down('md')]: {
      width: '70%'
    },
    [theme.breakpoints.down('sm')]: {
      display: 'none'
    },
  },
  dollarImg: {
    [theme.breakpoints.down(769)]: {
      height: 50,
    },

    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
  },
  dollarSign: {
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  emptyStateText: {
    fontSize: 21,
    lineHeight: '25px',
    color: theme.palette.neutral[900],
  },
  emptyState: {
    marginTop: 200,
  },
  dialog: {
    zIndex: 7000,
    '& .MuiDialog-paper': {
      margin: 0,
      padding: 0,
      maxWidth: 1143,
      zIndex: 7000,

      [theme.breakpoints.down('xs')]: {
        width: '95%',
      },

      [theme.breakpoints.down('sm')]: {
        width: '95%',
      },
    },
  },
  link: {
    textDecoration: 'underline',
    textUnderlineOffset: '3px',

    '& span': {
      fontSize: 20,
      fontWeight: 800,
    }
  }
}));

export default function ImpressionsSection({ changePage }) {
  const classes = useStyles();
  const { state, dispatch } = useAppState();
  const data = state.homeData;
  const [isOpen, setIsOpen] = useState(false);

  function handleChange(e) {
    dispatch({
      type: actions.UPDATE_SELECTED_IMPRESSION,
      payload: Number(e.target.value)
    })
  }

  function onClose() {
    setIsOpen(false);
  }

  function handleConfirm() {
    changePage(4);
  }

  function handleInventoryClick(e) {
    e.preventDefault();
    setIsOpen(true);
  }

  const checkedImpression = data.selectedImpression;

  return (
    <Box className={classes.impressionsSection} id="page3">
      <Box className={classes.contentContainer}>
        <div className={classes.section2}>
          <img src={section2} alt="section2" className={classes.section2Img} />
        </div>
        <Box className={classes.sectionHeader}>
          How many times would you like your ad displayed?
        </Box>
        <div className={classes.description}>
          Each time your ad appears on a car shopper’s phone or computer counts as one impression.
        </div>
        <Box display="flex" justifyContent="center" flexDirection="column">
          {data.impressions.length ? (
            <>
              <Box className={classes.optionsWrapper}>
                <>
                  <RadioGroup
                    aria-label="impressions"
                    value={checkedImpression}
                    name="radio-buttons-group"
                    onChange={handleChange}
                  >
                    {data.impressions && data.impressions.map((impression, key) => {
                      return (
                        <FormControlLabel
                          className={classes.impressionItem}
                          key={key}
                          value={impression.amount}
                          control={<Radio />}
                          label={<span className={checkedImpression === impression?.amount ? classes.activeImpressionNumber : ''}><span className={classes.impressionNumber}>{impression.amount_formatted} </span>impressions</span>}
                        />
                      )
                    })}
                  </RadioGroup>
                  <div>
                    {data.impressions && data.impressions.map((impression, key) => {
                      return (
                        <div key={key} className={classNames(classes.impressionAmount, checkedImpression === impression?.amount ? classes.activeImpressionAmount : '')}>
                          {checkedImpression === impression?.amount && (
                            <>
                              <Arrow className={classes.arrow} />&nbsp;
                              <Dollar className={classes.dollarImg} />
                            </>
                          )}
                          &nbsp;<span className={classes.dollarSign}>$</span>{impression.price_formatted}
                        </div>
                      )
                    })}
                  </div>
                </>
              </Box>
              <div className={classes.footerWrapper}>
                <Button variant="text" className={classes.link} onClick={handleInventoryClick}>Inventory is limited!</Button>
                <Button
                  variant='outlined'
                  className={classes.zipInputButton}
                  disabled={!checkedImpression}
                  onClick={handleConfirm}
                >
                  Confirm Impressions&nbsp;&nbsp;
                  {!!checkedImpression && <RightArrow />}
                </Button>
              </div>
            </>
          ) : (
           <Box mt={30}>
             <EmptyState img={arrowUp}>
               <span className={classes.emptyStateText}>Select a location above to see available impressions for your area.</span>
             </EmptyState>
           </Box>
          )}
        </Box>
      </Box>
      <Dialog
        className={classes.dialog}
        onClose={onClose}
        open={isOpen}
        disableEnforceFocus={true}
      >
        <Inventory onClose={onClose} />
      </Dialog>
    </Box>
  );
}
