import actions from './actions.js';

const reducer = (state, action) => {
  switch (action.type) {
    case actions.SET_HOME_DATA:
      let data = action.payload;
      const updatedBrandList = state.homeData.brandList.map((brand) => {
        const el = data?.brands?.find(el => (el.brand === brand.brand));
        if (el) return { ...brand, total: el.total };
        return brand;
      });
      return {
        ...state,
        homeData: {
          ...state.homeData,
          ...data,
          brandList: updatedBrandList,
        }
      };
    case actions.SET_TOKEN:
      let token = action.payload;
      return {
        ...state,
        token,
      };
    case actions.UPDATE_BRAND_LIST: {
      let updatedBrandList = action.payload;
      return {
        ...state,
        homeData: {
          ...state.homeData,
          brandList: updatedBrandList,
        }
      };
    }
    case actions.UPDATE_SELECTED_IMPRESSION: {
      let updatedImpression = action.payload;
      return {
        ...state,
        homeData: {
          ...state.homeData,
          selectedImpression: updatedImpression,
        }
      };
    }
    case actions.UPDATE_SELECTED_DATE: {
      let selectedDate = action.payload;
      console.log('UPDATE_SELECTED_DATE', selectedDate)
      return {
        ...state,
        homeData: {
          ...state.homeData,
          selectedDate,
        }
      };
    }
    case actions.UPDATE_ZIP: {
      let zip = action.payload;
      return {
        ...state,
        homeData: {
          ...state.homeData,
          zipCode: zip,
        }
      };
    }
    case actions.SET_ORDER_ID:
      let { orderId, summaryE2b } = action.payload;
      return {
        ...state,
        orderId,
        summaryE2b,
      };
    case actions.SET_ORDER:
      const order = action.payload;
      return {
        ...state,
        order,
      };
    case actions.UPDATE_CAMPAIGN_URL: {
      const url = action.payload;
      let order = state.order;
      order.filenotes.campaign_url = url;
      return {
        ...state,
        order: {...order},
      };
    }

    case actions.SET_METRICS:
      const metrics = action.payload;
      return {
        ...state,
        metrics,
      };
    case actions.UPDATE_ROW:
      if (!action.payload.location) return state;

      // clone state
      const updatedState = { ...state };
      let statePosition; // reference to state obj used for data manipulation

      // bulk mutate objects (optional)
      if (action.payload.mutateTargets && Array.isArray(action.payload.mutateTargets) && typeof action.payload.mutateFunction === 'function') {
        for (let i = 0; i < action.payload.mutateTargets.length; i++) {
          statePosition = updatedState; // reset state position
          const mutateTargetsLocationArray = action.payload.mutateTargets[i].split('.');
          for (let x = 0; x < mutateTargetsLocationArray.length; x++) {
            statePosition = statePosition[mutateTargetsLocationArray[x]];
          }
          if (typeof statePosition === 'undefined' || !Array.isArray(statePosition)) {
            console.warn('[UPDATE_ROW] could not mutate', action.payload.mutateTargets[i]);
          } else {
            for (let y = 0; y < statePosition.length; y++) {
              statePosition[y] = action.payload.mutateFunction(statePosition[y]);
            }
          }
        }
      }

      // find update data location
      const locationArray = action.payload.location.split('.');
      statePosition = updatedState; // reset state position
      for (let i = 0; i < locationArray.length; i++) {
        statePosition = statePosition[locationArray[i]];
        if (typeof statePosition === 'undefined') {
          console.error('[UPDATE_ROW] could not find location in state', action.payload.location);
          return state;
        }
      }

      // perform actual data update
      if (typeof action.payload.data === 'object') {
        if (Array.isArray(statePosition)) {
          if (!action.payload.data.id) {
            console.error('[UPDATE_ROW] id not found in data', action.payload.data);
            return state;
          }
          const objReplaceIndex = statePosition.findIndex(item => item.id === action.payload.data.id);
          if (objReplaceIndex !== -1) {
            console.log('[UPDATE_ROW] updating row in location');
            statePosition[objReplaceIndex] = {
              ...action.payload.data,
              is_table_accented: { state: true, location: action.payload.location }
            };
          } else {
            console.log('[UPDATE_ROW] pushing row to location');
            statePosition.push({
              ...action.payload.data,
              is_table_accented: { state: true, location: action.payload.location }
            });
          }
        } else if (typeof statePosition === 'object') {
          statePosition = { ...statePosition, ...action.payload.data };
          const stateUpd = {};
          for (let i = locationArray.length - 1; i >= 0; i--) {
            stateUpd[locationArray[i]] = { ...stateUpd };
            if (i !== locationArray.length - 1) {
              delete stateUpd[locationArray[i + 1]];
            }
          }
          let stateUpdPos = { ...stateUpd };
          for (let i = 0; i < locationArray.length; i++) {
            if (locationArray.length - 1 === i) {
              stateUpdPos[locationArray[i]] = { ...statePosition };
            } else {
              stateUpdPos = stateUpdPos[locationArray[i]];
            }
          }
          return { ...state, ...stateUpd };
        } else {
          console.error('[UPDATE_ROW] can not perform any data updates');
          return state;
        }
      } else {
        if (action.payload.unsetAccentId) {
          const unsetIndex = statePosition.findIndex(item => item.id === action.payload.unsetAccentId);
          statePosition[unsetIndex] = { ...statePosition[unsetIndex], is_table_accented: null };
        } else if (action.payload.deleteId && !action.payload.data && Array.isArray(statePosition)) {
          console.log('[UPDATE_ROW] delete item with', action.payload.deleteId);
          const deleteIndex = statePosition.findIndex(item => item.id === action.payload.deleteId);
          statePosition.splice(deleteIndex, 1);
        } else {
          console.log('[UPDATE_ROW] updated primitive state');
          statePosition = action.payload.data;
        }
      }
      return updatedState;
    default:
      return state;
  }
};

export default reducer;
