/* eslint-disable */
import React, {useCallback, useEffect, useState, useRef} from 'react';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import {makeStyles} from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import BackArrow from './../../elements/common/BackArrow'
import FieldGroup from '../../elements/form/FieldGroup';
import DataController from "../../../lib/controllers/DataController";
import PersistentStorage from "../../../lib/controllers/PersistentStorage";
import {CardCvcElement, CardExpiryElement, CardNumberElement, useElements, useStripe,} from '@stripe/react-stripe-js';
import StripeInputWrap from "../../elements/form/StripeInputWrap";
import {ReactComponent as CardIcon} from "../../../images/card.svg";
import {ReactComponent as BankIcon} from "../../../images/bank.svg";
import {ReactComponent as CardWhiteIcon} from "../../../images/cardWhite.svg";
import {ReactComponent as TrashIcon} from "../../../images/trash.svg";
import PlaidBtn from "../../elements/form/PlaidBtn";
import SideBar from "../../elements/Payment/SideBar";
import {useHistory, useParams} from "react-router-dom";
import {formatErrorObj} from "../../../lib/functions";
import {useSnackbar} from "notistack";
import Stripe3dsDialog from '../../elements/Payment/Stripe3dsDialog';

const useStyles = makeStyles(theme => ({
  wrapper: {
    width: '100%',
    maxWidth: 1100,
    padding: '0 40px 50px 40px',

    [theme.breakpoints.down('xs')]: {
      padding: '0 10px 50px 10px',
    },
  },
  logo: {
    position: "absolute",
    top: 10,
    left: 100,
    zIndex: 10
  },
  header: {
    height: 50,
    width: '100%',
    backgroundColor: '#B7FF05',
    position: "relative",
    boxShadow: "0px 1px 3px rgba(0, 0, 0, 0.12), 0px 1px 1px rgba(0, 0, 0, 0.14)",
  },
  options: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',

    '& span': {
      fontSize: 17,
      fontWeight: 400
    },
  },
  subtitle: {
    fontSize: 15,
    fontWeight: 400,
    lineHeight: '25px',
    marginTop: 14,
  },
  container: {
    display: 'flex',
    backgroundColor: theme.palette.common.offWhite,
    border: `1px solid ${theme.palette.neutral[400]}`,
    marginTop: 100,
    boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.12), 0px 1px 1px rgba(0, 0, 0, 0.14)',
    borderRadius: 10,

    [theme.breakpoints.down(769)]: {
      flexDirection: 'column'
    },

    [theme.breakpoints.down('xs')]: {
      marginTop: 40,
    },
  },
  formWrapper: {
    maxWidth: 414
  },
  formTitle: {
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: 50,
    lineHeight: '55px',
    letterSpacing: -0.5,

    [theme.breakpoints.down('xs')]: {
      fontSize: 34,
      lineHeight: '40px',
    },
  },
  textField: {
    marginTop: 30
  },
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  leftCol: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    padding: 20
  },
  rightCol: {
    display: 'flex',
    flexDirection: 'column',
    width: 395,

    [theme.breakpoints.down(769)]: {
      width: '100%'
    },
  },
  zipInputButton: {
    marginTop: 20,
    width: '100%'
  },
  creditCardBtn: {
    padding: '7px 5px',
    backgroundColor: theme.palette.neutral[900],
    border: `1px solid ${theme.palette.neutral[900]}`,
    borderBottomRightRadius: 0,
    borderTopRightRadius: 0,
    '& .MuiButton-label': {
      color: theme.palette.white,
      fontSize: 17,
      lineHeight: '24px',
      fontWeight: 'normal'
    },

    [theme.breakpoints.down('xs')]: {
      '& .MuiButton-label': {
        fontSize: 13,
        lineHeight: '12px',
      },
    },
  },
  bankAccountBtn: {
    padding: '8px 0',
    border: `1px solid ${theme.palette.neutral[900]}`,
    backgroundColor: theme.palette.common.offWhite,
    borderBottomLeftRadius: 0,
    borderTopLeftRadius: 0,
    paddingRight: 20,
    '& .MuiButton-label': {
      color: theme.palette.neutral[900],
      fontSize: 17,
      lineHeight: '24px',
      fontWeight: 'normal',
      marginLeft: 10,
    },

    [theme.breakpoints.down('xs')]: {
      '& .MuiButton-label': {
        fontSize: 13,
        lineHeight: '12px',
      },
    },
  },
  plaidLink: {
    display: 'none'
  },
  summaryWrapper: {
    backgroundColor: '#B7FF05',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: 20
  },
  summary: {
    width: '100%'
  },
  questionsWrapper: {
    backgroundColor: theme.palette.neutral[200],
    height: '100%',
    padding: 20,
  },
  question: {
    borderBottom: `2px solid ${theme.palette.neutral[200]}`,
    padding: 10
  },
  title: {
    fontWeight: 800,
    fontSize: 17,
    lineHeight: '24px',
    color: theme.palette.neutral[900],
  },
  text: {
    fontWeight: 800,
    fontSize: 15,
    lineHeight: '20px',
    color: theme.palette.neutral[700],
    marginTop: 10
  },
  bankName: {
    fontWeight: 800,
    fontSize: 17,
    marginTop: 25,
    lineHeight: '24px',
    color: theme.palette.neutral[900],
  },
  bankAccount: {
    fontSize: 15,
    marginTop: 10,
    lineHeight: '20px',
    color: theme.palette.neutral[700],
    marginBottom: 10
  },
  editAccount: {
    '& span': {
      fontSize: 15,
      color: theme.palette.primary.main,
    }
  }
}));

export default function PaymentPage() {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [companyName, setCompanyName] = useState('');
  const [companyStreet, setCompanyStreet] = useState('');
  const [companyCity, setCompanyCity] = useState('');
  const [companyState, setCompanyState] = useState('');
  const [companyZip, setCompanyZip] = useState('');
  const [companyPhone, setCompanyPhone] = useState('');
  const [paymentType, setPaymentType] = useState('stripe');
  const [plaidData, setPlaidData] = useState({
    publicToken: '',
    accountId: '',
    institutionName: '',
    mask: ''
  });
  const [cardNumberComplete, setCardNumberComplete] = useState(false);
  const [cardExpiryComplete, setCardExpiryComplete] = useState(false);
  const [cardCvcComplete, setCardCvcComplete] = useState(false);

  const [token, setToken] = useState(null);
  const history = useHistory();
  const stripe = useStripe();
  const elements = useElements();
  const classes = useStyles();
  let {cartId} = useParams();
  const {enqueueSnackbar} = useSnackbar();
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});

  const [iframeUrl, setIframeUrl] = useState('');
  const [iframeDialogOpen, setIframeDialogOpen] = useState(false);
  const paymentIntentId = useRef(null);


  const paymentIsReady = paymentType === 'stripe'
    ? cardNumberComplete && cardExpiryComplete && cardCvcComplete
    : plaidData.publicToken && plaidData.accountId

  useEffect(() => {
    window.scrollTo({top: 0, behavior: "smooth"});
    
    return () => {
      // cleanup 3ds success handler
      window.removeEventListener('message', on3dsComplete);
    }
  }, []);

  function handleChange({complete, elementType}) {
    if (elementType === 'cardNumber') {
      setCardNumberComplete(complete);
      return;
    }
    if (elementType === 'cardExpiry') {
      setCardExpiryComplete(complete);
      return;
    }
    if (elementType === 'cardCvc') {
      setCardCvcComplete(complete);
    }

  }

  const onSuccess = useCallback(
    (public_token, metadata) => {
      setPlaidData({
        publicToken: metadata.public_token,
        accountId: metadata.account_id,
        mask: metadata.account.mask,
        institutionName: metadata.institution.name,
      });
      setPaymentType('ach_debit');
    },
    []
  );

  async function handleBankAccountClick() {
    try {
      const {data, errors, message, success} = await DataController.getPlaidLinkToken({
        firstName,
        lastName,
        email,
        phone
      }, cartId);
      if (success) {
        if (data?.link_token) {
          setToken(data.link_token);
        }
        return;
      }
      if (message) {
        enqueueSnackbar(message, {variant: 'error'});
      }
    } catch (error) {
      console.log(error)
    }
  }

  const handlePlaceOrder = async () => {

    let stripeToken = {};

    if (paymentType === 'stripe') {
      if (!stripe || !elements) {
        return;
      }
      const cardNumberElement = elements.getElement(CardNumberElement);
      const {error, token} = await stripe.createToken(cardNumberElement);
      if (error) {
        return;
      }
      stripeToken = token;
    }

    const opt = {
      firstname: firstName,
      lastname: lastName,
      email,
      phone,
      payment_token: paymentType === 'stripe' ? stripeToken.id : plaidData.publicToken,
      payment_type: paymentType,
      company_name: companyName,
      company_street: companyStreet,
      company_city: companyCity,
      company_state: companyState,
      company_zip: companyZip,
      company_phone: companyPhone,
      account_id: plaidData.accountId
    }

    if (paymentIntentId.current) {
      opt.paymentintent = paymentIntentId.current;
    }

    setErrors({});
    setLoading(true);
    try {
      const res = await DataController.placeOrder(opt, cartId);
      const {data, errors, message, success} = res;

      if (success) {
        PersistentStorage.delete('cartId');
        history.push(`/success/${cartId}`);
        return;
      }
      if (res['3ds']){
        paymentIntentId.current = res['3ds'].paymentintent_id;
        setIframeUrl(res['3ds'].next_action.redirect_to_url.url);
        window.addEventListener('message', on3dsComplete);
        setIframeDialogOpen(true);
        return;
      }
      if (Object.keys(errors).length > 0) {
        const errorObject = formatErrorObj(errors);
        setErrors(errorObject);
      } else if (message) {
        enqueueSnackbar(message, {variant: 'error'})
      }
    } catch (error) {
      console.log(error)
    } finally {
      setLoading(false);
    }
  }

  function on3dsComplete(e) {
    if (e.data === '3DS-authentication-complete') {
      handlePlaceOrder();
    }
  }

  function handleDeleteBankInfo() {
    setPlaidData({
      publicToken: '',
      accountId: '',
      institutionName: '',
      mask: ''
    });
    setPaymentType('stripe')
  }

  const isPlaceOrderBtnEnabled = firstName && lastName && email && phone && companyName && companyStreet && companyCity && companyState && companyZip && companyPhone;
  const isAddBankAccountEnabled = firstName && lastName && email && phone;

  return (
    <div className={classes.wrapper}>
      {token && <PlaidBtn token={token} onSuccess={onSuccess}/>}
      <Container className={classes.container}>
        <Box className={classes.leftCol}>
          <BackArrow path="/"/>
          <Box>
            <Box mt={4} className={classes.formWrapper}>
              <form
                autoComplete="off"
                noValidate
              >
                <Box>
                  <div className={classes.formTitle}>
                    Your information
                  </div>
                  <TextField
                    className={classes.textField}
                    label='First name'
                    error={!!errors.firstname}
                    helperText={errors.firstname}
                    required
                    onChange={(e) => setFirstName(e.target.value)}
                    value={firstName}
                  />
                  <TextField
                    className={classes.textField}
                    label='Last name'
                    error={!!errors.lastname}
                    helperText={errors.lastname}
                    required
                    onChange={(e) => setLastName(e.target.value)}
                    value={lastName}
                  />
                  <TextField
                    className={classes.textField}
                    label='Email address'
                    error={!!errors.email}
                    helperText={errors.email}
                    required
                    onChange={(e) => setEmail(e.target.value)}
                    value={email}
                  />
                  <TextField
                    className={classes.textField}
                    label='Phone'
                    error={!!errors.phone}
                    helperText={errors.phone}
                    required
                    onChange={(e) => setPhone(e.target.value)}
                    value={phone}
                  />
                </Box>
                <Box mt={5}>
                  <div className={classes.formTitle}>
                    Payment method
                  </div>
                  <Box mt={4}>
                    <Button
                      variant='outlined'
                      className={classes.creditCardBtn}
                    >
                      <CardWhiteIcon/> Add a Credit card
                    </Button>
                    <Button
                      variant='contained'
                      className={classes.bankAccountBtn}
                      onClick={handleBankAccountClick}
                    >
                      <BankIcon/> Add a Bank Account
                    </Button>
                  </Box>
                  {
                    paymentType === 'stripe' ? (
                      <>
                        <FieldGroup>
                          <StripeInputWrap
                            label="Card number"
                            Component={CardNumberElement}
                            endAdornment={<CardIcon/>}
                            onChangeCallback={handleChange}
                          />
                        </FieldGroup>
                        <FieldGroup half>
                          <StripeInputWrap
                            label="Expiry date"
                            Component={CardExpiryElement}
                            onChangeCallback={handleChange}
                          />
                          <StripeInputWrap
                            label="CVC"
                            Component={CardCvcElement}
                            onChangeCallback={handleChange}
                          />
                        </FieldGroup>
                      </>
                    ) : (
                      <>
                        <div className={classes.bankName}>{plaidData.institutionName}</div>
                        <div className={classes.bankAccount}>Account: ****{plaidData.mask}</div>
                        <Button
                          variant="text"
                          className={classes.editAccount}
                          onClick={handleDeleteBankInfo}
                        >
                          <TrashIcon/> Delete bank information
                        </Button>
                      </>
                    )
                  }
                </Box>
                <Box mt={5}>
                  <div className={classes.formTitle}>
                    Company address
                  </div>
                  <TextField
                    className={classes.textField}
                    label='Company name'
                    error={!!errors.company_name}
                    helperText={errors.company_name}
                    value={companyName}
                    required
                    onChange={(e) => setCompanyName(e.target.value)}
                  />
                  <TextField
                    className={classes.textField}
                    label='Address line 1'
                    error={!!errors.company_street}
                    helperText={errors.company_street}
                    value={companyStreet}
                    required
                    onChange={(e) => setCompanyStreet(e.target.value)}
                  />
                  <FieldGroup>
                    <TextField
                      label="City"
                      error={!!errors.company_city}
                      helperText={errors.company_city}
                      value={companyCity}
                      required
                      onChange={(e) => setCompanyCity(e.target.value)}
                    />
                    <TextField
                      label="State/Province"
                      value={companyState}
                      error={!!errors.company_state}
                      helperText={errors.company_state}
                      required
                      onChange={(e) => setCompanyState(e.target.value)}
                    />
                    <TextField
                      label="Zipcode"
                      value={companyZip}
                      error={!!errors.company_zip}
                      helperText={errors.company_zip}
                      required
                      onChange={(e) => setCompanyZip(e.target.value)}
                    />
                  </FieldGroup>
                  <TextField
                    className={classes.textField}
                    label='Phone'
                    error={!!errors.company_phone}
                    helperText={errors.company_phone}
                    value={companyPhone}
                    required
                    onChange={(e) => setCompanyPhone(e.target.value)}
                  />
                </Box>
              </form>
            </Box>
          </Box>
        </Box>
        <Box className={classes.rightCol}>
          <SideBar
            isPlaceOrderBtnEnabled={isPlaceOrderBtnEnabled && paymentIsReady}
            handlePlaceOrder={handlePlaceOrder}
            loading={loading}
          />
        </Box>
      </Container>
      <Stripe3dsDialog 
        isOpen={iframeDialogOpen}
        onClose={() => {
          setIframeDialogOpen(false);
        }}
        iframeUrl={iframeUrl}
      />
    </div>
  );
}

