// Dev/QA - default values
let _orderApiUrl = "https://api-qa.addy.co/api/v1";
let _apiUrl = "https://api-qa.addy.co/api/e2b/v1";
let _stripeKey = 'pk_test_eCu3kiEHoZMvKsXMatlf79Rg';

// Prod
if (window.location.hostname === 'e2b.groundtruth.com') {

  _orderApiUrl = "https://api.addy.co/api/v1";
  _apiUrl = "https://api.addy.co/api/e2b/v1";
  _stripeKey = 'pk_live_QjIkizUdDXHRQs9qRFNX3FS5';

}

export const HOTJAR_ID = 2782100;
export const HOTJAR_SNIPPET_VERSION = 6;

export const orderApiUrl = _orderApiUrl;
export const apiUrl = _apiUrl;
export const stripeKey = _stripeKey;
