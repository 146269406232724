import React, { useState, useEffect } from 'react';
import useMemoCompare from './useMemoCompare.js';
import { compareMixed } from '../functions.js';
import LoadingButton from '../../components/elements/common/LoadingButton.js';

const useFormControl = ({ defaultForm, defaultFieldProps, loadValues, SubmitButtonProps = {}, onChangeGlobal }) => {

  const [errors, setErrors] = useState({});
  const [warnings, setWarnings] = useState({});
  const [isWorking, setIsWorking] = useState(false);
  const [formData, setFormData] = useState(defaultForm);

  const loadValuesCached = useMemoCompare(loadValues, (prevLoadValues) => {
    return prevLoadValues && loadValues && compareMixed(loadValues, prevLoadValues);
  });

  useEffect(() => {
    if (loadValuesCached) {
      let loadFormData = {};
      if (defaultForm) {
        for (let key in defaultForm) {
          if (defaultForm.hasOwnProperty(key)) {
            loadFormData[key] = loadValuesCached[key] || '';
          }
        }
      } else {
        loadFormData = { ...loadValuesCached };
      }
      setFormData(loadFormData);
    }

    return () => {
      //setErrors({});
    };
  }, [loadValuesCached, defaultForm]);

  /**
   * Set field error
   * @param key
   * @param error
   * @param severity
   */
  const setFieldError = (key, error, severity = 'error') => {
    const setterFunc = severity === 'error' ? setErrors : setWarnings;
    setterFunc(prev => {
      const newErrors = { ...prev };
      if (!error && newErrors[key]) delete (newErrors[key]);
      else newErrors[key] = error;
      return newErrors;
    });
  };

  /**
   * Set input change state
   * @param e
   * @param key
   * @param onChange
   * @param transformFunc
   * @param validatorFunc
   */
  const handleInputChange = (e, key, onChange, transformFunc, validatorFunc) => {
    setFormData({
      ...formData,
      [key]: typeof transformFunc === 'function' ? transformFunc(e.target.value) : e.target.value
    });
    if (typeof onChangeGlobal === 'function') onChangeGlobal(e, key);
    if (typeof onChange === 'function') onChange(e, key);
    if (typeof validatorFunc === 'function') {
      const errorText = validatorFunc(e.target.value, key);
      setFieldError(key, errorText === true ? undefined : errorText);
    } else if (Array.isArray(validatorFunc) && validatorFunc.length > 0) {
      let errorText;
      for (let i = 0; i < validatorFunc.length; i++) {
        errorText = validatorFunc[i](e.target.value, key);
        if (errorText !== true) {
          break;
        }
      }
      setFieldError(key, errorText === true ? undefined : errorText);
    }
  };

  /**
   * Return field default props
   * @param fieldName
   * @param onChange
   * @param transformFunc
   * @param validatorFunc
   * @returns {{onChange: ((function(*): void)|*), error: boolean, helperText, value}}
   */
  const getFieldProps = (fieldName, onChange, transformFunc, validatorFunc) => {
    let defaultProps = !!defaultFieldProps ? defaultFieldProps : {}
    return {
      ...defaultProps[fieldName],
      error: !!errors[fieldName],
      helperText: errors[fieldName],
      onChange: e => handleInputChange(e, fieldName, onChange, transformFunc, validatorFunc),
      value: formData[fieldName]
    };
  };

  const allRequiredFieldsFilled = () => {
    if (!defaultFieldProps) return true

    let ret = true;

    for (let key in defaultFieldProps) {
      if (defaultFieldProps[key].required) {
        ret = ret && !!getFieldProps(key).value
      }
    }

    return ret
  }

  const changeIsMade = () => {
    let ret = false;

    for (let key in defaultForm) {
      if (!loadValues || !loadValues[key]) {
        ret = ret || getFieldProps(key).value !== ''
      } else {
        ret = ret || loadValues[key] !== getFieldProps(key).value
      }
    }

    return ret
  }

  // get default props and merge with SubmitButtonProps
  const mergeDisabled = {
    disabled: !changeIsMade() || !allRequiredFieldsFilled() || isWorking || (typeof SubmitButtonProps !== 'undefined' ? SubmitButtonProps.disabled : false)
  };

  const submitButtonProps = Object.assign({
      variant: 'contained',
      type: 'submit',
      children: 'Save',
      loading: isWorking
    },
    SubmitButtonProps,
    mergeDisabled
  );

  /**
   * Reset form - call this function to clear the values (ie. after submit)
   */
  const resetForm = () => {
    for (let key in formData) {
      if (formData.hasOwnProperty(key)) formData[key] = '';
    }
  };

  return {
    handleInputChange,
    getFieldProps,
    setFieldError,
    setErrors,
    setWarnings,
    errors,
    warnings,
    formData,
    resetForm,
    setIsWorking,
    isWorking,
    renderSubmitButton: <LoadingButton {...submitButtonProps}>{submitButtonProps.label ? submitButtonProps.label : 'Submit'}</LoadingButton>
  };
};

export default useFormControl;
