import React, {useState} from 'react'
import CustomDialog from '../common/CustomDialog'
import { Box,CircularProgress, makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  iframeLoader: {
    position: 'absolute',
    width: 42,
    height: 42,
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)'
  }
})

export default function Stripe3dsDialog({iframeUrl, isOpen, onClose}) {
  const classes = useStyles();
  const [iframeLoading, setIframeLoading] = useState(true);

  return (
    <CustomDialog
      isOpen={isOpen}
      onClose={onClose}
      positionedAbove
      hasXClose
      title="Please complete authentication"
      maxWidth={'lg'}
      actions={[]}
      dialogContentClass={classes.dialogContent}
    > 
      {iframeLoading &&
        <Box className={classes.iframeLoader}>
            <CircularProgress color="secondary" />
        </Box>
      }
      <iframe src={iframeUrl} width="100%" height="400" frameBorder="0" title="3DS Card confirm dialog" onLoad={() => {
        setIframeLoading(false)
      }}></iframe>
    </CustomDialog>
  )
}
