import React from 'react';
import { makeStyles } from "@material-ui/core/styles";
import pin from "../../../images/pin.png";

const useStyles = makeStyles(theme => ({
  container: {
    borderRadius: 10,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center'
  },
  imageContainer: {
    width: 30,
  },
  infoContainer: {
    flex: 1
  }
}));

function CustomTooltip({ point }) {

  const classes = useStyles();

  return (
    <div className={classes.container}>
      <div className={classes.imageContainer}>
        <img src={pin} alt="pin" />
      </div>
      <div className={classes.infoContainer}>
        <div>{point.title}</div>
        <div>{point.address}</div>
      </div>
    </div>
  )
}

export default CustomTooltip;
