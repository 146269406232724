import brandList from '../data/brandList';
import { defaultMetrics } from '../data/defaultMetrics';

const initialState = {
  uuid: '',
  orderId: '',
  token: '',
  homeData: {
    pois: [], // From api
    location: {}, // From api
    impressions: [], // From api
    brands: [], // From api
    availability: '', // From api

    brandList: brandList,
    selectedImpression: 40000,
    selectedDate: '',
    zipCode: '',
    city: ''
  },
  metrics: {
    ...defaultMetrics,
  },
  order: {

  }
};

export default initialState;
