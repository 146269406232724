import React from 'react';
import { Box, Typography, Button } from '@material-ui/core';
import { useHistory } from "react-router-dom";
import { ReactComponent as ArrowLeftIcon } from '../../../images/arrow-left.svg';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  backWrapper: {
    marginBottom: 28,
    display: 'flex',
    justifyContent: 'space-between',
    borderBottom: `1px solid ${theme.palette.neutral[300]}`
  },
  btn: {
    borderBottom: `1px solid ${theme.palette.neutral[900]}`,
    borderRadius: 0,
    color: theme.palette.neutral[900],
    padding: 15,

    '& svg': {
      transition: 'all .3s ease-out'
    },

    '& svg path': {
      transition: 'all .3s ease-out'
    },

    '&:hover': {
      textDecoration: 'none',

      '& svg': {
        transform: 'rotateX(180deg)'
      },

      '& svg path': {
        stroke: theme.palette.primary.main
      }
    }
  },
  note: {
    borderRadius: 0,
    color: theme.palette.neutral[900],
    padding: 15,

    '& svg': {
      transition: 'all .3s ease-out'
    },

    '& svg path': {
      transition: 'all .3s ease-out'
    },

    '&:hover': {
      textDecoration: 'none',

      '& svg': {
        transform: 'rotateX(180deg)'
      },

      '& svg path': {
        stroke: theme.palette.primary.main
      }
    }
  },
  label: {
    fontSize: 15,
    lineHeight: "20px"
  }
}));

function BackArrow({ path, label = "Edit order" }) {
  const history = useHistory();
  const classes = useStyles();

  const handleBack = e => {
    e.preventDefault();
    if (path) {
      history.push(path);
    } else if (history.length && history.length > 1) {
      history.goBack()
    }
  }

  return (
    <Box display="flex" className={classes.backWrapper}>
      <Button
        onClick={handleBack}
        startIcon={<ArrowLeftIcon/>}
        variant="text"
        className={classes.btn}
      >
        <Typography variant="body2" className={classes.label}>
          {label}
        </Typography>
      </Button>
    </Box>
  );
}

export default BackArrow;
