/**
 * Compare mixed objects
 *
 * @param obj1
 * @param obj2
 * @returns {boolean}
 */
export const compareMixed = (obj1, obj2) => {

  const getType = obj => Object.prototype.toString.call(obj).slice(8, -1).toLowerCase();

  const areArraysEqual = () => {
    if (obj1.length !== obj2.length) return false;
    for (let i = 0; i < obj1.length; i++) {
      if (!compareMixed(obj1[i], obj2[i])) return false;
    }
    return true;
  };

  const areObjectsEqual = () => {
    if (Object.keys(obj1).length !== Object.keys(obj2).length) return false;
    for (let key in obj1) {
      if (Object.prototype.hasOwnProperty.call(obj1, key)) {
        if (!compareMixed(obj1[key], obj2[key])) return false;
      }
    }
    return true;
  };

  const areFunctionsEqual = () => obj1.toString() === obj2.toString();

  const arePrimitivesEqual = () => obj1 === obj2;

  const type = getType(obj1);
  if (type !== getType(obj2)) return false;
  if (type === 'array') return areArraysEqual();
  if (type === 'object') return areObjectsEqual();
  if (type === 'function') return areFunctionsEqual();
  return arePrimitivesEqual();
};

export const getLocationData = () => {
  return new Promise((resolve, reject) => {
    //let callbacks = [];
    const geoip2 = window.geoip2;
    /*geoip2.waitResponse = function(cb) {
      if ('response' in geoip2) {
        cb(geoip2.response)
      } else {
        callbacks.push(cb)
      }
    }*/
    geoip2.city(function(response){
      geoip2.response = response;
      resolve(response)
      //callbacks.forEach(function(cb) {cb(response)})
    }, function(error){
      reject(error)
      geoip2.response = null;
      //callbacks.forEach(function(cb) {cb(null)})
    });
  })
}

/**
 * Download file
 *
 * @param name
 * @param url
 */
export const downloadFile = (name, url) => {
  const link = document.createElement('a');
  link.setAttribute('download', name);
  link.href = url;
  document.body.appendChild(link);
  link.click();
  link.remove();
};

/**
 * Get image dimensions
 *
 * @param url
 * @returns {Promise<unknown>}
 */
export const getImageDimensions = async url => {
  return new Promise(resolve => {
    const img = new Image();

    img.onload = () => {
      resolve({
        width: img.width,
        height: img.height
      });
    };

    img.onerror = () => {
      resolve(false);
    };

    img.src = url;
  });
};

/**
 * Check gif file for max_seconds length
 *
 * @param file
 * @param max_seconds
 * @returns {Promise<unknown>}
 */
export const gifAnimationCheck = (file, max_seconds) => {
  return new Promise(resolve => {
    const reader = new FileReader();
    reader.onload = function (event) {
      const bytes = new Uint8Array(reader.result);
      const buf = [];
      for (let i = 0; i < bytes.length; i++) {
        let b = bytes[i].toString(16);
        if (b.length === 1) {
          // Zero pad
          b = '0' + b;
        }
        buf.push(b);
      }
      const data = buf.join('');

      const iterations = (function () {
        // See http://www.w3.org/Graphics/GIF/spec-gif89a.txt and http://www.let.rug.nl/~kleiweg/gif/netscape.html for more info
        const re = new RegExp('21ff0b4e45545343415045322e300301([0-9a-f]{4})00');
        const matches = re.exec(data);

        let iter = 1;
        if (matches) {
          // Convert little-endian hex unsigned int to decimal
          iter = parseInt(matches[1].substring(2, 4) + matches[1].substring(0, 2), 16);

          // The presence of the header with a nonzero number of iterations
          // should be interpreted as "additional" iterations,
          // hence a specifed iteration of 1 means loop twice.
          // Zero iterations means loop continuously
          if (iter > 0) iter++;
        }

        // A return value of zero means gif will loop continuously
        return iter;
      })();

      // If set to loop continuously, return false
      if (iterations === 0) {
        console.log('continuous loop, return false');
        resolve(false);
        return;
      }

      let delay = (function () {
        let total_delay = 0;
        // See http://www.w3.org/Graphics/GIF/spec-gif89a.txt for more info
        const re = new RegExp('21f904[0-9a-f]{2}([0-9a-f]{4})[0-9a-f]{2}00', 'g');
        let matches = null;
        do {
          matches = re.exec(data);
          if (matches) {
            // Convert little-endian hex unsigned ints to decimals
            const d = parseInt(matches[1].substring(2, 4) + matches[1].substring(0, 2), 16);
            total_delay += d;
          }
        } while (matches);

        // Delays are stored as hundredths of a second, lets convert to seconds
        return total_delay / 100;
      })();

      delay = delay * iterations;

      // console.log('delay: ' + delay);
      if (delay > max_seconds) {
        // console.log('animation > ' + max_seconds + ', return false');
        resolve(false);
      } else {
        // console.log('animation <= ' + max_seconds + ', return true');
        resolve(true);
      }
    };

    reader.readAsArrayBuffer(file);
  });
};

/**
 * Get human readable size
 *
 * @param bytes
 * @param si
 * @param dp
 * @returns {string}
 */
export const humanFileSize = (bytes, si = false, dp = 0) => {
  const thresh = si ? 1000 : 1024;

  if (Math.abs(bytes) < thresh) {
    return bytes + ' B';
  }

  const units = si
    ? ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
    : ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];
  let u = -1;
  const r = 10 ** dp;

  do {
    bytes /= thresh;
    ++u;
  } while (Math.round(Math.abs(bytes) * r) / r >= thresh && u < units.length - 1);


  return bytes.toFixed(dp) + ' ' + units[u];
};

/**
 * Animate scroll to
 *
 * @param to
 * @param duration
 * @param container
 * @param offset
 */
export const scrollTo = (to, duration, container, offset) => {
  const element = container || document.scrollingElement || document.body;
  if (to && to.nodeName) {
    to = to.getBoundingClientRect().top;
  }
  if (offset && !isNaN(offset)) to = to - offset;
  const start = element.scrollTop;
  const change = to - start;
  const startTs = performance.now();
  const easeInOutQuad = function (t, b, c, d) {
    t /= d / 2;
    if (t < 1) return c / 2 * t * t + b;
    t--;
    return -c / 2 * (t * (t - 2) - 1) + b;
  };
  const animateScroll = function (ts) {
    const currentTime = ts - startTs;
    element.scrollTop = parseInt(easeInOutQuad(currentTime, start, change, duration));
    if (currentTime < duration) {
      requestAnimationFrame(animateScroll);
    } else {
      element.scrollTop = to;
    }
  };
  requestAnimationFrame(animateScroll);
};

export const formatErrorObj = (errors) => Object.keys(errors).reduce((acc, key) => ({
  ...acc,
  [key]: Array.isArray(errors[key]) ? errors[key].join('<br />') : errors[key]
}), {});
