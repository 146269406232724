import React from 'react';
import Box from '@material-ui/core/Box';
import {makeStyles} from '@material-ui/core/styles';
import CloseDialogIcon from "../../../../images/CloseDialogIcon.png";
import GTaboutIcon from "../../../../images/GTaboutIcon.svg";
import IconButton from "@material-ui/core/IconButton";

const useStyles = makeStyles(theme => ({
  container: {
    maxWidth: '700px',
    backgroundColor: '#C7FC51'
  },
  button: {
    width: '100%',
  },
  footer: {
    backgroundColor: theme.palette.white,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-end',
    padding: 10,
  },
  header: {
    display: 'flex',
    justifyContent: "space-between"
  },
  success: {
    padding: 32,
    [theme.breakpoints.down('xs')]: {
      padding: 20,
    },
  },
  successTitle: {
    fontSize: 34,
    lineHeight: '100%',
    letterSpacing: '-0.5px',
    color: theme.palette.neutral[900],

    [theme.breakpoints.down('sm')]: {
      fontSize: 21,
      lineHeight: '25px',
      maxWidth: 300
    },
  },
  successText: {
    fontSize: 20,
    lineHeight: '28px',
    marginTop: 20,

    [theme.breakpoints.down('sm')]: {
      fontSize: 15,
      lineHeight: '22px',
    },

    '& span': {
      color: theme.palette.primary.main,
      cursor: 'pointer'
    }
  },
  successImage: {
    display: 'flex',
    justifyContent: 'center',
  },
  img: {
    width: '100%',
    maxHeight: 331,
    margin: '30px 0 30px 0',

    [theme.breakpoints.down(769)]: {
      maxHeight: 315,
    },
  },
  aboutImg: {
    [theme.breakpoints.down('sm')]: {
      width: 150
    },
  }
}));

export default function GTAbout({onClose}) {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <Box>
        <div>
          <Box className={classes.success}>
            <Box className={classes.header}>
              <Box>
                <Box className={classes.successTitle}>About GroundTruth</Box>
              </Box>
              <Box>
                <IconButton
                  className={classes.xCloseButton}
                  onClick={onClose}
                  size="small"
                  aria-label="close"
                >
                  <img src={CloseDialogIcon} alt=''/>
                </IconButton>
              </Box>
            </Box>
            <Box display='flex' justifyContent='center' alignItems='center' mt={2}>
              <img className={classes.aboutImg} src={GTaboutIcon} alt=''/>
            </Box>
            <Box className={classes.successText}>
              <a target="_blank" rel="noreferrer" href='https://www.groundtruth.com/'>GroundTruth</a> is the leading location-based marketing and advertising technology company. The largest brands
              in the world trust GroundTruth to deliver timely, effective ads to the right consumers as indicated by the
              places they visit in the real world - not just on the Internet. Now, this powerful marketing tool is
              available to local businesses like yours.
            </Box>
          </Box>
        </div>
      </Box>
    </div>
  );
}
