import React from 'react';
import Box from '@material-ui/core/Box';
import {makeStyles} from '@material-ui/core/styles';
import Map from './Map';
import Button from '@material-ui/core/Button';
import {ReactComponent as RightArrow} from '../../../images/rightArrow.svg';
import DealershipList from '../common/DealershipList';
import useAppState from '../../../lib/hooks/useAppState';
import arrowUp from "../../../images/arrowUpWhite.png";
import EmptyState from "./EmptyState";

const useStyles = makeStyles(theme => ({
  mapSection: {
    backgroundColor: '#D84B4C',
    minHeight: 1024,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  contentContainer: {
    maxWidth: 1030,
    width: '100%',
    marginTop: 150,

    [theme.breakpoints.down('xl')]: {
      width: '80%',
    },

    [theme.breakpoints.down('xs')]: {
      width: '90%',
      marginTop: 50,
    },
  },
  sectionHeader: {
    fontSize: 34,
    lineHeight: '34px',
    paddingBottom: 20,
    letterSpacing: -0.45,
    color: '#FFCA78',
    borderBottom: '4px solid rgba(214,242,239, .3)',
  },
  description: {
    color: '#FFBCBD',
    marginTop: 20,
    fontSize: 20,
    lineHeight: '25px'
  },
  content: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'row',    [theme.breakpoints.down(960)]: {
      flexDirection: 'column-reverse',
    },
    alignItems: 'center',
    padding: 5,

    [theme.breakpoints.down(960)]: {
      flexDirection: 'column-reverse',
    },
  },
  mapWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginLeft: -100,
    width: '100%',

    [theme.breakpoints.down('md')]: {
      marginLeft: -60,
    },

    [theme.breakpoints.down(960)]: {
      marginLeft: 0,
    },
  },
  brandsListWrapper: {
    color: theme.palette.common.offWhite,
    padding: '30px 0px'
  },
  emptyStateText: {
    fontSize: 21,
    lineHeight: '25px',
    color: theme.palette.common.offWhite,
  },
  footerWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    marginTop: 10,
    color: theme.palette.common.offWhite,
    width: '100%',

    [theme.breakpoints.down(960)]: {
      margin: '40px 0'
    },

    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
      marginTop: 30
    },
  },
  zipInputButton: {
    marginLeft: 20,
    zIndex: 10000,

    [theme.breakpoints.down('xs')]: {
      width: '100%',
      marginLeft: 0,
      marginTop: 30,
      marginBottom: 30
    },
  },
  note: {
    fontSize: 20,
    fontWeight: 800,
    color: theme.palette.common.offWhite,
    zIndex: 10000,
  }
}));

export default function MapPage({changePage}) {
  const {state} = useAppState();
  const {pois, location, zipCode, brandList} = state.homeData;
  const classes = useStyles();

  function handleConfirm() {
    changePage(3);
  }

  const selectedDealersCount = brandList.reduce(function (accumulator, currentValue) {
    return currentValue.checked ? accumulator + currentValue.total : accumulator;
  }, 0);

  const selectedBrands = brandList.filter(el => el.checked).map(el => el.brand);

  return (
    <Box className={classes.mapSection} id="page2">
      <Box className={classes.contentContainer}>
        <Box className={classes.sectionHeader}>
          What types of dealerships are your customers visiting?
        </Box>
        <div className={classes.description}>
          Select as many brands as you like and we’ll deliver your ads to the visitors of those dealerships.
        </div>
        <Box className={classes.content}>
          <Box className={classes.mapWrapper}>
            <Map points={pois} location={location} selectedBrands={selectedBrands}/>
          </Box>
          <Box className={classes.brandsListWrapper}>
            {zipCode ? (
              <DealershipList/>
            ) : (
              <EmptyState img={arrowUp}>
                <span className={classes.emptyStateText}>Select a location above to see available dealerships in your area.</span>
              </EmptyState>
            )}
          </Box>
        </Box>
        <div className={classes.footerWrapper}>
          {!!selectedDealersCount && (
            <div className={classes.note}>
              {`OK, visitors to these ${selectedDealersCount} dealerships will see your ads!`}
            </div>
          )}
          <Button
            variant='outlined'
            className={classes.zipInputButton}
            disabled={!selectedDealersCount}
            onClick={handleConfirm}
          >
            Confirm Dealerships&nbsp;&nbsp;
            {!!selectedDealersCount && <RightArrow/>}
          </Button>
        </div>
      </Box>
    </Box>
  );
}
