import React from 'react';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import { ReactComponent as CloseDialogIcon } from "../../../../images/closeDialog.svg";
import IconButton from "@material-ui/core/IconButton";
import e2bSize1 from "../../../../images/E2BAuto-728x90-px.jpg";
import e2bSize2 from "../../../../images/E2BAuto-300x600-px.jpg";
import e2bSize3 from "../../../../images/E2BAuto-160x600-px.jpg";
import e2bSize4 from "../../../../images/E2BAuto-300x250-px.jpg";
import e2bSize5 from "../../../../images/E2BAuto-320x50-px.jpg";
import e2bSize6 from "../../../../images/E2BAuto-320x480-px.jpg";

const useStyles = makeStyles(theme => ({
  button: {
    width: '100%',
  },
  footer: {
    backgroundColor: theme.palette.white,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-end',
    padding: 10,
  },
  xCloseButton: {
    position: 'absolute',
    right: 25,
    top: 25
  },
  formContainer: {
    '& h2': {
      fontSize: 21
    },

    '& h3': {
      fontSize: 21
    },

    '& h6': {
      fontSize: 15
    },

    [theme.breakpoints.up('sm')]: {
      '& h2': {
        fontSize: 34
      },

      '& h3': {
        fontSize: 34
      },

      '& h6': {
        fontSize: 20
      }
    },

    [theme.breakpoints.up('lg')]: {
      '& h2': {
        fontSize: 55
      }
    }
  },
  success: {
    padding: 32,

    [theme.breakpoints.down('xs')]: {
      padding: 10,
    },
  },
  successTitle: {
    fontSize: 34,
    lineHeight: '100%',
    letterSpacing: '-0.5px',
    color: theme.palette.neutral[900],

    [theme.breakpoints.down(769)]: {
      fontSize: 34,
      lineHeight: '34px',
      maxWidth: 300
    },
  },
  successText: {
    fontSize: 20,
    lineHeight: '25px',
    color: theme.palette.neutral[700] ,
    display: 'flex',
    alignItems: 'center',
    marginTop: 20
  },
  successImage: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    marginTop: 30,
    width: '100%'
  },
  img: {
    width: '100%',
  },
  img1: {
    width: '65%',
  },
}));

export default function DigitalAds({ onClose }) {
  const classes = useStyles();

  return (
    <div style={{maxWidth: '700px'}}>
      <Box className={classes.formContainer}>
        <IconButton
          className={classes.xCloseButton}
          onClick={onClose}
          size="small"
          aria-label="close"
          color="secondary"
        >
          <CloseDialogIcon/>
        </IconButton>
        <div>
          <Box className={classes.success}>
            <Box className={classes.successTitle}>Variety for better results!</Box>
            <Box className={classes.successText}>
              Your digital ads will appear in several of the most popular sizes - and can even accommodate a variety of ad messages.
            </Box>
            <Box className={classes.successImage}>

              <Box>
                <img  src={e2bSize1} className={classes.img} alt="adImg1" />
              </Box>

              <Box display="flex" mt={2}>
                <Box display="flex" flexDirection="column">
                  <Box display="flex">
                    <Box>
                      <img src={e2bSize2} className={classes.img} alt="adImg2" />
                    </Box>
                    <Box ml={2}>
                      <img src={e2bSize3} className={classes.img} alt="adImg3" />
                    </Box>
                  </Box>
                  <Box mt={2}>
                    <img src={e2bSize5} className={classes.img1} alt="adImg5" />
                  </Box>
                </Box>
                <Box ml={2}>
                  <img src={e2bSize6} className={classes.img} alt="adImg6" />
                  <Box>
                    <img src={e2bSize4} className={classes.img} alt="adImg4" />
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </div>
      </Box>
    </div>
  );
}
