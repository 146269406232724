import { Box, makeStyles } from "@material-ui/core";
import mediaTypeDigitalOOH from '../../../images/mediaTypeDigitalOOH.svg';
import mediaTypeDirectMail from '../../../images/mediaTypeDirectMail.svg';
import mediaTypeMagazine from '../../../images/mediaTypeMagazine.svg';
import mediaTypeGoogle from '../../../images/mediaTypeGoogle.svg';
import mediaTypeGt from '../../../images/mediaTypeGt.svg';
import mediaTypeTV from '../../../images/mediaTypeTV.svg';
import classNames from 'classnames';

const useStyles = makeStyles(theme => ({
  mediaTypeText: {
    fontSize: 13,
    lineHeight: '18px'
  },
  mediaTypeText_printmag: {
    color: theme.palette.accent.customerCoral[400]
  },
  mediaTypeText_tv: {
    color: theme.palette.accent.TV[500]
  },
  mediaTypeText_dooh: {
    color: theme.palette.active.main
  },
  mediaTypeText_google: {
    color: theme.palette.accent.buyerBlue[500]
  },
  mediaTypeText_gt: {
    color: theme.palette.accent.online[500]
  },
  mediaTypeText_eddm: {
    color: theme.palette.accent.subscriberSunshine[500]
  },
  mediaTypeSvgWrapper: {
    '& img': {
      height: 24,
      width: 24
    }
  }
}));

export default function OrderMediaType({ mediaType, iconType }) {

  const classes = useStyles();
  let mediaTypeText = mediaType;
  let mediaTypeSVG = '';

  if (iconType === 'printmag') {
    mediaTypeText = 'Magazine';
    mediaTypeSVG = mediaTypeMagazine;
  }

  if (iconType === 'tv') {
    mediaTypeText = 'TV';
    mediaTypeSVG = mediaTypeTV;
  }

  if (iconType === 'dooh') {
    mediaTypeText = <span>Digital Out<br/>of Home</span>;
    mediaTypeSVG = mediaTypeDigitalOOH;
  }

  if (iconType === 'google') {
    mediaTypeText = 'Online';
    mediaTypeSVG = mediaTypeGoogle;
  }

  if (iconType === 'gt') {
    mediaTypeText = 'Online';
    mediaTypeSVG = mediaTypeGt;
  }

  if (iconType === 'eddm') {
    mediaTypeText = 'Direct Mail';
    mediaTypeSVG = mediaTypeDirectMail;
  }

  return <Box display="inline-flex" flexDirection="column" alignItems="center" justifyContent="center">
    <div className={classes.mediaTypeSvgWrapper}>
      <img src={mediaTypeSVG} alt={mediaTypeText} />
    </div>
    <Box className={classNames(
      classes.mediaTypeText,
      classes[`mediaTypeText_${iconType}`]
    )}>
      {mediaTypeText}
    </Box>
  </Box>;
}
