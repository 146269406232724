import { Button, CircularProgress, makeStyles } from '@material-ui/core';
import classNames from 'classnames';

const useStyles = makeStyles(theme => ({
  wrap: {
    display: 'inline-flex',
    position: 'relative',
  },

  wrapFullWidth: {
    width: '100%'
  },

  loader: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -14,
    marginLeft: -14
  }
}));

export default function LoadingButton(props) {
  const classes = useStyles();
  const { className, variant, loading, ...otherProps } = props;

  return (
    <div className={classNames(
      classes.wrap,
      props.className ? props.className : '',
      otherProps.fullWidth ? classes.wrapFullWidth : '')}>
      <Button {...otherProps} disabled={otherProps.disabled || loading} variant={variant ? variant : 'contained'}
              style={{ opacity: loading && variant === 'text' ? 0 : 1 }}>
        {props.children}
      </Button>
      {props.loading &&
      <CircularProgress size={28} className={classes.loader}/>
      }
    </div>
  );
}
