import React, { useEffect } from "react";
import {
  usePlaidLink,
} from "react-plaid-link";

const PlaidBtn = ({ token, onSuccess }) => {
  const { open, ready, error } = usePlaidLink({
    token,
    onSuccess,
  });
  console.log(error);

  // this opens link as soon as it's ready
  useEffect(() => {
    if (!ready) {
      return;
    }
    open();
  }, [ready, open]);

  // don't render anything, just open Link
  return (
    <button
      id="plaid-button-id"
      style={{ display: 'none' }}
      onClick={() => {
      open()
    }}
      disabled={!ready}
    >
      Connect a bank account
    </button>
  );
};

export default PlaidBtn;
