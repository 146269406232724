import React from 'react';
import GoogleMapReact from 'google-map-react';
import { makeStyles } from '@material-ui/core/styles';
import pin from '../../../images/pin.png';
import activePin from '../../../images/activePin.png';
import Tooltip from '@material-ui/core/Tooltip';
import CustomTooltip from '../common/CustomTooltip';

const MAP_KEY = 'AIzaSyB0vdZITke5JXBSMERzAoNl8YZjGztOFN0';

const useStyles = makeStyles(theme => ({
  map: {
    height: 450,
    width: '95%',

    [theme.breakpoints.down(960)]: {
      height: 350,
    },

    [theme.breakpoints.down(769)]: {
      height: 250,
    },

    [theme.breakpoints.down('xs')]: {
      height: 400,
      width: '100%',
    },
  }
}));

const Point = ({ point, selectedBrands }) => {
  const img = selectedBrands.includes(point.type) ? activePin : pin;
  return (
    <Tooltip title={<CustomTooltip point={point} />} placement="top-start">
      <img src={img} alt="pin" />
    </Tooltip>
  )
};

let defaultProps = {
  center: {
    lat: 33.912498,
    lng: -84.557198
  },
  zoom: 10
};

function Map({ points = [], location, selectedBrands }) {
  const classes = useStyles();

  const center = {
    lat: location.lat,
    lng: location.lng,
  }

  return (
    // Important! Always set the container height explicitly
    <div className={classes.map}>
      <GoogleMapReact
        bootstrapURLKeys={{ key: MAP_KEY }}
        defaultCenter={defaultProps.center}
        center={location.lat ? center : defaultProps.center}
        defaultZoom={defaultProps.zoom}
        onDragEnd={(map) => {
          console.clear()
          console.log("////////////////////////////////////////////////////");
          console.log("map.center.lat -> ", map.center.lat());
          console.log("map.center.lng -> ", map.center.lng());
          console.log("map.zoom -> ", map.zoom);
          console.log("mapUrl -> ", map.mapUrl);
        }}
      >
        {points?.map((point, key) => {
          return (
            <Point
              key={key}
              lat={point.lat}
              lng={point.lng}
              point={point}
              selectedBrands={selectedBrands}
            />
          )
        })}
      </GoogleMapReact>
    </div>
  );
}

export default Map;
