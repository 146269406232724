/* eslint-disable */
import React, { useEffect, useState } from 'react';
import { Box, makeStyles } from '@material-ui/core';
import { useParams } from 'react-router-dom';
import { format } from 'date-fns';
import { useSnackbar } from 'notistack';
import Summary from './Summary';
import DataController from '../../../lib/controllers/DataController';
import { actions } from '../../../store/store';
import useAppState from '../../../lib/hooks/useAppState';

const useStyles = makeStyles(theme => ({
  wrap: {
    background: theme.palette.neutral[200],
    minHeight: '100%',
    minWidth: 290
  },
  accordionWrap: {
    padding: '24px 16px',

    [theme.breakpoints.up('xl')]: {
      padding: '24px 32px',
    },
  },
  addOnsWrap: {
    flex: 1,
    background: theme.palette.neutral[300],
    padding: '24px 16px',

    [theme.breakpoints.up('xl')]: {
      padding: '24px 32px',
    },
  },
  item: {
    display: 'flex',
    justifyContent: 'space-between',
    borderBottom: `1px solid ${theme.palette.neutral[300]}`,
    padding: '15px 0',
    marginTop: 5,
  },
  cardNumber: {
    textAlign: 'right'
  },
  chargeDay: {
    fontSize: 12,
    fontStyle: 'italic'
  }
}))

export default function OrderSidebar() {
  const classes = useStyles();
  const { cartId } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const { dispatch, state } = useAppState();
  const [data, setData] = useState({
    ccNumber: '',
    ccType: '',
    placedBy: '',
    company: '',
    placedOn: '',
    paymentChanged: '',
    cChargeDay: ''
  })
  const [summaryE2b, setSummaryE2b] = useState({
    who: '',
    what: '',
    where: '',
    when: '',
    total: 0
  })

  useEffect(() => {
    async function fetchCartDetails() {
      try {
        const { data, message, success } = await DataController.getCampaign(cartId);
        const { cc_type, cc_number, company, contact, cc_charge_day, created, summary_e2b, market } = data;
        if (success) {
          setData({
            ccNumber: cc_number,
            ccType: cc_type,
            placedBy: contact?.fullname,
            company: company?.name,
            placedOn: created,
            cChargeDay: cc_charge_day || ''
          });
          dispatch({
            type: actions.SET_ORDER,
            payload: data,
          });
          let summaryE2b = {};
          summary_e2b.forEach(el => {
            summaryE2b[el.description] = el.formatted_value;
          })
          setSummaryE2b(summaryE2b);
          return;
        }
        if (message) {
          enqueueSnackbar(message, {variant: 'error'})
        }
      } catch (error) {
        console.log(error)
      }
    }

    if (cartId) {
      fetchCartDetails()
    }
  }, [cartId])

  const formattedDate = data.placedOn ? format(new Date(data.placedOn), 'MM/dd/yyyy') : '';

  return (
    <Box className={classes.wrap} display="flex" flexDirection="column">
      <Box className={classes.accordionWrap}>
        <Box className={classes.item}>
          <div>placed by</div>
          <div>{data.placedBy}</div>
        </Box>
        <Box className={classes.item}>
          <div>company</div>
          <div>{data.company}</div>
        </Box>
        <Box className={classes.item}>
          <div>placed on</div>
          <div>{formattedDate}</div>
        </Box>
        <Box className={classes.item}>
          <div>payment method</div>
          <div className={classes.cardNumber}>
            {data.ccType} ****{data.ccNumber}
            <div className={classes.chargeDay}>{data.cChargeDay}</div>
          </div>
        </Box>
        <Box mt={5}>
          <Summary summaryE2b={summaryE2b}/>
        </Box>
      </Box>
    </Box>
  )
}
