import { useEffect } from 'react';
import { hotjar } from 'react-hotjar';
import { HOTJAR_ID, HOTJAR_SNIPPET_VERSION } from '../../../lib/config';

function LoadHotjar() {

  useEffect(() => {
    hotjar.initialize(HOTJAR_ID, HOTJAR_SNIPPET_VERSION);
  }, []);

  return null;
}

export default LoadHotjar;
