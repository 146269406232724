import React, {useEffect, useState} from 'react';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import {makeStyles} from '@material-ui/core/styles';
import Summary from "../../elements/common/Summary";
import {ReactComponent as RightArrow} from "../../../images/rightArrowWhite.svg";
import LoadingButton from '../common/LoadingButton';
import DataController from "../../../lib/controllers/DataController";
import {useParams} from "react-router-dom";

const useStyles = makeStyles(theme => ({
  wrapper: {
    width: '100%',
    maxWidth: 1440,
  },
  logo: {
    position: "absolute",
    top: 10,
    left: 100,
    zIndex: 10
  },
  header: {
    height: 50,
    width: '100%',
    backgroundColor: '#B7FF05',
    position: "relative",
    boxShadow: "0px 1px 3px rgba(0, 0, 0, 0.12), 0px 1px 1px rgba(0, 0, 0, 0.14)",
  },
  options: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',

    '& span': {
      fontSize: 17,
      fontWeight: 400
    },
  },
  subtitle: {
    fontSize: 15,
    fontWeight: 400,
    lineHeight: '25px',
    marginTop: 14,
  },
  container: {
    display: 'flex',
    backgroundColor: theme.palette.common.offWhite,
    border: `1px solid ${theme.palette.neutral[400]}`,
    marginTop: 100,
    boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.12), 0px 1px 1px rgba(0, 0, 0, 0.14)',
    borderRadius: 10,

    [theme.breakpoints.down(769)]: {
      flexDirection: 'column'
    },
  },
  formWrapper: {
    maxWidth: 414
  },
  formTitle: {
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: 50,
    lineHeight: '55px',
    letterSpacing: -0.5,
  },
  textField: {
    marginTop: 30
  },
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  leftCol: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    padding: 20
  },
  rightCol: {
    display: 'flex',
    flexDirection: 'column',
    width: 395,

    [theme.breakpoints.down(769)]: {
      width: '100%'
    },
  },
  zipInputButton: {
    marginTop: 20,
    width: '100%',
    '& button': {
      width: '100%',
      backgroundColor: '#957EFE',
      color: 'white',

      '&:hover': {
        color: ' #3f3f3f',

        '& path': {
          stroke: '#3f3f3f'
        },
      },
    },
  },
  buyNowButton: {
    marginTop: 20,
    width: '100%',
    maxWidth: 390,

    '& button': {
      width: '100%',
      backgroundColor: '#957EFE',
      color: 'white',
    },

    [theme.breakpoints.down(769)]: {
      marginBottom: 50,
    },
  },
  creditCardBtn: {
    padding: 10,
    backgroundColor: theme.palette.neutral[900],
    border: `1px solid ${theme.palette.neutral[900]}`,
    borderBottomRightRadius: 0,
    borderTopRightRadius: 0,
    '& .MuiButton-label': {
      color: theme.palette.white,
      fontSize: 17,
      lineHeight: '24px',
      fontWeight: 'normal'
    }
  },
  bankAccountBtn: {
    padding: 10,
    border: `1px solid ${theme.palette.neutral[900]}`,
    backgroundColor: theme.palette.common.offWhite,
    borderBottomLeftRadius: 0,
    borderTopLeftRadius: 0,
    paddingRight: 20,
    '& .MuiButton-label': {
      color: theme.palette.neutral[900],
      fontSize: 17,
      lineHeight: '24px',
      fontWeight: 'normal',
      marginLeft: 10,
    }
  },
  plaidLink: {
    display: 'none'
  },
  summaryWrapper: {
    backgroundColor: '#B7FF05',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: 20
  },
  summary: {
    width: '100%'
  },
  questionsWrapper: {
    backgroundColor: theme.palette.neutral[200],
    height: '100%',
    padding: 20,
  },
  question: {
    borderBottom: `2px solid ${theme.palette.neutral[200]}`,
    padding: 10
  },
  title: {
    fontWeight: 800,
    fontSize: 17,
    lineHeight: '24px',
    color: theme.palette.neutral[900],
  },
  text: {
    fontWeight: 400,
    fontSize: 15,
    lineHeight: '20px',
    color: theme.palette.neutral[700],
    marginTop: 10
  },
  bankName: {
    fontWeight: 800,
    fontSize: 17,
    marginTop: 25,
    lineHeight: '24px',
    color: theme.palette.neutral[900],
  },
  bankAccount: {
    fontSize: 15,
    marginTop: 10,
    lineHeight: '20px',
    color: theme.palette.neutral[700],
    marginBottom: 5
  },
  editAccount: {
    '& span': {
      fontSize: 15,
      color: theme.palette.primary.main,
    }
  },
  link: {
    textDecoration: 'underline',
    textUnderlineOffset: '3px',
    paddingBottom: 5,
  }
}));

export default function SideBar({isPlaceOrderBtnEnabled, handlePlaceOrder, loading}) {
  const classes = useStyles();
  let {cartId} = useParams();
  const [summaryE2b, setSummaryE2b] = useState({
    who: '',
    what: '',
    where: '',
    when: '',
    total: 0
  })

  function handleClick() {
    window.open(`mailto:E2Bauto@groundtruth.com?subject=&body=`)
  }

  async function downloadPdf() {
    const fileName = "groundtruth-specs-for-online-ads.pdf";

    try {
      const response = await DataController.downloadSpecPdf();
      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = fileName;
      document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
      a.click();
      a.remove();  //afterwards we remove the element again
    } catch (e) {
      console.log('ERROR DOWNLOAD SPEC PDF')
    }
  }

  useEffect(() => {
    async function fetchCartDetails() {
      try {
        const {data, success} = await DataController.getCardInfo(cartId);
        const {summary_e2b} = data;

        if (success) {
          let summaryE2b = {};
          summary_e2b.forEach(el => {
            summaryE2b[el.description] = el.formatted_value;
          })
          setSummaryE2b(summaryE2b);
        }
      } catch (error) {
        console.log(error)
      }
    }

    if (cartId) {
      fetchCartDetails()
    }
  }, [cartId])

  return (
    <>
      <Box className={classes.summaryWrapper}>
        <Box className={classes.summary}>
          <Summary summaryE2b={summaryE2b}/>
        </Box>
        <LoadingButton
          variant='outlined'
          className={classes.zipInputButton}
          onClick={handlePlaceOrder}
          disabled={!isPlaceOrderBtnEnabled}
          loading={loading}
        >
          Place Order&nbsp;&nbsp;
          {isPlaceOrderBtnEnabled && <RightArrow/>}
        </LoadingButton>
      </Box>
      <Box className={classes.questionsWrapper}>
        <div className={classes.question}>
          <div className={classes.title}>What happens next?</div>
          <div className={classes.text}>
            Once you’ve purchased your campaign, your GroundTruth rep will send you an email introducing themselves and
            sharing details about where you will track your campaign results and more.
          </div>
        </div>
        <div className={classes.question}>
          <div className={classes.title}>Do I need my ad ready today?</div>
          <div className={classes.text}>
            Nope! Depending on your campaign start date, you have at least a few days to get your ad prepared. And if
            you need help creating your ad, your GroundTruth rep can provide details on our ad design services. Here are
            the <Button onClick={downloadPdf} className={classes.link}>ad specs.</Button>
          </div>
        </div>
        <div className={classes.question}>
          <div className={classes.title}>Questions?</div>
          <div className={classes.text}>
            If you’d like to speak to a GroundTruth representative before you make your purchase, you can send us an
            email at
            <Button onClick={handleClick} className={classes.link}>E2BAuto@groundtruth.com</Button> and your rep will
            get right back to you.
          </div>
        </div>
      </Box>
    </>
  );
}
