import {Box, makeStyles} from '@material-ui/core';
import successImage from '../../../images/paymentSuccess.jpg'
import {useHistory, useParams} from "react-router-dom";
import LoadingButton from "../common/LoadingButton";
import React from "react";

const useStyles = makeStyles(theme => ({
  success: {
    padding: 32,

    [theme.breakpoints.down('xs')]: {
      padding: 10,
    },
  },
  successTitle: {
    fontSize: 35,
    lineHeight: '100%',
    letterSpacing: '-0.5px',
    color: theme.palette.neutral[900],

    [theme.breakpoints.down(769)]: {
      fontSize: 34,
      lineHeight: '34px'
    },
  },
  successText: {
    fontSize: 20,
    lineHeight: '25px',
    color: theme.palette.neutral[700],
  },
  successImage: {
    display: 'flex',
    justifyContent: 'center',
  },
  img: {
    width: '100%',
    maxHeight: 331,
    margin: '30px 0 30px 0',

    [theme.breakpoints.down(769)]: {
      maxHeight: 215,
    },
  },
  button: {
    marginTop: 20,
    width: '100%',
    '& button': {
      width: '100%',
      backgroundColor: '#957EFE',
      color: 'white',

      '&:hover': {
        color: ' #3f3f3f',

        '& path': {
          stroke: '#3f3f3f'
        },
      },
    },
  },
  successImg: {
    width: '80%',
  }
}));

export default function Success() {
  const classes = useStyles();
  const history = useHistory();
  const {cartId} = useParams();

  function handleClick() {
    history.push(`/upload/${cartId}`);
  }

  return (
    <Box className={classes.success}>
      <Box className={classes.successTitle}>All set! Your campaign is scheduled.</Box>
      <Box className={classes.successImage}>
        <img src={successImage} className={classes.img} alt='success'/>
      </Box>
      <Box className={classes.successText}>
        Watch your email inbox for more details including ad specs, options for getting your ads designed, plus
        directions for how to track your campaign results. If you have your ads ready now, you can upload them using the
        link below.
      </Box>
      <Box>
        <LoadingButton
          variant='outlined'
          className={classes.button}
          onClick={handleClick}
        >
          Upload Ads
        </LoadingButton>
      </Box>
    </Box>
  )
}
