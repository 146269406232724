import React from 'react';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import DatePicker from './DatePicker';
import Button from "@material-ui/core/Button";
import {ReactComponent as RightArrow} from "../../../images/rightArrow.svg";
import { format } from 'date-fns'
import useAppState from "../../../lib/hooks/useAppState";
import {actions} from "../../../store/store";
import section3 from "../../../images/section3.png";
import EmptyState from "./EmptyState";
import arrowUp from "../../../images/arrowUpWhite.png";

const useStyles = makeStyles(theme => ({
  dateSection: {
    backgroundColor: theme.palette.primary[500],
    minHeight: 1024,
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    alignItems: 'center',

    [theme.breakpoints.down('xs')]: {
      minHeight: 'unset',
      paddingBottom: 50
    },
  },
  contentContainer: {
    maxWidth: 1030,
    width: '100%',
    marginTop: 150,

    [theme.breakpoints.down('xl')]: {
      width: '80%',
    },

    [theme.breakpoints.down('xs')]: {
      width: '90%',
      marginTop: 50
    },
  },
  sectionHeader: {
    fontSize: 34,
    letterSpacing: -0.45,
    lineHeight: '34px',
    color: theme.palette.common.offWhite,
    borderBottom: '4px solid rgba(253,253,253, .2)',
    paddingBottom: 15,
  },
  description: {
    color: theme.palette.common.offWhite,
    opacity: 0.8,
    marginTop: 20,
    fontSize: 20,
    lineHeight: '25px'
  },
  footerWrapper: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    marginTop: 100,

    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
      marginTop: 60,
    },
  },
  zipInputButton: {
    marginLeft: 20,

    [theme.breakpoints.down('xs')]: {
      marginTop: 30,
      marginLeft: 0,
      width: '100%'
    },
  },
  date: {
    color: theme.palette.common.offWhite,
    fontSize: 20,
    lineHeight: '25px',
  },
  dd: {
    fontSize: 200,
    letterSpacing: -0.5,
    color: theme.palette.common.offWhite,
    position: "absolute",
    top: -150,
    right: 300,
    zIndex: 20,
    opacity: 0.5,

    [theme.breakpoints.down(769)]: {
      display: 'none',
    },
  },
  mm: {
    fontSize: 200,
    letterSpacing: -0.5,
    color: theme.palette.common.offWhite,
    position: "absolute",
    right: -150,
    zIndex: 20,
    opacity: 0.05,

    [theme.breakpoints.down(769)]: {
      display: 'none',
    },
  },
  datePickerContainer: {
    position: 'relative',

    [theme.breakpoints.down('xs')]: {
      marginTop: 30,
    },
  },
  section3: {
    position: 'absolute',
    right: 0,
    zIndex: 500,
    top: -300,

    [theme.breakpoints.down('md')]: {
      right: -0,
      top: -200,
    },

    [theme.breakpoints.down(769)]: {
      display: 'none',
    },
  },
  section3Img: {
    height: 487,

    [theme.breakpoints.down('md')]: {
      right: 0,
      height: 287,
    },
  },
  emptyStateText: {
    fontSize: 21,
    lineHeight: '25px',
    color: theme.palette.common.offWhite,
  }
}));

export default function DateSection({ changePage }) {
  const classes = useStyles();
  const { state, dispatch } = useAppState();
  const data = state.homeData;
  const startDate = data.availability.start_date;
  const selectedDate = data.selectedDate;

  function handleConfirm() {
    changePage(5);
  }

  const handleChangeDate = (date) => {
    console.log(':::::::::::::::handleChangeDate', format(date, 'yyyy'))
    dispatch({
      type: actions.UPDATE_SELECTED_DATE,
      payload: date
    })
  }

  console.log(":::::::selectedDate", selectedDate);
  console.log(":::::::::::startDate", startDate);

  return (
    <Box className={classes.dateSection} id="page4">
      <Box className={classes.contentContainer}>
        <div className={classes.section3}>
          <img src={section3} alt="section3" className={classes.section3Img} />
        </div>
        <Box className={classes.sectionHeader}>
          When would you like your campaign to start?
        </Box>
        <div className={classes.description}>
          Simply select the start date on the calendar below. Campaigns start on Mondays and typically run for about 30
          days but may require more time based on your market size and the number of impressions you’ve chosen.
        </div>
        {selectedDate || startDate ? (
          <>
            <Box display='flex' justifyContent='center' mt={15} className={classes.datePickerContainer}>
              {selectedDate && <div className={classes.dd}>{format(new Date(selectedDate), 'dd')}</div>}
              {selectedDate && <div className={classes.mm}>{format(new Date(selectedDate), 'MMMM')}</div>}
              <DatePicker selectedDate={selectedDate} minDate={new Date(startDate)} setSelectedDate={handleChangeDate} />
            </Box>
            <Box className={classes.footerWrapper}>
              {selectedDate && <div className={classes.date}>Ok, {format(new Date(selectedDate), 'MMMM dd, yyyy')} it is!</div>}
              <Button
                variant='outlined'
                className={classes.zipInputButton}
                disabled={!selectedDate}
                onClick={handleConfirm}
              >
                Confirm Dates&nbsp;&nbsp;
                {selectedDate && <RightArrow />}
              </Button>
            </Box>
          </>
        ) : (
          <Box mt={30}>
            <EmptyState img={arrowUp}>
              <span className={classes.emptyStateText}>Select a location above to see available start dates for your area.</span>
            </EmptyState>
          </Box>
        )}
      </Box>
    </Box>
  );
}
