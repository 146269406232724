/* eslint-disable */
import { useHistory } from 'react-router-dom';

export default function () {

  const history = useHistory();

  const isLocalURL = val => val.startsWith('/');

  /**
   * Get props for <a>
   *
   * @param url
   * @param target
   * @param onClick
   * @param preventDefault
   * @returns {{}}
   */
  const getLinkAnchorProps = ({ url = '', target = '', onClick, preventDefault = false }) => {

    const props = {};

    const maybeOnClick = e => {
      if (typeof onClick === 'function') {
        onClick(e);
      }
    };

    const maybePreventDefault = e => {
      e.preventDefault();
      e.stopPropagation();
    };

    if (isLocalURL(url)) {
      props.onClick = e => {
        maybePreventDefault(e);
        maybeOnClick(e);
        history.push(url);
      };
    } else {
      /*props.onClick = e => {
          maybePreventDefault(e);
          maybeOnClick(e);
      };*/
      props.href = url;
      props.target = target || '_blank';
    }

    return props;
  };

  /**
   * Get props for <button> or other clickable element
   *
   * @param url
   * @param onClick
   * @param target
   * @param preventDefault
   * @param from
   * @returns {{onClick: onClick}}
   */
  const getLinkButtonProps = ({ url = '', target= '_blank', onClick, preventDefault = false, from }) => {
    return {
      onClick: e => {
        if (preventDefault) {
          e.preventDefault();
          e.stopPropagation();
        }
        if (typeof onClick === 'function') {
          onClick(e);
        }
        if (isLocalURL(url)) {
          console.log(1);
          history.push(url, {from});
        } else {
          console.log(2);
          window.open(url, target).focus();
        }
      }
    };
  };

  return {
    getLinkAnchorProps,
    getLinkButtonProps,
    history
  };
}
