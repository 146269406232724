import React, {useEffect} from 'react';
import {Box, makeStyles} from "@material-ui/core";
import Success from "../../elements/PaymentSuccess/Success";
import Sidebar from "../../elements/common/SideBar";
import Container from '@material-ui/core/Container';
import DataController from "../../../lib/controllers/DataController";
import PersistentStorage from "../../../lib/controllers/PersistentStorage";
import {actions} from "../../../store/store";
import {useParams} from "react-router-dom";
import useAppState from "../../../lib/hooks/useAppState";

const useStyles = makeStyles(theme => ({
  wrapper: {
    width: '100%',
    maxWidth: 1100,
    padding: '0 40px 50px 40px',

    [theme.breakpoints.down('xs')]: {
      padding: '0 10px 50px 10px',
    },
  },
  container: {
    display: 'flex',
    backgroundColor: theme.palette.common.offWhite,
    border: `1px solid ${theme.palette.neutral[400]}`,
    marginTop: 100,
    boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.12), 0px 1px 1px rgba(0, 0, 0, 0.14)',
    borderRadius: 10,

    [theme.breakpoints.down(769)]: {
      flexDirection: 'column'
    },

    [theme.breakpoints.down('xs')]: {
      marginTop: 40,
    },
  },
  charts: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
  },
  details: {
    display: 'flex',
    flexDirection: 'column',
    width: 450,

    [theme.breakpoints.down('md')]: {
      width: 290
    },

    [theme.breakpoints.down(769)]: {
      width: '100%'
    },
  },
}));

export default function PaymentSuccessPage() {
  const classes = useStyles();
  const {cartId} = useParams();
  const {dispatch} = useAppState();

  useEffect(() => {
    async function fetchToken() {
      try {
        const {data, success} = await DataController.getToken(cartId);
        if (success) {
          PersistentStorage.set('token', data.token);
          dispatch({
            type: actions.SET_TOKEN,
            payload: data.token
          });
        }
      } catch (error) {
        console.log(error)
      }
    }

    if (cartId) {
      fetchToken()
    }
  }, [cartId, dispatch]);


  useEffect(() => {
    window.scrollTo({top: 0, behavior: "smooth"});
  }, []);

  return (
    <Box className={classes.wrapper}>
      <Container className={classes.container}>
        <Box className={classes.charts}>
          <Success/>
        </Box>
        <Box className={classes.details}>
          <Sidebar/>
        </Box>
      </Container>
    </Box>
  )
}
