const actions = {
  SET_HOME_DATA: 'SET_HOME_DATA',
  UPDATE_BRAND_LIST: 'UPDATE_BRAND_LIST',
  UPDATE_SELECTED_IMPRESSION: 'UPDATE_SELECTED_IMPRESSION',
  UPDATE_SELECTED_DATE: 'UPDATE_SELECTED_DATE',
  UPDATE_ZIP: 'UPDATE_ZIP',
  SET_TOKEN: 'SET_TOKEN',
  SET_ORDER_ID: 'SET_ORDER_ID',
  SET_METRICS: 'SET_METRICS',
  SET_ORDER: 'SET_ORDER',
  UPDATE_ROW: 'UPDATE_ROW',
  UPDATE_CAMPAIGN_URL: 'UPDATE_CAMPAIGN_URL',
};

export default actions;
