/* eslint-disable */
import React, { useState, useLayoutEffect } from 'react';

export default function useHasScrollbar(ref) {

  const [hasScrollbar, setHasScrollbar] = useState(false);

  const handleResize = () => {
    if (ref && ref.current) {
      setHasScrollbar(ref.current.scrollWidth > ref.current.clientWidth);
    }
  };

  useLayoutEffect(() => {
    window.addEventListener('resize', handleResize);
    handleResize();
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  });

  return hasScrollbar;
}
