import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core';
import Avatar from '../common/Avatar.js';

const useStyles = makeStyles(theme => ({
  root: {},
  note: {
    fontSize: 15,
    lineHeight: '20px',
    background: theme.palette.accent.marketMint[100],
    borderRadius: 4,
    padding: 16,

    '& + $note': {
      marginTop: 16
    }
  },
  noteHeader: {},
  noteName: {
    color: theme.palette.primary.main
  },
  noteDate: {
    color: theme.palette.neutral[700]
  },
  noteText: {
    color: theme.palette.neutral[900],
    overflowWrap: 'anywhere',
  }
}));

function OrderNotes({ order }) {

  const classes = useStyles();

  if (!order?.filenotes?.note) return '';

  return <Box className={classes.root}>
    <Box className={classes.note}>
      <Box mb={1} display="flex" alignItems="center" className={classes.noteHeader}>
        {order.filenotes.note.user.avatar && <Avatar width={40} height={40} src={order.filenotes.note.user.avatar}/>}
        <div className={classes.noteName}>{order.filenotes.note.user.name}</div>
        <div className={classes.noteDate}>, {order.filenotes.note.user.date}:</div>
      </Box>
      <Box pl={7} className={classes.noteText}>
        {order.filenotes.note.body}
      </Box>
    </Box>
  </Box>;
}

export default OrderNotes;
