import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  header: {
    height: 104,
    width: 151,
    background: theme.palette.accent.marketMint[100],
    border: `1px solid ${theme.palette.background.start}`,
    fontSize: 17,
    lineHeight: '24px',
    borderRadius: 10,
    display: "flex",
    justifyContent: "center",
    alignItems: 'center',
    padding: 10,
    textAlign: 'center'
  },
}));

export default function NoCampaignBadge() {

  const classes = useStyles();

  return (
    <div className={classes.header}>
      Your campaign<br/> has not<br/> started yet.
    </div>
  );
}
