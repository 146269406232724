/* eslint-disable */
import React, { useEffect, useState } from 'react';
import Container from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/core/styles';
import { useSnackbar } from 'notistack';
import ZipSection from '../../elements/Home/ZipSection';
import MapSection from '../../elements/Home/MapSection';
import ImpressionsSection from '../../elements/Home/ImpressionsSection';
import DateSection from '../../elements/Home/DateSection';
import BuyNowSection from '../../elements/Home/BuyNowSection';
import Footer from '../../elements/Home/Footer';
import Stepper from '../../elements/common/StepperMenu';
import DataController from '../../../lib/controllers/DataController';
import useAppState from '../../../lib/hooks/useAppState';
import { actions } from '../../../store/store';
import PersistentStorage from '../../../lib/controllers/PersistentStorage';
import { formatErrorObj } from '../../../lib/functions';
import Dialog from "@material-ui/core/Dialog";
import GTAbout from "../../elements/Home/Modals/GTAbout";

const useStyles = makeStyles(theme => ({
  wrapper: {
    width: '100%',
  },
  container: {
    border: `1px solid ${theme.palette.neutral[400]}`,
    boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.12), 0px 1px 1px rgba(0, 0, 0, 0.14)',
    borderRadius: 10,
    position: "relative",
  },
  stepper: {
    position: 'fixed',
    top: 400,
    right: 25,
    zIndex: 1500,

    [theme.breakpoints.down(769)]: {
      display: 'none',
    },
  },
  dialog: {
    '& .MuiDialog-paper': {
      margin: 0,
      padding: 0,
      maxWidth: 1143,
      border: 0,

      [theme.breakpoints.down('xs')]: {
        width: '95%',
      },

      [theme.breakpoints.down('sm')]: {
        width: '95%',
      },
    },
  },
}));

export default function HomePage({ openAboutButtonModal, onClose, isOpen }) {
  const [page, setPage] = useState(1);
  const classes = useStyles();
  const { state, dispatch } = useAppState();
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);
  const cartId = PersistentStorage.get('cartId');
  const [message, setMessage] = useState('');
  const [scrollNextPage, setScrollNextPage] = useState(false);
  const { brandList, brands } = state.homeData;

  useEffect(() => {
    async function fetchCartDetails() {
      try {
        const { data, errors, message, success } = await DataController.getCardInfo(cartId);
        if (success) {
          const selectedBrands = data.contents.items[0].metadata.brands;
          const updatedBrandList = brandList.map(el => {
            if (selectedBrands.includes(el.brand)) {
              return {
                ...el,
                checked: true
              }
            }
            return el;
          })
          dispatch({
            type: actions.UPDATE_SELECTED_IMPRESSION,
            payload: data.contents.items[0].impressions
          });
          dispatch({
            type: actions.UPDATE_SELECTED_DATE,
            payload: new Date(data.contents.items[0].date_start.replaceAll('-', '/'))
          });
          dispatch({
            type: actions.UPDATE_BRAND_LIST,
            payload: updatedBrandList
          });
        } else {
          PersistentStorage.delete('cartId');
        }
      } catch (error) {
        console.log(error)
      }
    }

    if (cartId && brands.length) {
      fetchCartDetails()
    }
  }, [brands.length])

  useEffect(() => {
    async function getLocation(zip) {
      PersistentStorage.set('zipCode', zip);
      setLoading(true);
      setMessage('')
      try {
        const { data, errors, message, success } = await DataController.getLocation(zip);
        if (success) {
          dispatch({
            type: actions.SET_HOME_DATA,
            payload: { ...data, zipCode: zip }
          });
          if (scrollNextPage) {
           setScrollNextPage(false);
            changePage(2);
          }
          return
        }
        if (Object.keys(errors).length > 0) {
          const errorObject = formatErrorObj(errors);
        }
        if (message) {
          setMessage(message)
        }
      } catch (error) {
        enqueueSnackbar(error.message, {variant: 'error'});
      } finally {
        setLoading(false);
      }
    }
    if (state.homeData.zipCode) {
      getLocation(state.homeData.zipCode)
    }
  }, [state.homeData.zipCode])

  function handleGetStartedPress(zip) {
    setScrollNextPage(true);
    dispatch({
      type: actions.UPDATE_ZIP,
      payload: zip
    });
  }

  const changePage = (page) => {
    const element = document.getElementById(`page${page}`);
    const y = element.getBoundingClientRect().top + window.scrollY;
    window.scrollTo({top: y, behavior: 'smooth'});
  }

  return (
    <div className={classes.wrapper} >
      <Container className={classes.container} >
        <div className={classes.stepper} >
          <Stepper active={page} page={page} onPress={changePage} setPage={setPage} />
        </div>
        <ZipSection onGetStartedPress={handleGetStartedPress} loading={loading} message={message} setMessage={setMessage} />
        <MapSection changePage={changePage} />
        <ImpressionsSection changePage={changePage} />
        <DateSection changePage={changePage} />
        <BuyNowSection changePage={changePage} />
        <Footer openAboutButtonModal={openAboutButtonModal}/>
      </Container>
      <Dialog
        className={classes.dialog}
        onClose={onClose}
        open={isOpen}
        disableEnforceFocus={true}
      >
        <GTAbout onClose={onClose} />
      </Dialog>
    </div>
  );
}
