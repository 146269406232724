/* eslint-disable */
import { useEffect } from 'react';
import PersistentStorage from '../controllers/PersistentStorage';
import { getLocationData } from '../functions';
import { actions } from "../../store/store";
import useAppState from "./useAppState";

function useZipCode() {
  const { dispatch } = useAppState();

  function updateState(zipCode) {
    dispatch({
      type: actions.SET_HOME_DATA,
      payload: {
        zipCode,
      }
    });
  }

  useEffect(() => {
    async function load() {
      try {
        const data = await getLocationData();
        const zipCode = data.postal.code;
        if (data?.postal?.code) {
          PersistentStorage.set('zipCode', zipCode);
        }
        updateState(zipCode);
      } catch (e) {
        console.log(e)
      }
    }

    const zipCode =  PersistentStorage.get('zipCode');
    if (!zipCode) {
      load();
      return
    }
    updateState(zipCode);
  }, [])
}

export default useZipCode;
