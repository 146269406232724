/* eslint-disable */

export function validateURL(val) {
  if (!val) return true;
  const URLRegex = /(https?:\/\/)([\da-z\.-]+)\.([a-z]{2,6})([\/\w\.-]*)*\/?/g;
  return URLRegex.test(val) || 'Value should be a valid URL';
}

export function validateRequired(val) {
  return !!val || 'Value is required';
}
