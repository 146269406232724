/* eslint-disable */
import React, { useState, useEffect } from 'react';
import CustomDialog from '../common/CustomDialog.js';
import Box from '@material-ui/core/Box';
import { makeStyles, Typography } from '@material-ui/core';
import DataTable from '../common/DataTable.js';
import OrderThumbnail from './OrderThumbnail.js';
import OrderMediaType from './OrderMediaType.js';
import Checkbox from '@material-ui/core/Checkbox';
import DataController from '../../../lib/controllers/DataController.js';
import { useSnackbar } from 'notistack';
import TransitionBounce from '../common/TransitionBounce.js';
import { ReactComponent as WelcomeGroupIcon } from '../../../images/home-group.svg';

const headings = [
  {
    id: 'checkbox'
  },
  {
    id: 'logo'
  },
  {
    id: 'text'
  },
  {
    id: 'media_type',
    breakpoint: 'sm'
  }
];

const useStyles = makeStyles((theme) => ({
  header: {
    display: 'flex',
    marginLeft: -24,
    marginRight: -24,
    marginTop: 24,
    backgroundColor: theme.palette.accent.marketMint[100],
    padding: 24,

    '& img': {
      marginRight: 16,
      maxWidth: 100,
      maxHeight: 120,
      objectFit: 'contain',
      objectPosition: 'center'
    }
  },
  headerText: {
    fontSize: 20,
    lineHeight: '25px',
    color: theme.palette.neutral[900],
    textAlign: 'center'
  },
  dialogContentClass: {
    overflow: 'visible'
  },
  textId: {
    color: theme.palette.neutral[800],
    fontSize: 11,
    lineHeight: '13px',
    marginBottom: 4
  },
  textTitle: {
    color: theme.palette.neutral[900],
    fontSize: 20,
    lineHeight: '25px',
    fontWeight: 'bold',
    marginBottom: 4
  },
  textRow: {
    color: theme.palette.neutral[800],
    fontSize: 11,
    lineHeight: '13px'
  },
  thumbWrap: {
    position: 'relative'
  },
  checkboxCell: {
    maxWidth: 56,
    width: 56,
    paddingLeft: 16,
    paddingRight: 0,

    '& svg': {
      width: 16,
      height: 16
    }
  },
  logoCell: {
    maxWidth: 142,
    width: 142
  },
  mediaTypeCell: {
    textAlign: 'center'
  },
  defaultRow: {
    cursor: 'pointer',

    '&:hover': {
      background: theme.palette.neutral[300]
    }
  },
  graphicWrapper: {
    textAlign: 'center',
  },
  graphic: {
    maxWidth: 311,
    maxHeight: 176,
  },
  title: {
    fontSize: 34,
    lineHeight: 1,
    color: theme.palette.neutral[900],
    fontWeight: 400,
  }
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <TransitionBounce ref={ref} {...props} />;
});

function OrderDuplicateAdDialog({ isOpen, order, data, onClose }) {

  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [selected, setSelected] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (isOpen) {
      setSelected([]);
      setIsLoading(false);
    }
  }, [isOpen]);

  const cellRenderer = (heading, row) => {
    switch (heading.id) {
      case 'checkbox':
        return <Checkbox size="small" checked={selected.includes(row.id)}/>;
      case 'logo':
        return <Box className={classes.thumbWrap}>
          <OrderThumbnail src={row.image_thumbnail} isNew={true}/>
        </Box>;
      case 'text':
        return <Box>
          <Box className={classes.textId}>#{row.id}</Box>
          <Box className={classes.textTitle}>{row.title}</Box>
          {row.companyname && <Box className={classes.textRow}>Client: {row.companyname}</Box>}
          {row.summary_line_1 && <Box className={classes.textRow}>{row.summary_line_1}</Box>}
          {row.summary_line_2 && <Box className={classes.textRow}>{row.summary_line_2}</Box>}
        </Box>;
      case 'media_type':
        return <OrderMediaType mediaType={row.media_type} iconType={row.icon_type}/>;
      default:
        return '-';
    }
  };

  const cellPropsGenerator = (heading, row) => {
    switch (heading.id) {
      case 'checkbox':
        return { className: classes.checkboxCell };
      case 'logo':
        return { className: classes.logoCell };
      case 'media_type':
        return { className: classes.mediaTypeCell };
    }
  };

  const rowPropsGenerator = row => {
    return { className: classes.defaultRow };
  };

  const handleClickRow = (e, row) => {
    setSelected(val => {
      if (val.includes(row.id)) {
        return [...val].filter(item => item !== row.id);
      } else {
        const newArr = [...val];
        newArr.push(row.id);
        return newArr;
      }
    });
  };

  const handleSubmit = e => {
    setIsLoading(true);
    DataController.postFilesPickup(order.id, selected).then(res => {
      if (res && res.message) {
        enqueueSnackbar(res.message, {
          variant: res.success ? 'success' : 'error'
        });
      }
      onClose();
      setIsLoading(false);
    });
  };

  return <CustomDialog
    TransitionComponent={Transition}
    onClose={onClose}
    isOpen={isOpen}
    title=" "
    actions={data && data.length > 0 ? [
      {
        label: 'Confirm',
        fullWidth: true,
        color: selected.length > 0 && !isLoading ? 'primary' : 'default',
        disabled: selected.length === 0 || isLoading,
        onClick: handleSubmit
      },
      {
        label: 'not now',
        variant: 'text',
        fullWidth: true,
        onClick: onClose
      }
    ] : []}
    dialogContentClass={classes.dialogContentClass}
    maxWidth="sm"
    hideTitleDivider
    hasXClose
    disableAutoFocus
    positionedAbove>
    <Box className={classes.graphicWrapper}>
      <WelcomeGroupIcon className={classes.graphic} />
      <Box mt={1} className={classes.title}>Congratulations! Your ad was uploaded successfully!</Box>
    </Box>
    {data && data.length > 0 &&
      <>
        <Box className={classes.header}>
          {data.length === 1
            ? <Box className={classes.headerText}>
              This ad can be used for another order. Would you like to run this ad for the order below?
            </Box>

            : <Box className={classes.headerText}>
              This ad can be used for {data.length} other orders. Would you like to run this ad for any of the orders below?
            </Box>
          }
        </Box>
        <DataTable
          breakpointPaddings={{
            xsUp: 24
          }}
          headings={headings}
          cellRenderer={cellRenderer}
          cellPropsGenerator={cellPropsGenerator}
          rowPropsGenerator={rowPropsGenerator}
          onRowClick={handleClickRow}
          rows={data}
        />
      </>
    }
  </CustomDialog>;
}

export default OrderDuplicateAdDialog;
