import React from 'react';
import Box from '@material-ui/core/Box';
import {makeStyles} from '@material-ui/core/styles';
import {ReactComponent as CloseDialogIcon} from "../../../../images/closeDialog.svg";
import IconButton from "@material-ui/core/IconButton";

const useStyles = makeStyles(theme => ({
  button: {
    width: '100%',
  },
  footer: {
    backgroundColor: theme.palette.white,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-end',
    padding: 10,
  },
  xCloseButton: {
    position: 'absolute',
    right: 25,
    top: 25
  },
  formContainer: {
    '& h2': {
      fontSize: 21
    },

    '& h3': {
      fontSize: 21
    },

    '& h6': {
      fontSize: 15
    },

    [theme.breakpoints.up('sm')]: {
      '& h2': {
        fontSize: 34
      },

      '& h3': {
        fontSize: 34
      },

      '& h6': {
        fontSize: 20
      }
    },

    [theme.breakpoints.up('lg')]: {
      '& h2': {
        fontSize: 55
      }
    }
  },
  success: {
    padding: 32,

    [theme.breakpoints.down('xs')]: {
      padding: 10,
    },
  },
  successTitle: {
    fontSize: 34,
    lineHeight: '100%',
    letterSpacing: '-0.5px',
    color: theme.palette.neutral[900],

    [theme.breakpoints.down(769)]: {
      fontSize: 34,
      lineHeight: '34px',
      maxWidth: 300
    },
  },
  successText: {
    fontSize: 20,
    lineHeight: '25px',
    color: theme.palette.neutral[700],
    display: 'flex',
    alignItems: 'center',
    marginTop: 20
  },
  successImage: {
    display: 'flex',
    justifyContent: 'center',
  },
  img: {
    width: '100%',
    maxHeight: 331,
    margin: '30px 0 30px 0',

    [theme.breakpoints.down(769)]: {
      maxHeight: 315,
    },
  },
}));

export default function Inventory({onClose}) {
  const classes = useStyles();

  return (
    <div style={{maxWidth: '700px'}}>
      <Box className={classes.formContainer}>
        <IconButton
          className={classes.xCloseButton}
          onClick={onClose}
          size="small"
          aria-label="close"
          color="secondary"
        >
          <CloseDialogIcon/>
        </IconButton>
        <div>
          <Box className={classes.success}>
            <Box className={classes.successTitle}>Limited impressions available</Box>
            <Box className={classes.successText}>
              A finite number of people visit car dealerships each month. That means that there is a limited number of
              opportunities available to reach those shoppers with ads in each market. Once those ad impressions have
              been purchased, the inventory is sold out for that period and those dates are not available in the
              campaign date selector.
            </Box>
          </Box>
        </div>
      </Box>
    </div>
  );
}
